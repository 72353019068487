import React from 'react';
import Alert from '@mui/material/Alert';
// import { lng } from '../../utils/language';
// import { useSelector } from 'react-redux';

export default function EmailAlert({severity, open, setOpen, msg}) {
    setTimeout(() => {
        setOpen({
            ...open,
            open: false
        })
    }, 5000);
  return (
    open &&
    <Alert variant='filled' severity={severity} sx={{'.MuiAlert-action': {display: 'none'}, display: 'flex', justifyContent: 'center', backgroundColor: '#002856', width: '100%', borderRadius: 0, position: 'fixed', bottom: 0, left: 0}}>{msg}</Alert>
  )
}