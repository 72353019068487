export const privacyLng = {
    alb: {
        policy: `POLITIKA E PRIVATËSISË`,
        policy1: `1. HYRJE`,
        policy11: `1.1 INFORMACION RRETH KONTROLLUESIT TË TË DHËNAVE`,
        policy12: `1.2 FUSHA E ZBATIMIT`,
        policy13: `1.3 QËLLIMI DHE BAZA LIGJORE`,
        policy2: `2. PËRPUNIMI I TË DHËNAVE NËPËRMJET FAQES WEB`,
        policy21: `2.1 QËLLIMET E PËRPUNIMIT TË TË DHËNAVE NËPËRMJET FAQES WEBE`,
        policy22: `2.2 MËNYRAT E MBLEDHJES SË TË DHËNAVE PERSONALE NËPËRMJET FAQES WEB DHE LLOJET E TË DHËNAVE QË PËRPUNOHEN PËR SECILIN RAST`,
        policy221: `2.2.1 NËPËRMJET KËRKESAVE, PYETJEVE APO SHPREHJEVE TË INTERESIT QË DËRGONI NË FAQEN WEB`,
        policy222: `2.2.2 KUR KONTAKTONI`,
        policy3: `3. SHPËRNDARJA E TË DHËNAVE TUAJA PERSONALE ME PALË TË TRETA`,
        policy4: `4. TRANSFERIMI I TË DHËNAVE TUAJA TE MARRËS NË SHTETE TË TJERA`,
        policy5: `5. KOHA E RUAJTJES SË TË DHËNAVE TUAJA TË MBLEDHURA NËPËRMJET FAQES WEB`,
        policy6: `6. SIGURIA E TË DHËNAVE TUAJA PERSONALE`,
        policy7: `7. TË DREJTAT TUAJA SI SUBJEKT TË DHËNASH`,
        policy8: `8. PËRDITËSIMET E POLITIKËS SË PRIVATËSISË`,
        policy9: `9. NA KONTAKTONI`,
    },
    eng: {
        policy: `PRIVACY POLICY`,
        policy1: `1. INTRODUCTION `,
        policy11: `1.1 INFORMATION ABOUT THE DATA CONTROLLER `,
        policy12: `1.2 SCOPE OF APPLICATION `,
        policy13: `1.3 PURPOSE AND LEGAL BASIS`,
        policy2: `2. DATA PROCESSING THROUGH THE WEBSITE`,
        policy21: `2.1 PURPOSES OF DATA PROCESSING THROUGH THE WEBSITE `,
        policy22: `2.2 METHODS OF COLLECTING PERSONAL DATA THROUGH THE WEBSITE AND TYPES OF DATA PROCESSED FOR EACH CASE`,
        policy221: `2.2.1 THROUGH REQUESTS, INQUIRIES, OR EXPRESSIONS OF INTEREST SUBMITTED ON THE WEBSITE`,
        policy222: `2.2.2 CONTACT`,
        policy3: `3. SHARING YOUR PERSONAL DATA WITH THIRD PARTIES`,
        policy4: `4. TRANSFER OF YOUR DATA TO RECIPIENTS IN OTHER COUNTRIES`,
        policy5: `5. STORAGE TIME OF YOUR DATA COLLECTED THROUGH THE WEBSITE`,
        policy6: `6. SECURITY OF YOUR PERSONAL DATA`,
        policy7: `7. YOUR RIGHTS AS A DATA SUBJECT`,
        policy8: `8. PRIVACY POLICY UPDATES`,
        policy9: `9. CONTACT US`,
    }
}