import React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import FetchData from '../../api/FetchData';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Countries from './Countries.json';
import { lng } from '../../utils/language';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
const inputStyle = {
    '.MuiInputBase-root': {
        borderColor: '#7f8a94',
    },
    fieldset: {
        borderRadius: 0,
        borderWidth: '1px'
    }
}

const defaultUserInfo = {
    fullName: '',
    lastname: '',
    email: '',
    prefix: '',
    // countryCode: '',
    mob: '',
    country: '',
    checked: false
}

const defaultUserInfoError = {
    fullNameError: false,
    lastNameError: false,
    emailError: false,
    prefixError: false,
    // countryCodeError: false,
    mobError: false,
    countryError: false
}

const defaultAlert = {
    open: false,
    severity: '',
    msg: ''
}

export default function ContactModal({ setState, setOp }) {
    const language = useSelector((state) => state.language.language)
    const [loadingButton, setLoadingButton] = React.useState(false);
    const [userInfo, setUserInfo] = React.useState(defaultUserInfo)
    const [userInfoErrors, setUserInfoErrors] = React.useState(defaultUserInfoError)
    const [alert, setAlert] = React.useState(defaultAlert);

    const handleCheck = (e) => {
        setUserInfo({ ...userInfo, checked: e.target.checked });
    }

    const inputFunc = {
        onChangeName: (e) => {
            var value = e.target.value;
            if (value !== "" && !value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g)) {
                setUserInfoErrors({ ...userInfoErrors, fullNameError: true })
            } else if (value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g) || value !== "") {
                setUserInfoErrors({ ...userInfoErrors, fullNameError: false })
            }
            setUserInfo({ ...userInfo, fullName: value })
        },
        onChangeLastname: (e) => {
            var value = e.target.value;
            if (value !== "" && !value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g)) {
                setUserInfoErrors({ ...userInfoErrors, lastNameError: true })
            } else if (value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g) || value !== "") {
                setUserInfoErrors({ ...userInfoErrors, lastNameError: false })
            }
            setUserInfo({ ...userInfo, lastname: value })
        },
        onChangeCountry: (e) => {
            // var value = e.target.value;
            // if (value !== "" && !value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g)) {
            //     setUserInfoErrors({ ...userInfoErrors, countryError: true })
            // } else if (value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g) || value !== "") {
            //     setUserInfoErrors({ ...userInfoErrors, countryError: false })
            // }
            // setUserInfo({ ...userInfo, country: value })
            var value = e.target.value; if (value !== "") { setUserInfoErrors({ ...userInfoErrors, countryError: false }) } setUserInfo({ ...userInfo, country: value });
        },
        onChangeEmail: (e) => {
            var value = e.target.value;
            if (value !== "" && !value.match(/^[\w\-.]+@([\w-]+\.)+[\w-]{2,}/)) {
                setUserInfoErrors({ ...userInfoErrors, emailError: true })
            } else if (value.match(/^[\w\-.]+@([\w-]+\.)+[\w-]{2,}/) || value === "") {
                setUserInfoErrors({ ...userInfoErrors, emailError: false })
            }
            setUserInfo({ ...userInfo, email: value })
        },
        onChangePrefix: (e) => {
            var value = e.target.value;
            if (value === '') {
                setUserInfoErrors({ ...userInfoErrors, prefixError: true })
            }
            setUserInfo({ ...userInfo, prefix: value })
        },
        onChangeTel: (e) => {
            var value = e.target.value;
            const regex = /^[0-9\s]*$/;
            if (value !== "" && !value.match(regex)) {
                setUserInfoErrors({ ...userInfoErrors, mobError: true })
            } else if (value.match(regex) || value !== "") {
                setUserInfoErrors({ ...userInfoErrors, mobError: false })
            }
            setUserInfo({ ...userInfo, mob: value })
        },
    }
    const hasEmptyFields = () => {
        return Object.values(userInfo).some(value => value === '');
    };

    const handleContact = (e) => {
        e.preventDefault();
        const validation = Object.values(userInfoErrors);
        const confirmation = validation.includes(true);
        if (!confirmation && hasEmptyFields() === false) {
            setLoadingButton(true);
            FetchData.create('/email/contactUs', {
                user: userInfo,
                lang: language
            })
            .then(res => {
                if (res.data.success) {
                    setAlert({
                        open: true,
                        msg: res.data.msg,
                        severity: res.data.severity
                    })
                }
                setUserInfo(defaultUserInfo);
                setUserInfoErrors(defaultUserInfoError);
                setState(false);
                setLoadingButton(false);
            })
            .catch(err => {
                console.log("Error:", err);
            })
        }else{
            setLoadingButton(false);
        }
    }

    const handleClose = (event, reason) => {
        setTimeout(() => {
            setAlert({ ...alert, open: false });
        }, 5000)
    };

    return (
        <Box sx={{width: {md: '600px', xs: '90%'}, height: 'fit-content', padding: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#FFF'}}>
            <Box onSubmit={(e) => handleContact(e)} component={'form'} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '10px', width: '90%', marginX: 'auto'}}>
                <Box sx={{display: 'grid', gridTemplateColumns: '1fr 20px', columnGap: '15px'}}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%', marginX: 'auto' }}>
                        <Typography variant="h4" sx={{fontSize: '25px'}} color={'#002856'}>{lng[language]['contact']}</Typography>
                    </Box>
                    <Box onClick={() => setState(false)} sx={{ marginLeft: 'auto', color: '#9f9f9f', height: '20px', cursor: 'pointer' }}>
                        <CloseIcon fontSize='large' />
                    </Box>
                </Box>
                <Box sx={{display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '15px'}}>
                    <Box>
                        <InputLabel sx={{ fontWeight: '600', marginBottom: '10px', color: '#002856', marginLeft: '15px', fontSize: '16px' }}>{lng[language]['name']}</InputLabel>
                        <TextField
                            fullWidth
                            placeholder={lng[language]['placeholderFirstname']}
                            sx={inputStyle}
                            value={userInfo.fullName}
                            error={userInfoErrors.fullNameError}
                            onChange={(e) => inputFunc.onChangeName(e)}
                        />
                    </Box>

                    <Box>
                        <InputLabel sx={{ fontWeight: '600', marginBottom: '10px', color: '#002856', marginLeft: '15px', fontSize: '16px' }}>{lng[language]['lastName']}</InputLabel>
                        <TextField
                            fullWidth
                            placeholder={lng[language]['placeholderLastname']}
                            sx={inputStyle}
                            value={userInfo.lastname}
                            error={userInfoErrors.lastNameError}
                            onChange={(e) => inputFunc.onChangeLastname(e)}
                        />
                    </Box>
                </Box>
                
                <Box>
                    <InputLabel sx={{ fontWeight: '600', marginBottom: '10px', color: '#002856', marginLeft: '15px', fontSize: '16px' }}>{lng[language]['email']}</InputLabel>
                    <TextField
                        fullWidth
                        placeholder={lng[language]['placeholderEmail']}
                        sx={inputStyle}
                        value={userInfo.email}
                        error={userInfoErrors.emailError}
                        onChange={(e) => inputFunc.onChangeEmail(e)}
                    />
                </Box>

                <Box>
                    <InputLabel sx={{ fontWeight: '600', marginBottom: '10px', color: '#002856', marginLeft: '15px', fontSize: '16px' }}>{lng[language]['countty2']}</InputLabel>
                    <FormControl sx={{ '.MuiInputBase-root': { borderRadius: 0 }, width: '100%'}}>
                        <Select onChange={(e) => inputFunc.onChangeCountry(e)} error={userInfoErrors.countryError} value={userInfo.country} placeholder={lng[language]['placeholderCuntry']}>
                            {
                                Countries.map((data, index) => (
                                    <MenuItem key={index} value={data.countryId}>{data.country} {data.flag}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Box>

                <Box>
                    <InputLabel sx={{ fontWeight: '600', marginBottom: '10px', color: '#002856', marginLeft: '15px', fontSize: '16px' }}>{lng[language]['phone']}</InputLabel>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <FormControl sx={{ '.MuiInputBase-root': { borderRadius: 0 }, width: '120px', '.MuiSelect-select': {paddingY: '16.5px !important', paddingX: '5px'}}}>
                            <Select onChange={(e) => inputFunc.onChangePrefix(e)}>
                                {
                                    Countries.map((data, index) => (
                                        <MenuItem key={index} value={data.phone_code}>{data.phone_code} {data.flag}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <TextField
                            type='tel'
                            fullWidth
                            placeholder={lng[language]['placeholderMobile']}
                            sx={[{ marginLeft: '10px' }, inputStyle]}
                            value={userInfo.mob}
                            error={userInfoErrors.mobError}
                            onChange={(e) => inputFunc.onChangeTel(e)}
                        />
                    </Box>
                </Box>

                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Checkbox
                        checked={userInfo.checked}
                        onChange={handleCheck}
                        sx={{'&.MuiButtonBase-root': {borderRadius: 0, border: 'solid 1px #002856 !important'}, '&.Mui-checked': {color: '#002856'}}}
                    />
                    <Typography variant="body1" sx={{ marginLeft: '20px', color: '#002856', fontSize: '14px' }}>
                        {lng[language]['updated']}
                    </Typography>
                </Box>

                <Button disabled={loadingButton ? true : false} type='submit' sx={{ width: '100%', paddingY: '10px', borderRadius: 0, backgroundColor: loadingButton ? '#002856 !important' : '#002856', border: 'solid 1px #002856', '&:hover': { backgroundColor: '#FFF', color: '#002856' } }} variant='contained'>
                    {
                        loadingButton ?
                        <CircularProgress sx={{width: '25px !important', height: '25px !important', color: '#FFF'}} />
                        :
                        lng[language]['sendBtnContact']
                    }
                </Button>
            </Box>
            {
                alert.open &&
                <Box sx={{ width: '100%', bottom: '0', display: 'flex', position: 'fixed', alignItems: 'center', justifyContent: 'center' }}>
                    <Alert onClose={handleClose} severity={alert.severity} variant="filled" sx={{'.MuiAlert-action': {display: 'none'}, display: 'flex', justifyContent: 'center', backgroundColor: '#002856', width: '100%'}}>
                        {lng[language][alert.msg]}
                    </Alert>
                </Box>
            }
        </Box>
    )
}