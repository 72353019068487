import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography'
import { setCookies } from '../../utils/cookies';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import Slide from '@mui/material/Slide';
import { changeConsent } from '../../app/consent/ConsentRedux';



const acceptBtn = { border: 'solid 1px #FFF', borderRadius: '50px', backgroundColor: '#FFF', boxShadow: 0, paddingX: '30px', paddingY: '10px', marginRight: { md: '20px', xs: 'unset' }, color: '#002856', fontSize: { md: '0.875rem', xs: '12px' }, ':hover': {backgroundColor: '#002856', color: '#FFF'} }
const refuseBtn = { border: 'solid 1px #FFF', borderRadius: '50px', backgroundColor: '#002856', boxShadow: 0, paddingX: '30px', paddingY: '10px', marginRight: { md: '20px', xs: 'unset' }, fontSize: { md: '0.875rem', xs: '12px' }, marginTop: { md: 'unset', xs: '10px' }, ':hover': {backgroundColor: '#FFF', color: '#002856'} }
const checkedStyle = { '&.Mui-checked': { color: '#002856' } }

export default function ConsentScreen() {
    const language = useSelector((state) => state.language.language);
    const disatch = useDispatch();
    const [openModal, setOpenModal] = useState(false)
    const handleAllCookies = () => {
        setCookies(true, true, true, true, true);
        disatch(changeConsent({
            conesntGiven: true,
            loading: false,
            pathTracking: true
        }));
        setOpenModal(false);
    }
    const [checked, setChecked] = React.useState({
        google: true,
        facebook: true,
        timezone: true,
        marketing: true,
        googleAds: true
    });
    const handleLimitedCookies = () => {
        setCookies(true, checked.timezone, checked.facebook, checked.google, checked.googleAds);
        disatch(changeConsent({
            conesntGiven: true,
            loading: false,
            pathTracking: true
        }));
        setOpenModal(false);
    }
    const handleChange = (event, name) => {
        let value = event.target.checked;
        setChecked({
            ...checked,
            [name]: value
        });
    };
    return (
        <Box sx={{padding: '15px', width: '100%', height: 'auto', backgroundColor: '#002856', zIndex: 9999, display: 'flex', flexDirection: 'column', color: '#FFF' }}>
            <Box sx={{ width: { md: '90%', xs: '100%' }, marginX: 'auto', display: 'grid', gridTemplateRows: 'auto', rowGap: '20px' }}>
                <Box>
                    {
                        language === 'alb' &&
                        <Typography variant="body1" sx={{ fontWeight: 600, textAlign: 'center', fontSize: { md: '1rem', xs: '12px' }, fontFamily: '"Arial",sans-serif' }}>
                            Kjo faqe interneti përdor Cookies. Ne përdorim Cookies për t'u siguruar që ju të keni përvojën më të mirë në faqen tonë të internetit. Ju lutemi lexoni <Box component={Link} to="/pricaypolicy" sx={{ color: '#FFF', textDecoration: 'underline' }}>politikën tonë të privatësisë</Box>.
                        </Typography>
                    }
                    {
                        language === 'eng' &&
                        <Typography variant="body1" sx={{ fontWeight: 600, textAlign: 'center', fontSize: { md: '1rem', xs: '12px' }, fontFamily: '"Arial",sans-serif' }}>
                            This website uses Cookies. We use Cookies to make sure you get the best experience on our website. Please read our <Box component={Link} to="/pricaypolicy" sx={{ color: '#FFF', textDecoration: 'underline' }}>privacy policy</Box>.
                        </Typography>
                    }
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: { md: 'row', xs: 'column' } }}>
                    <Button variant='contained' sx={[acceptBtn, {width: {md: 'unset', xs: '260px'}}]} onClick={() => handleAllCookies()}>
                        {language === 'alb' && 'PO PRANOJ COOKIES'}
                        {language === 'eng' && 'YES I ACCEPT COOKIES'}
                    </Button>
                    <Button variant='contained' sx={[refuseBtn, {width: {md: 'unset', xs: '260px'}}]} onClick={() => setOpenModal(!openModal)}>

                        {language === 'alb' && 'JO DUA TË MENAXHOJ COOKIES E MIA'}
                        {language === 'eng' && 'NO. I WANT TO MANAGE COOKIES'}
                    </Button>
                </Box>

                {
                    <Slide direction="right" in={openModal}>
                        <Box sx={{ position: 'fixed', left: 0, top: 0, width: { sm: 320, xs: '100%' }, height: '100%', backgroundColor: '#FFF', borderRight: { sm: '1px solid #002856', xs: 'none' }, overflowY: 'scroll', zIndex: 999 }}>

                            <Box onClick={() => setOpenModal(false)} sx={{ position: 'absolute', right: '20px', top: '20px', cursor: 'pointer' }}>
                                <CloseIcon sx={{ fill: '#707070' }} />
                            </Box>

                            <Box sx={{ width: '90%', marginX: 'auto' }}>

                                <Box sx={{ marginTop: '60px' }}>
                                    <Typography variant="body2" color={'#002856'} sx={{ fontSize: '12px' }}>
                                        Fundway uses Cookies and similar technologies that are necessary to operate the site
                                        <br />
                                        With your consent, additional third-party will be used to improve our service, and to provide you with personalized content and advertising.
                                    </Typography>
                                </Box>

                                <Box sx={{ display: 'grid', gridTemplateRows: 'auto', rowGap: '15px', marginTop: '25px', color: '#002856' }}>

                                    <Box>
                                        <Box sx={{ borderBottom: '1px solid #707070', paddingBottom: '5px' }}>
                                            <Typography variant="h5">Settings</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Checkbox defaultChecked={true} disabled />
                                            <Typography variant="subtitle1" color={'#707070'}>Sessions</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Checkbox defaultChecked={true} disabled />
                                            <Typography variant="subtitle1" color={'#707070'}>CSRF Protection</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Checkbox checked={checked.timezone} onChange={(e) => handleChange(e, 'timezone')} sx={checkedStyle} />
                                            <Typography variant="subtitle1" color={'#707070'}>Time Zone</Typography>
                                        </Box>
                                    </Box>

                                    <Box>
                                        <Box sx={{ borderBottom: '1px solid #707070', paddingBottom: '5px' }}>
                                            <Typography variant="h5">Analytics</Typography>
                                        </Box>
                                        <Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Checkbox checked={checked.google} onChange={(e) => handleChange(e, 'google')} sx={checkedStyle} />
                                                <Typography variant="subtitle1" color={'#707070'}>Google Analytics</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Checkbox checked={checked.facebook} onChange={(e) => handleChange(e, 'facebook')} sx={checkedStyle} />
                                                <Typography variant="subtitle1" color={'#707070'}>Facebook Meta Pixel</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Checkbox checked={checked.marketing} onChange={(e) => handleChange(e, 'marketing')} sx={checkedStyle} />
                                                <Typography variant="subtitle1" color={'#707070'}>Marketing</Typography>
                                            </Box>

                                        </Box>
                                    </Box>

                                    <Box>
                                        <Box sx={{ borderBottom: '1px solid #707070', paddingBottom: '5px' }}>
                                            <Typography variant="h5">Ads</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Checkbox checked={checked.googleAds} onChange={(e) => handleChange(e, 'googleAds')} sx={checkedStyle} />
                                            <Typography variant="subtitle1" color={'#707070'}>Google Ads</Typography>
                                        </Box>
                                    </Box>

                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Button variant='contained' sx={{ background: '#002856', paddingY: '10px', paddingX: '30px', borderRadius: 0, marginTop: '30px' }} onClick={() => handleLimitedCookies()}>
                                    Accept Cookies
                                </Button>
                            </Box>

                        </Box>
                    </Slide>
                }


            </Box>
        </Box>
    )
}