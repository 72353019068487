import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography'
import { lng } from '../utils/language';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';


export default function HomePageGridTemp() {
    const language = useSelector((state) => state.language.language);
  return (
    <Box sx={{display: 'grid', gridTemplateColumns: {md: '0.7fr 1fr', xs: 'unset'}, columnGap: '20px', gridTemplateRows: 'auto auto', rowGap: {md: 'unset', xs: '50px'}}}>
        <Box sx={{gridRowStart: {md: '1', xs: 2}, gridRowEnd: {md: '2', xs: 3}}}>
            <Typography variant="body1" sx={{whiteSpace: 'pre-line', color: '#002856'}}>
                {lng[language]['homepageLast']}
            </Typography>
            <Box sx={{display: 'flex', width: '90%', marginX: {md: 'unset', xs: 'auto'}}}>
                <Button LinkComponent={Link} to="/properties" sx={{marginTop: '40px', backgroundColor: '#002856', color: '#FFF', border: 'solid 1px #002856', borderRadius: 0, padding: '10px 20px', '&:hover':{color: '#002856', backgroundColor: '#FFF'}, width: '100%'}}>
                    {lng[language]['seeApartments']}
                </Button>
            </Box>
        </Box>
        <Box sx={{display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridTemplateRows: 'repeat(3, 1fr)', rowGap: '20px', columnGap: '20px', gridRowStart: {md: '1', xs: 1}, gridRowEnd: {md: '2', xs: 2}}}>

            <Box sx={{gridRowStart: '1',gridRowEnd: '3',gridColumnStart: '1',gridColumnEnd: '2'}}>
                <Box component={'img'} src='https://fundway.com/images/2024/1stImage.png' alt='' sx={{width: '100%', height: '100%', objectFit: 'cover'}} />
            </Box>

            <Box sx={{gridRowStart: '1',gridRowEnd: '2',gridColumnStart: '2',gridColumnEnd: '3', display: 'grid', gridTemplateRows: 'auto', rowGap: '5px'}}>
                <Box component={'img'} src='https://fundway.com/images/2024/2ndImage.png' alt='' sx={{width: '100%', height: 'auto', objectFit: 'cover'}} />
                <Typography variant="subtitle1" sx={{fontSize: '15px', color: '#002856', fontWeight: 'bold'}}>
                    {
                        language === 'alb' 
                        ?
                        'PARKU KOMBËTAR I BUTRINTIT'
                        :
                        'BUTRINT NATIONAL PARK'
                    }
                </Typography>
            </Box>

            <Box sx={{gridRowStart: '2',gridRowEnd: '3',gridColumnStart: '2',gridColumnEnd: '3', display: 'grid', gridTemplateRows: 'auto', rowGap: '5px'}}>
                <Box component={'img'} src='https://fundway.com/images/2024/3rdImage.png' alt='' sx={{width: '100%', height: 'auto', objectFit: 'cover'}} />
                <Typography variant="subtitle1" sx={{fontSize: '15px', color: '#002856', fontWeight: 'bold'}}>
                    {
                        language === 'alb' 
                        ?
                        'QYTETI MUZE I BERATIT'
                        :
                        'MUSEUM CITY OF BERAT'
                    }
                </Typography>
            </Box>

            <Box sx={{gridRowStart: '3',gridRowEnd: '4',gridColumnStart: '1',gridColumnEnd: '2', display: 'grid', gridTemplateRows: 'auto', rowGap: '5px'}}>
                <Box component={'img'} src='https://fundway.com/images/2024/4thImage.png' alt='' sx={{width: '100%', height: 'auto', objectFit: 'cover'}} />
                <Typography variant="subtitle1" sx={{fontSize: '15px', color: '#002856', fontWeight: 'bold'}}>
                    {
                        language === 'alb' 
                        ?
                        'VLORA INTERNATIONAL AIRPORT'
                        :
                        'VLORA INTERNATIONAL AIRPORT'
                    }
                </Typography>
            </Box>

            <Box sx={{gridRowStart: '3',gridRowEnd: '4',gridColumnStart: '2',gridColumnEnd: '3', display: 'grid', gridTemplateRows: 'auto', rowGap: '5px'}}>
                <Box component={'img'} src='https://fundway.com/images/2024/5thImage.png' alt='' sx={{width: '100%', height: 'auto', objectFit: 'cover'}} />
                <Typography variant="subtitle1" sx={{fontSize: '15px', color: '#002856', fontWeight: 'bold'}}>
                    {
                        language === 'alb' 
                        ?
                        'QYTETI MUZE I GJIROKASTRËS'
                        :
                        'MUSEUM CITY OF GIROKASTRA'
                    }
                </Typography>
            </Box>

        </Box>

    </Box>
  )
}