import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import FetchData from '../api/FetchData';
// import { addPath } from '../app/consent/ConsentRedux';
// import { useGeolocated } from 'react-geolocated';

export const areCookiesEnabled = () => {
    Cookies.set('test_cookie', '1');
    const isEnabled = Cookies.get('test_cookie') === '1';
    Cookies.remove('test_cookie');
    return isEnabled;
}

export const setCookies = (statistics, timezone, facebook, googleAnalytics, googleAds) => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 30);
    const csrfToken = uuidv4();

    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    Cookies.set('csrf_token', csrfToken, {
        expires: 30,           // 30 days
        secure: false,           // Send cookie over HTTPS only
        sameSite: 'Strict',     // Protect against CSRF,
        path: '/' 
    });

    Cookies.set('csrf_expire', expirationDate.toISOString(), {
        expires: 30,           // 30 days
        secure: false,           // Send cookie over HTTPS only
        sameSite: 'Strict',     // Protect against CSRF,
        path: '/' 
    });

    // ENABLE TIME ZONE TRACKING
    Cookies.set('csrf_time_zone_enabled', timezone, { expires: 30, secure: false, sameSite: 'Strict', path: '/' });

    // ENABLE FACEBOOK META PIXEL
    Cookies.set('meta_pixel', facebook, { expires: 30, secure: false, sameSite: 'Strict' });
    // ENABLE GOOGLE ANALYTICS
    Cookies.set('google_analytics', googleAnalytics, { expires: 30, secure: false, sameSite: 'Strict', path: '/' });
    // ENABLE GOOGLE ADS
    Cookies.set('google_ads', googleAds, { expires: 30, secure: false, sameSite: 'Strict' });

    if (statistics) {
        Cookies.set('csrf_path_enable', statistics, { expires: 30, secure: false, sameSite: 'Strict', path: '/' });
        Cookies.set('csrf_statistics_enable', statistics, { expires: 30, secure: false, sameSite: 'Strict', path: '/' });
    }

    FetchData.create('/cookies/cookiesAuth', {
        token: csrfToken,
        experation: expirationDate.toISOString(),
        timezone: timezone ? userTimezone : null
    })

    FetchData.create('/cookies/startsession', {
        token: csrfToken,
    })

}

export const checkCookies = () => {
    const cookie = Cookies.get('csrf_token');
    const experation = Cookies.get('csrf_expire');
    const pathTracking = Cookies.get('csrf_path_enable');
    if (cookie && experation) {
        const targetDate = new Date(experation);
        const currentDate = new Date();
        if (targetDate > currentDate) {
            return {
                valid: true,
                pathTracking: pathTracking,
                token: cookie
            };
        } else if (targetDate < currentDate) {
             return {
                valid: false,
                pathTracking: false
            };
        } else {
             return {
                valid: false,
                pathTracking: false
            };
        }
    } else {
         return {
                valid: false,
                pathTracking: false
            };
    }
}

export const cookiePaths = (data) => {
    const isEnabled = Cookies.get('csrf_path_enable') === "true";
    if (!isEnabled) return false;
    let oldPaths = Cookies.get('csrf_path');
    oldPaths = oldPaths ? JSON.parse(oldPaths) : [];
    if (data) {
        oldPaths.push(data);
        // addPath(JSON.stringify(oldPaths));
        Cookies.set('csrf_path', JSON.stringify(oldPaths), { 
            expires: 30, 
            secure: false, 
            sameSite: 'Strict',
            path: '/'
        });
    } else if (oldPaths.length === 0) {
        oldPaths = [window.location.pathname];
        // addPath(JSON.stringify(oldPaths));
        Cookies.set('csrf_path', JSON.stringify(oldPaths), { 
            expires: 30, 
            secure: false, 
            sameSite: 'Strict',
            path: '/'
        });
    }

}