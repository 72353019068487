import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { lng } from '../utils/language'
// import SimpleMortgageCalculator from './card/SimpleMortgageCalculator'
import { AnimatePresence, motion } from 'framer-motion';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { financingType } from '../utils/utils';
import NormalCalculator from './card/NormalCalculator';

export default function CalculatorContainer({
    id,
    price,
    status,
    // OPEN MODAL IN DESKTOP
    setDesktopModal,
    desktopModal,

    // OPEN MODAL IN MOBILE
    setMobileModal,
    mobileModal,

    // STATE FOR ANIMATION IN MOBILE
    openSelfPayingPlan,
    setOpenSelfPayingPlan,

    openPayingWithBank,
    setOpenPayingWithBank,

    openCalculator,
    setOpenCalculator,

}) {
    const language = useSelector((state) => state.language.language)
    const navigate = useNavigate();

    return (
        <Box>
            {
                mobileModal &&
                <Box sx={{ display: { md: 'none', xs: 'block' }, backgroundColor: '#FFF', position: 'fixed', top: 0, left: 0, height: '100%', width: '100%', zIndex: 999, overflow: 'scroll' }}>
                    <Box sx={{ gridTemplateRows: 'auto 1fr', display: 'grid' }}>
                        <Box sx={{ width: '80%', marginX: 'auto', display: 'flex', justifyContent: 'flex-end', marginTop: '30px' }}>
                            <CloseIcon onClick={() => setMobileModal(false)} sx={{ cursor: 'pointer' }} />
                        </Box>
                        <Box sx={{ width: '80%', marginX: 'auto' }}>
                            <Box sx={{ display: 'grid', gridTemplateRows: '80px 1fr' }}>
                                <Box sx={{ marginTop: '20px', display: 'flex', borderBottom: 'solid 1px', paddingY: '20px', cursor: 'pointer', justifyContent: 'space-between', backgroundColor: 'unset', color: '#002856', }} onClick={() => setOpenSelfPayingPlan(!openSelfPayingPlan)}>
                                    <Typography variant="h6" sx={{ fontSize: '18px' }}>{lng[language]['selfPayingPlan']}</Typography>
                                    <Box>
                                        <KeyboardArrowRightIcon />
                                    </Box>
                                </Box>
                                <AnimatePresence >
                                    {
                                        openSelfPayingPlan === true ?
                                            <Box component={motion.section} key="content" initial="collapsed" animate="open" exit="collapsed" variants={{ open: { opacity: 1, height: "auto" }, collapsed: { opacity: 0, height: 0 } }} transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }} sx={{ marginTop: '20px' }}>

                                                <Box sx={{ display: 'grid', gridTemplateRows: '1fr 1fr 1fr 1fr 1fr 1fr', rowGap: '20px' }}>
                                                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px ', columnGap: '10px' }}>
                                                        <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['installments']}</Typography>
                                                        <Typography>%</Typography>
                                                        {/* <Typography>&euro;</Typography> */}
                                                    </Box>

                                                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px ', columnGap: '10px', textAlign: 'left' }}>
                                                        <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader1']} {lng[language]['selfPayingPlanList1']}</Typography>
                                                        <Typography>30%</Typography>
                                                        {/* <Typography>{new Intl.NumberFormat('us-US', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format((30 / 100) * price)}</Typography> */}
                                                    </Box>

                                                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px ', columnGap: '10px' }}>
                                                        <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader2']} {lng[language]['selfPayingPlanList2']}</Typography>
                                                        <Typography>30%</Typography>
                                                        {/* <Typography>{new Intl.NumberFormat('us-US', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format((30 / 100) * price)}</Typography> */}
                                                    </Box>

                                                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px ', columnGap: '10px' }}>
                                                        <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader3']} {lng[language]['selfPayingPlanList3']}</Typography>
                                                        <Typography>30%</Typography>
                                                        {/* <Typography>{new Intl.NumberFormat('us-US', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format((30 / 100) * price)}</Typography> */}
                                                    </Box>

                                                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px ', columnGap: '10px' }}>
                                                        <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader4']} {lng[language]['selfPayingPlanList4']}</Typography>
                                                        <Typography>10%-5000&euro;</Typography>
                                                        {/* <Typography>{new Intl.NumberFormat('us-US', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format(((10 / 100) * price) - 5000)}</Typography> */}
                                                    </Box>
                                                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px ', columnGap: '10px' }}>
                                                        <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader5']} {lng[language]['selfPayingPlanList5']}</Typography>
                                                        {/* <Typography></Typography> */}
                                                        <Typography>5000&euro;</Typography>
                                                    </Box>

                                                </Box>
                                                <Button onClick={() => { financingType('getOfferWithSelfFinancing'); navigate(`../reserve/${id}`) }} variant='contained' sx={{ borderRadius: 0, backgroundColor: '#002856', color: '#FFF', border: 'solid 1px #002856', paddingY: 1.4, marginTop: '20px', '&:hover': { color: '#002856', backgroundColor: '#FFF' } }}>
                                                    {lng[language]['takeOffer']}
                                                </Button>
                                            </Box>
                                            :
                                            ''
                                    }
                                </AnimatePresence>
                            </Box>

                            <Box sx={{ display: 'grid', gridTemplateRows: 'auto 1fr' }}>
                                    <Box sx={{ marginTop: '20px', display: 'flex', borderBottom: 'solid 1px', paddingY: '20px', cursor: 'pointer', justifyContent: 'space-between', color: openPayingWithBank ? '#002856' : '#002856', }} onClick={() => setOpenPayingWithBank(!openPayingWithBank)}>
                                        <Typography variant="h6" sx={{ fontSize: '18px' }}>{lng[language]['selfPayWithBank']}</Typography>
                                        <Box><KeyboardArrowRightIcon /></Box>
                                    </Box>

                                    <AnimatePresence initial={false}>
                                        {
                                            openPayingWithBank === true ?
                                                <Box
                                                    component={motion.section}
                                                    key="content"
                                                    initial="collapsed"
                                                    animate="open"
                                                    exit="collapsed"
                                                    variants={{
                                                        open: { opacity: 1, height: "auto" },
                                                        collapsed: { opacity: 0, height: 0 },
                                                    }}
                                                    transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                                                    sx={{ marginTop: '20px', paddingBottom: '70px' }}
                                                >
                                                    <Box>
                                                        <Typography variant="body1" sx={{ fontSize: '15px', whiteSpace: 'pre-wrap' }}>{lng[language]['offerWithBank']}</Typography>

                                                    </Box>

                                                    <Box>
                                                        <Button onClick={() => { financingType('getOfferWithBank'); navigate(`../reserve/${id}`); }} variant='contained' sx={{ borderRadius: 0, backgroundColor: '#002856', color: '#FFF', border: 'solid 1px #002856', paddingY: 1.4, marginTop: '20px', '&:hover': { color: '#002856', backgroundColor: '#FFF' } }}>
                                                            {lng[language]['takeOffer']}
                                                        </Button>
                                                    </Box>
                                                </Box>
                                                :
                                                ''
                                        }
                                    </AnimatePresence>

                                </Box>

                            <Box sx={{ display: 'grid', gridTemplateRows: 'auto 1fr' }}>
                                <Box sx={{ marginTop: '20px', display: 'flex', borderBottom: 'solid 1px', paddingY: '20px', cursor: 'pointer', justifyContent: 'space-between', backgroundColor: 'unset', color: '#002856', }} onClick={() => setOpenCalculator(!openCalculator)}>
                                    <Typography variant="h6" sx={{ fontSize: '18px' }}>{lng[language]['calculator']}</Typography>
                                    <Box><KeyboardArrowRightIcon /></Box>
                                </Box>
                                <AnimatePresence initial={false}>
                                    {
                                        openCalculator === true ?
                                            <Box component={motion.section} key="content" initial="collapsed" animate="open" exit="collapsed" variants={{ open: { opacity: 1, height: "auto" }, collapsed: { opacity: 0, height: 0 } }} transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }} sx={{ marginTop: '20px', paddingBottom: '70px' }}>
                                                <Box>
                                                    <Typography variant="body1" sx={{marginBottom: 2}}>{lng[language]['calculateTitle']}</Typography>
                                                </Box>
                                                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', marginX: 'auto' }}>
                                                    {/* <SimpleMortgageCalculator price={price} /> */}
                                                    <NormalCalculator />
                                                </Box>
                                                {/* <Button onClick={() => { financingType('getOfferWithBank'); navigate(`../reserve/${id}`) }} variant='contained' sx={{ borderRadius: 0, backgroundColor: '#002856', color: '#FFF', border: 'solid 1px #002856', paddingY: 1.4, marginTop: '20px', '&:hover': { color: '#002856', backgroundColor: '#FFF' } }}>
                                                    {lng[language]['takeOffer']}
                                                </Button> */}
                                            </Box>
                                            :
                                            ''
                                    }
                                </AnimatePresence>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            }



            {
                desktopModal &&
                <Box sx={{ display: { md: 'flex', xs: 'none' }, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) )', zIndex: 997, justifyContent: 'center', alignItems: 'center' }}>
                    <Box sx={{ display: 'grid', background: '#FFF', height: 'auto', paddingY: '20px', width: '1200px', gridTemplateColumns: '1fr 1fr', position: 'relative' }}>
                        <Box sx={{ position: 'absolute', top: '20px', right: '20px', cursor: 'pointer' }} onClick={() => setDesktopModal(false)}>
                            <CloseIcon />
                        </Box>
                        <Box sx={{ width: '100%', borderRight: '0.5px solid #c4c4c4' }}>
                            <Box sx={{ width: '90%', marginX: 'auto', gridTemplateRows: '60px 1fr', display: 'grid' }}>
                                <Typography variant="h5" fontWeight={'normal'} sx={{ width: '90%', fontSize: '28px' }}>{lng[language]['selfPayingPlan']}</Typography>
                                <Box>
                                    <Box sx={{ display: 'grid', gridTemplateRows: '1fr 1fr 1fr 1fr 1fr 1fr', rowGap: '20px' }}>
                                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px ', columnGap: '10px' }}>
                                            <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['installments']}</Typography>
                                            <Typography>%</Typography>
                                            {/* <Typography>&euro;</Typography> */}
                                        </Box>
                                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px ', columnGap: '10px', textAlign: 'left' }}>
                                            <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader1']} {lng[language]['selfPayingPlanList1']}</Typography>
                                            <Typography>30%</Typography>
                                            {/* <Typography>{new Intl.NumberFormat('us-US', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format((30 / 100) * price)}</Typography> */}
                                        </Box>
                                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px ', columnGap: '10px' }}>
                                            <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader2']} {lng[language]['selfPayingPlanList2']}</Typography>
                                            <Typography>30%</Typography>
                                            {/* <Typography>{new Intl.NumberFormat('us-US', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format((30 / 100) * price)}</Typography> */}
                                        </Box>
                                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px ', columnGap: '10px' }}>
                                            <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader3']} {lng[language]['selfPayingPlanList3']}</Typography>
                                            <Typography>30%</Typography>
                                            {/* <Typography>{new Intl.NumberFormat('us-US', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format((30 / 100) * price)}</Typography> */}
                                        </Box>
                                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px ', columnGap: '10px' }}>
                                            <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader4']} {lng[language]['selfPayingPlanList4']}</Typography>
                                            <Typography>10%-5000&euro;</Typography>
                                            {/* <Typography>{new Intl.NumberFormat('us-US', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format(((10 / 100) * price) - 5000)}</Typography> */}
                                        </Box>
                                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px ', columnGap: '10px' }}>
                                            <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader5']} {lng[language]['selfPayingPlanList5']}</Typography>
                                            {/* <Typography></Typography> */}
                                            <Typography>5000&euro;</Typography>
                                        </Box>
                                    </Box>
                                    <Button onClick={() => { financingType('getOfferWithSelfFinancing'); navigate(`../reserve/${id}`) }} variant='contained' sx={{ borderRadius: 0, backgroundColor: '#002856', color: '#FFF', border: 'solid 1px #002856', paddingY: 1.4, marginTop: '20px', '&:hover': { color: '#002856', backgroundColor: '#FFF' } }}>
                                        {lng[language]['takeOffer']}
                                    </Button>
                                </Box>

                            </Box>

                            <Box sx={{ marginTop: '25px' }}>
                                <Box sx={{ width: '90%', marginX: 'auto', gridTemplateRows: '100px 1fr', display: 'grid' }}>
                                    <Typography variant="h5" fontSize={'28px'} fontWeight={'normal'} sx={{ width: '80%' }}>{lng[language]['selfPayWithBank']}</Typography>
                                    <Box>
                                        <Typography variant="body1" sx={{ fontSize: '15px', whiteSpace: 'pre-wrap' }}>{lng[language]['offerWithBank']}</Typography>
                                    </Box>
                                    <Box>
                                        <Button onClick={() => { financingType('getOfferWithBank'); navigate(`../reserve/${id}`); }} variant='contained' sx={{ borderRadius: 0, backgroundColor: '#002856', color: '#FFF', border: 'solid 1px #002856', paddingY: 1.4, marginTop: '20px', '&:hover': { color: '#002856', backgroundColor: '#FFF' } }}>
                                            {lng[language]['takeOffer']}
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>

                        </Box>
                        <Box sx={{ width: '100%', borderLeft: '0.5px solid #c4c4c4' }}>
                            <Box sx={{ width: '90%', marginX: 'auto', gridTemplateRows: '60px 1fr', display: 'grid' }}>
                                <Typography variant="h5" fontWeight={'normal'} sx={{ width: '90%', fontSize: '28px' }}>{lng[language]['calculator']}</Typography>
                                <Box>
                                    <Box>
                                        <Typography variant="body1" sx={{marginBottom: 2}}>{lng[language]['calculateTitle']}</Typography>
                                    </Box>
                                    <Box>
                                        {/* <SimpleMortgageCalculator price={price} /> */}
                                        <NormalCalculator />
                                    </Box>
                                    {/* <Button onClick={() => { financingType('getOfferWithBank'); navigate(`../reserve/${id}`) }} variant='contained' sx={{ borderRadius: 0, backgroundColor: '#002856', color: '#FFF', border: 'solid 1px #002856', paddingY: 1.4, marginTop: '20px', '&:hover': { color: '#002856', backgroundColor: '#FFF' } }}>
                                        {lng[language]['takeOffer']}
                                    </Button> */}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            }
        </Box>
    )
}