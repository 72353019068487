import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
// import { Link } from 'react-router-dom';

export default function TextComponent({
// PROPS
    title, desc, button,
// STYLE
compStyle, titleStyle, descStyle, buttonStyle,
    //OTHERPROPS
    endIcon

}) {
    return (
        <Box sx={compStyle}>
            {
                title &&
                <Typography variant="h6" sx={titleStyle}>{title}</Typography>
            }
            {
                desc &&
                <Typography variant="h6" sx={descStyle}>{desc}</Typography>
            }
            {
                button &&
                <Button
                    sx={buttonStyle}
                    component={Link}
                    to={button.url}
                    endIcon={endIcon}
                >
                    {button.title}
                </Button>
            }
        </Box>
    )
}