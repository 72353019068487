import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { Button, CircularProgress, InputLabel, Slider } from '@mui/material';
import { lng } from '../../utils/language';
import { useSelector } from 'react-redux';

const inputStyle = {
    fieldset: { borderRadius: '0', borderWidth: '2px', },
    input: { textAlign: 'center' }
}

const inputContained = {
    borderRadius: 0,
    '&.MuiButton-contained': {
        backgroundColor: '#002856',
    },
    '&.MuiButton-outlined': {
        color: '#002856 !important',
        border: 'solid 1px #002856 !important'
    }
}

export default function NormalCalculator() {
    const language = useSelector((state) => state.language.language);
    // eslint-disable-next-line
    const [dataLoader ,setDataLoader] = useState(false);

    const [data, setData] = useState({
        amount: 0,
        percent: 20,
        loanTerm: 10,
        interestRate: 3.7
    })

    const [calc, setCalc] = useState({
        // objectValue: 0,
        downPayent: 0,
        // loanTerm: 10,
        monthlyPayment: '',
        // downPayentPercent: 20
    })

    const calcInputs = {
        objectValue: (e) => {
            let value = e.target.value;
            if (!isNaN(+value)) {
                setData({
                    ...data,
                    amount: value
                });
                calculate(value, null, null);
            }
        },
        downPayent: (e) => {
            let value = e.target.value;
            setData({
                ...data,
                percent: value
            });
            calculate(null, value, null);
        },
        loanTerm: (value) => {
            setData({
                ...data,
                loanTerm: value
            });
            calculate(null, null, value);
        }
    }

    const calculate = (price, downpaymentPercent, loanTerm) => {

        let realPrice = price;
        let realDownpaymentPercent = downpaymentPercent;
        let realLoanTerm = loanTerm;

        if (price === null) {
            realPrice = data.amount;
        }
        if (downpaymentPercent === null) {
            realDownpaymentPercent = data.percent;
        }
        if (loanTerm === null) {
            realLoanTerm = data.loanTerm;
        }

        let downpayment = (realDownpaymentPercent / 100) * realPrice;

        let loanAmount = realPrice - downpayment;

        let monthlyInterestRate = data.interestRate / 100 / 12;
        let numberOfPayments = realLoanTerm * 12;

        // CALCULATE MONTHLY PAYMENT START
        
        // CALCULATE MONTHLY PAYMENT END

        let monthlyPaymentData = (loanAmount * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numberOfPayments)) /
        (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);

        setCalc({
            ...calc, 
            downPayent: downpayment, 
            monthlyPayment: monthlyPaymentData.toFixed(2)
        });
    }


    return (

        <Box sx={{ color: '#11294D' }}>
        <Box sx={{ width: '100%', marginX: 'auto', display: 'grid', gridGap: '20px', gridTemplateColumns: '1fr' }}>
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', gridGap: '20px' }}>
                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '20px', alignContent: 'center' }}>
                    <Box>
                        <InputLabel sx={{ marginBottom: '5px', fontWeight: 500, fontSize: '15px' }}>
                            {lng[language]['priceTxt']}
                        </InputLabel>
                        <TextField 
                        // eslint-disable-next-line
                            error={data.amount == 0 ? true : false}
                            fullWidth 
                            sx={inputStyle} 
                            value={data.amount}
                            onChange={(e) => {calcInputs.objectValue(e)}} 
                        />
                    </Box>
                    <Box>
                        <InputLabel sx={{ marginBottom: '5px', fontWeight: 500, fontSize: '15px' }}>
                            {lng[language]['interest']}
                        </InputLabel>
                        <Box sx={{border: 'solid 2px #c4c4c4', padding: '16.5px', textAlign: 'center', height: '56px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Typography variant="subtitle1">{`${data.interestRate}%`}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box>
                    {/* <InputLabel sx={{ marginBottom: '5px', fontWeight: 500, fontSize: '15px' }}>
                        {lng[language]['priPayment']}
                    </InputLabel> */}
                    <Box>
                        <Typography sx={{ fontSize: '14px' }}>{lng[language]['prepaymentValue']}: {`${data.percent}%`} / {`${new Intl.NumberFormat('us-US', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format(calc.downPayent)}`} </Typography>
                        <Slider value={data.percent} sx={{'.MuiSlider-track': {color: '#11294D'}, '.MuiSlider-thumb': {color: '#11294D'}}} valueLabelDisplay="auto" size='small' shiftStep={20} step={10} marks min={20} max={80} onChange={(e) => calcInputs.downPayent(e)} />
                    </Box>
                </Box>
                <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <InputLabel sx={{ marginBottom: '5px', fontWeight: 500, fontSize: '15px' }}>
                            {lng[language]['period']}: {`${data.loanTerm}`} 
                        </InputLabel>
                        {/* <InputLabel sx={{ marginBottom: '5px', fontWeight: 500, fontSize: '15px' }}>
                            {lng[language]['inYears']}
                        </InputLabel> */}
                    </Box>
                    <Box>
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: {md: 'auto auto auto auto auto', xs: 'auto auto auto'},
                                columnGap: {md: '20px', xs: '10px'},
                                marginTop: '10px',
                                rowGap: {md: 'none', xs: '10px'}
                            }}
                        >
                            <Button 
                            // eslint-disable-next-line
                            disabled={data.amount == 0 ? true: false }
                            variant={
                                // eslint-disable-next-line
                                data.loanTerm == '10' ? 'contained' : 'outlined'
                            } sx={inputContained} onClick={() => calcInputs.loanTerm(10)}>
                                {
                                    dataLoader ?
                                    <CircularProgress sx={{ width: '25px !important', height: '25px !important', color: '#002856' }} />
                                    :
                                    10
                                }
                            </Button>
                            <Button 
                            // eslint-disable-next-line
                            disabled={data.amount == 0 ? true: false }
                            variant={
                                // eslint-disable-next-line
                                data.loanTerm == '15' ? 'contained' : 'outlined'
                            } sx={inputContained} onClick={() => calcInputs.loanTerm(15)}>
                                {
                                    dataLoader ?
                                    <CircularProgress sx={{ width: '25px !important', height: '25px !important', color: '#002856' }} />
                                    :
                                    15
                                }
                            </Button>
                            <Button 
                            // eslint-disable-next-line
                            disabled={data.amount == 0 ? true: false }
                            variant={
                                // eslint-disable-next-line
                                data.loanTerm == '20' ? 'contained' : 'outlined'
                            } sx={inputContained} onClick={() => calcInputs.loanTerm(20)}>
                                {
                                    dataLoader ?
                                    <CircularProgress sx={{ width: '25px !important', height: '25px !important', color: '#002856' }} />
                                    :
                                    20
                                }
                            </Button>
                            <Button  
                            // eslint-disable-next-line
                            disabled={data.amount == 0 ? true: false }
                            variant={
                                // eslint-disable-next-line
                                data.loanTerm == '25' ? 'contained' : 'outlined'
                            } sx={inputContained} onClick={() => calcInputs.loanTerm(25)}>
                                {
                                    dataLoader ?
                                    <CircularProgress sx={{ width: '25px !important', height: '25px !important', color: '#002856' }} />
                                    :
                                    25
                                }
                            </Button>
                            <Button 
                            // eslint-disable-next-line
                            disabled={data.amount == 0 ? true: false }
                            variant={
                                // eslint-disable-next-line
                                data.loanTerm == '30' ? 'contained' : 'outlined'
                                } sx={inputContained} onClick={() => calcInputs.loanTerm(30)}>
                                {
                                    dataLoader ?
                                    <CircularProgress sx={{ width: '25px !important', height: '25px !important', color: '#002856' }} />
                                    :
                                    30
                                }
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: '1fr',
                gridGap: '20px'
            }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: 'solid 1px #c4c4c4', paddingBottom: 1 }}>
                    <Typography variant="body1">{lng[language]['monthlyInters']}</Typography>
                    <Typography variant="body1">{calc.monthlyPayment ? `${new Intl.NumberFormat('us-US', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format(calc.monthlyPayment)}` : 'NaN'}</Typography>
                </Box>
                {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: 'solid 1px #c4c4c4', paddingBottom: 1 }}>
                    <Typography variant="body1">{lng[language]['totalPayment']}</Typography>
                    <Typography variant="body1">{calcData.totalInteres ? `${new Intl.NumberFormat('us-US', { style: 'currency', currency: 'EUR' }).format(calcData.totalInteres)}` : 'NaN'}</Typography>
                </Box> */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="body1">{lng[language]['totalInteres']}</Typography>
                    <Typography variant="body1">{new Intl.NumberFormat('us-US', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format((data.amount - calc.downPayent))}</Typography>
                </Box>
            </Box>
            <Box>
                <Typography variant="caption" sx={{fontSize: '10px'}}>{lng[language]['liability']}</Typography>
            </Box>
        </Box>

    </Box>
        
    )
}