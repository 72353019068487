import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { api } from '../../api/config';

const initialState = {
    isConsentGiven: false,
    loading: true,
    pathTracking: false,
    token: null,
    user: '',
    userPath: []
}

export const addPath = createAsyncThunk("cookies/addPath", async (data, thunkAPI) => {
    const response = await api.post("/cookies/addPath", {
        token: data.token,
        user: data.user,
        path: data.path,
        exp: data.exp,
    });
    return response?.data
});

export const checkSession = createAsyncThunk('', async(data) => {
    const response = await api.post('/cookies/checkSession', {
        token: data.token,
    })
    return response?.data
})

export const consentSlice = createSlice({
    name: 'cookies',
    initialState,
    reducers: {
        check: (state, action) => {
            return state;
        },
        changeConsent: (state, action) => {
            if (action.payload) {
                return {
                    ...state,
                    isConsentGiven: action.payload.conesntGiven,
                    loading: action.payload.loading,
                    pathTracking: action.payload.pathTracking,
                    token: action.payload.userId
                }
            }
        },
    },
    extraReducers: (builder) => {
        // WHEN THE API CALL IS FULLFILEED
        builder.addCase(addPath.fulfilled, (state, action) => {
            let success = action?.payload?.success;
            if (success === true || success === "true") {
            }else{
            }
        })
        // WHEN THE API CALL IS PENDING
        builder.addCase(addPath.pending, (state, action) => {
        })
        // WHEN THE API CALL FAILED
        builder.addCase(addPath.rejected, (state, action) => {
        })
    }
});

export const { changeConsent, check } = consentSlice.actions

export default consentSlice.reducer