import React, { useEffect } from 'react'
import Box from '@mui/material/Box';
import MorgageCalculator from '../../components/MorgageCalculator';

export default function AboutUs() {

    // const [mortgageData, setMortgageData] = React.useState({
    //     purchasePrice: '350000',
    //     downPayment: '',
    //     loanTerm: '',
    //     loanInteres: '',
    //     monthlyPayment: ''
    // })

/*
    Paradhënja
    Kësti mujor
    Pagesa Totale
    Interesi total
*/

useEffect(() => {
    window.scrollTo(0, 0);
}, [])

    return (
        <Box sx={{paddingY: '60px'}}>
            
            <MorgageCalculator />
            
        </Box>
    )
}