import React from 'react';
import Box from '@mui/material/Box';

export default function Containers({ children }) {
    return (
        <Box 
            sx={{
                width: {
                    md: '1024px',
                    sm: '80%',
                    xs: '90%'
                },
                marginX: 'auto'
            }}
        >
            {children}
        </Box>
    )
}