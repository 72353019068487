import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MainFilter from '../../components/filters/MainFilter';
import TextComponent from '../../components/TextComponent';
import ResizencyCard from '../../components/card/ResizencyCard';
import VerticalCard from '../../components/card/VerticalCard';
import Button from '@mui/material/Button';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
// import style from './Overall.module.css';
import { useSelector } from 'react-redux';
import { lng } from '../../utils/language';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FetchData from '../../api/FetchData';
import SkeletonComp from '../../components/SkeletonComp';
import SkeletonLoader from './HomePageSkeleton.json';
import { Parallax, Background } from 'react-parallax';
import HomePageGridTemp from '../../layout/HomePageGridTemp';
// import fundwayVid from '../../assets/new_Vlora_Main_Banner.mp4';
import ShowVideoModule from '../../components/video/ShowVideoModule';
import SimpleImageSlider from "react-simple-image-slider";
// import { Player, BigPlayButton } from 'video-react';

const defaultSpacing = {
    marginY: '50px'
}

export default function Homepage() {

    const [filter, setFilter] = React.useState({
        typology: '',
        orientation: ''
    })

    const updateFilters = (data) => {
        setFilter(data)
        setSearchParams(data)
    }

    const resetFilters = () => {
        setFilter({
            typology: [],
            orientation: []
        })
    }

    const language = useSelector((state) => state.language.language);

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const search = () => {
        navigate(`/properties?${searchParams}`);
    }
    const [openMobileFilter, setOpenMobileFilter] = useState(false);
    const updateModal = () => {
        if (!openMobileFilter === true) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
        setOpenMobileFilter(!openMobileFilter)
    }



    const [freeAps, setFreeAps] = useState([]);
    const [loading, setLoading] = useState(true);
    const handleGet = () => {
        FetchData.create('/apartments/getSpecificApartemnt', {
            apartmentId: `1727, 1665, 1765, 1690, 1761, 1652`
        })
            .then(res => {
                setFreeAps(res.data.data)
                handleImageUpdate(res.data.data);
            })
    }

    const handleImageUpdate = (apsFirst) => {
        if (apsFirst) {
            let images = ['HomePage-01.webp', 'HomePage-02.webp', 'HomePage-03.webp', 'HomePage-04.webp', 'HomePage-05.webp', 'HomePage-06.webp'];
            // eslint-disable-next-line
            const updatedItems = apsFirst.map((item, index) => index === index ? {
                ...item,
                imageName: images[index]
            } : item);
            setFreeAps(updatedItems);
        }
        setLoading(false);
    }

    useEffect(() => {
        handleGet();
        // eslint-disable-next-line
    }, [])

    const bigImages = [
        {
            imageUrl: 'https://fundway.com/images/2024/Residenca.webp',
            title: lng[language]['vertTitle1'],
            desc: lng[language]['vertDesc1'],
            buttonUrl: '/amenities'
        },
        {
            imageUrl: 'https://fundway.com/images/2024/image-homepage-verticalCard-1.png',
            title: lng[language]['vertTitle2'],
            desc: lng[language]['vertDesc2'],
            buttonUrl: '/amenities'
        },
        {
            imageUrl: 'https://fundway.com/images/2024/Shetitorja.webp',
            title: lng[language]['vertTitle3'],
            desc: lng[language]['vertDesc3'],
            buttonUrl: '/amenities'
        },
    ]

    // const images = [
    //     `https://fundway.com/images/2024/HomepageImage1.png`,
    //     `https://fundway.com/images/2024/HomepageImage2.png`,
    //     `https://fundway.com/images/2024/HomepageImage3.png`,
    // ];

    const slider = [
        // {
        //     imageName: 'https://fundway.com/images/2024/MainBannerSlider_01.webp'
        // },
        // {
        //     imageName: 'https://fundway.com/images/2024/MainBannerSlider_02.webp'
        // },
        // {
        //     imageName: 'https://fundway.com/images/2024/MainBannerSlider_03.webp'
        // },
        // {
        //     imageName: 'https://fundway.com/images/2024/MainBannerSlider_04.webp'
        // },
        // {
        //     imageName: 'https://fundway.com/images/2024/MainBannerSlider_05.webp'
        // }
        'https://fundway.com/images/2024/MainBannerSlider_01.webp',
        'https://fundway.com/images/2024/MainBannerSlider_02.webp',
        'https://fundway.com/images/2024/MainBannerSlider_03.webp',
        'https://fundway.com/images/2024/MainBannerSlider_04.webp',
        'https://fundway.com/images/2024/MainBannerSlider_05.webp'
    ];

// backgroundRepeat: 'no-repeat', 
// backgroundImage: 'url("https://fundway.com/images/2024/LandingPicture_Main banner.webp")', 
// backgroundPosition: 'center', 

    return (
        <Box sx={{overflow: 'hidden'}}>
            {/* MAIN BANNER */}
            <Box sx={{height: { md: 'calc(100vh - 64px)', xs: 'calc(100vh - 56px)' }, display: 'grid',justifyItems: 'center',backgroundSize: 'cover',alignContent: 'space-around',gridTemplateRows: { md: '1fr 1fr 1fr', xs: 'repeat(4, 1fr)' },position: 'relative',}}>
                <Box sx={{display: { md: 'block', xs: 'none' },position: 'absolute',top: 0,left: 0,width: '100%',height: '100%',overflow: 'hidden'}}>
                    {/* <Box sx={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.25)'}}>
                    </Box> */}
                    <SimpleImageSlider
                        width={'100%'}
                        height={'100%'}
                        images={slider}
                        showBullets={false}
                        showNavs={false}
                        loop={true}
                        autoPlayDelay={2.5}
                        autoPlay={true}
                        navStyle={1}
                    />
                </Box>

                <Box sx={{position: 'relative', gridColumnStart: 1, gridColumnEnd: 4, gridRowStart: {sm: 1, xs: 1}, gridRowEnd: {md: 3, xs: 4}, display: 'none', alignItems: 'flex-start', justifyContent: {md: 'center', xs: 'unset'}, flexDirection: 'column',  zIndex: {md: 'unset', xs: 3}, width: {md: '1024px', xs: '90%'}, marginX: {md: 'auto', xs: 'auto'}}}>

                    <Typography variant="h6" sx={{color: '#FFFFFF', fontSize: {md: '53px', xs: '30px'}, marginTop: {md: 'unset', xs: '80px'}, width: {md: language === 'alb' ? '800px': '870px', xs: '100%'}, lineHeight: {md: '75px', xs: 'unset'}}}>
                        {lng[language]['homePageStartingTxt']}
                    </Typography>
                    <Typography variant="h6" sx={{color: '#FFFFFF', fontSize: {md: '39px', xs: '23px'}, marginTop: '40px', whiteSpace: 'pre-line', lineHeight: {md: '45px', xs: 'unset'}}}>
                        {lng[language]['homePageStartingDesc']}
                    </Typography>
                    <Button LinkComponent={Link} to="/properties" variant='contained' sx={{marginTop: '40px', boxShadow: 0, borderRadius: 0, background: '#FFF', color: '#53626F', paddingY: 1.5, border: 'solid 1px #53626F', '&:hover': {color: '#FFF', background: '#53626F'}, display: {md: 'block', xs: 'none'}}}>
                        {lng[language]['paymentPlans']}
                    </Button>

                </Box>

                <Box sx={{display: { md: 'none', xs: 'block' },position: 'absolute',top: 0,left: 0,width: '100%',height: '100%', overflow: 'hidden'}}>
                <Box sx={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.1)', zIndex: 2}}>
                </Box>
                <SimpleImageSlider
                        width={'100%'}
                        height={'100%'}
                        images={slider}
                        showBullets={false}
                        showNavs={false}
                        loop={true}
                        autoPlayDelay={2.5}
                        autoPlay={true}
                        navStyle={1}
                    />
                </Box>

                <Box sx={{ gridRowStart: { md: 3, xs: 'unset' }, gridRowEnd: { md: 4, xs: 'unset' },gridColumnStart: 1, gridColumnEnd: 4, alignSelf: 'flex-end', display: { md: 'flex', xs: openMobileFilter ? 'flex' : 'none' }, position: { md: 'inherit', xs: 'fixed' }, height: { md: 'auto', xs: '100%' }, width: { md: '700px', xs: '100%' }, top: 0, left: 0, background: '#FFF', zIndex: 98, marginBottom: '4%', borderRadius: '56px' }}>
                    <MainFilter
                        updateModal={updateModal}
                        update={updateFilters}
                        state={filter}
                        search={search}
                        resetFilters={resetFilters}
                    />
                </Box>

                {/* <Typography variant="h2" sx={{ display: { md: 'none', xs: 'block' }, gridRowStart: 2, gridRowEnd: 3, width: '90%', marginX: 'auto', fontSize: '40px', color: '#FFF', fontWeight: 800, textAlign: 'center' }}>
                    SOPHISTICATION<br />OUTSIDE<br />SERENITY<br />WITHIN
                </Typography> */}

                <Box
                    sx={{ display: { md: 'none', xs: 'flex' }, gridRowStart: 3, gridRowEnd: 4, width: '100%', justifyContent: 'center', alignItems: 'center', gridColumnEnd: 4, gridColumnStart: 1, zIndex: 3 }}>
                    <Button variant='contained' sx={{ color: '#53626F', borderRadius: 0, backgroundColor: '#FFF', padding: '10px 20px', border: 'solid 1px #707070' }} onClick={updateModal}>
                        {lng[language]['checkResidence']}
                    </Button>
                </Box>
            </Box>

            <Box sx={{ color: '#002856', width: { md: '1024px', sm: '80%', xs: '90%' }, marginX: 'auto', overflow: 'hidden' }}>

                <Box sx={{ defaultSpacing, marginTop: '50px' }}>
                    {/* <TextComponent compStyle={{ marginBottom: '20px' }} titleStyle={{ fontSize: '30px' }} buttonStyle={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '13px' }} title={lng[language]['startingFrom']} button={{ url: '/residencies', title: lng[language]['startingFromHomePage'] }} endIcon={<ArrowForwardIosIcon />} /> */}

                    <TextComponent titleStyle={{ fontSize: '30px' }} buttonStyle={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '13px' }} title={lng[language]['financeHomePage']} button={{ url: '/properties', title: lng[language]['checkPlansHomePage'] }} endIcon={<ArrowForwardIosIcon />} />


                </Box>
            </Box>

            <Box sx={{ color: '#002856', width: { md: '1472px', sm: '80%', xs: '100%' }, marginLeft: 'auto', marginRight: { md: 'unset', xs: 'auto' }, overflow: 'hidden' }}>
                <Box sx={[{ color: '#002856', display: 'grid', gridTemplateColumns: { md: '0.6fr 1fr', xs: 'unset' }, gridTemplateRows: { md: 'unset', xs: '1fr' }, rowGap: { md: 'unset', xs: '30px' }, position: 'relative', columnGap: '20px' }, defaultSpacing]}>
                    <Box sx={{ width: '90%', marginLeft: 'auto', marginX: { md: 'unset', xs: 'auto' } }}>
                        <Typography variant="h3" sx={{ fontSize: { md: '3rem', xs: '40px' }, fontWeight: 500 }}>
                            {lng[language]['homePageTxt1']}
                        </Typography>
                        <Typography variant="body1" sx={{ whiteSpace: 'preserve-breaks', marginTop: '30px' }}>
                            {lng[language]['homePageDescTxt1']}
                        </Typography>
                    </Box>

                    <Box sx={{ position: 'relative', height: '400px', width: '100%', gridRowStart: { md: 'unset', xs: '1' } }}>
                        <Box component={'img'} src='https://fundway.com/images/2024/Derë e hapur për një jetë luksoze.webp' sx={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </Box>
                </Box>
            </Box>

            {/* APARTEMTNS PART */}
            <Box sx={{ color: '#002856', width: { md: '1024px', sm: '80%', xs: '90%' }, marginX: 'auto' }}>
                <Box sx={[defaultSpacing, { marginTop: '100px' }]}>
                    <Typography variant="h4" textAlign={'center'}>{lng[language]['vertTitle1']}</Typography>
                    <Box sx={{ rowGap: '20px', display: 'grid', columnGap: '20px', marginTop: '50px', gridTemplateColumns: { sm: '1fr 1fr 1fr', xs: '1fr 1fr' } }}>
                        {
                            loading === false ?
                                <>
                                    {
                                        freeAps.map((data, index) => (<ResizencyCard key={index} image={`https://fundway.com/images/2024/${data.imageName}`} price={Math.ceil((parseFloat(data.priceTotal) + parseInt(data.priceParking)) / 100) * 100} beds={data.typology} link={data.apartmentId} status={data.status} orientation={data.orientationName} />))
                                    }
                                </>
                                :
                                SkeletonLoader?.map((data, index) => (<SkeletonComp key={index} variant={data.variant} style={data.style} height={data.height} width={data.width} />))
                        }
                    </Box>
                </Box>
            </Box>

            <Box sx={{ height: { md: '600px', sm: '500px', xs: '300px' }, width: '100%', marginTop: '100px', marginBottom: '100px', overflow: 'hidden' }} strength={500} component={Parallax}>
                <Background className="custom-bg">
                    <Box component={'img'} src='https://fundway.com/images/2024/Paralax_Nen residencat.webp' />
                </Background>
            </Box>

            <Box sx={{ color: '#002856', width: { md: '1024px', sm: '80%', xs: '90%' }, marginX: 'auto' }}>
                <Box sx={{ display: 'grid', gridTemplateColumns: { md: '1fr 1fr 1fr', xs: 'unset' }, gridGap: '50px', gridTemplateRows: { md: 'unset', xs: '1fr 1fr 1fr' } }}>{bigImages.map((data, index) => <VerticalCard key={index} imageUrl={data.imageUrl} title={data.title} desc={data.desc} buttonUrl={data.buttonUrl} />)}</Box>
            </Box>

            <Box sx={{ marginTop: { md: '100px', xs: '50px' } }}>
                <Box sx={{ color: '#002856', display: 'grid', gridTemplateColumns: { md: '2fr 1fr', xs: 'unset' }, gridTemplateRows: { md: 'unset', xs: 'auto auto' }, marginTop: { md: '100px', xs: '50px' }, columnGap: { md: '50px', xs: 'unset' }, width: {md: '80%', xs: 'unset'}, marginRight: {md: 'auto', xs: 'unset'} }}>
                    <Box sx={{ width: '100%', height: '500px' }}>
                        <Box component={'img'} src='https://fundway.com/images/2024/paragraph1.webp' alt='' sx={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: { md: 'unset', xs: '50px' }, width: { md: '100%', xs: '90%' }, marginX: { md: 'unset', xs: 'auto' } }}>
                        <Typography variant="body1" sx={{ whiteSpace: 'preserve-breaks' }}>{lng[language]['homePageFirstText']}</Typography>
                    </Box>
                </Box>
                
                <Box sx={{ color: '#002856', display: 'grid', gridTemplateColumns: { md: '1fr 1fr', xs: 'unset' }, gridTemplateRows: { md: 'unset', xs: 'auto auto' }, marginTop: { md: '100px', xs: '50px' }, columnGap: { md: '50px', xs: 'unset' }, marginLeft: 'auto', width: {md: '95%', xs: 'unset'} }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: { md: 'unset', xs: '50px' }, gridRowStart: { md: 'unset', xs: '2' }, gridRowEnd: { md: 'unset', xs: '3' }, flexDirection: 'column' }}>
                        <Typography variant="body1" sx={{ whiteSpace: 'preserve-breaks', width: { md: '100%', xs: '90%' }, marginX: { md: 'unset', xs: 'auto' } }}>{lng[language]['homePageSecondText']}</Typography>
                        <Box sx={{ display: 'flex', width: '90%', marginX: { md: 'unset', xs: 'auto' }, marginRight: { md: 'auto', xs: 'auto' }, justifyContent: { md: 'unset', xs: 'center' } }}>
                            <Button LinkComponent={Link} to="/properties" sx={{ marginTop: '40px', backgroundColor: '#002856', color: '#FFF', border: 'solid 1px #002856', borderRadius: 0, padding: '10px 20px', '&:hover': { color: '#002856', backgroundColor: '#FFF' }, width: { md: 'max-content', xs: 'max-content' } }}>
                                {lng[language]['seeApartments']}
                            </Button>
                        </Box>
                    </Box>
                    <Box sx={{ width: '100%', height: '100%', gridRowStart: { md: 'unset', xs: '1' }, gridRowEnd: { md: 'unset', xs: '2' } }}>
                        <Box component={'img'} src='https://fundway.com/images/2024/paragraph2.webp' alt='' sx={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </Box>
                </Box>

                {/* CARDS */}
                <Box sx={{ width: { md: '1024px', xs: '90%' }, marginX: 'auto', marginTop: { md: '100px', xs: '50px' } }}>
                    <Box sx={{ display: 'grid', gridTemplateColumns: { md: '1fr 1fr 1fr', xs: '1fr 1fr' }, columnGap: { md: '30px', xs: '20px' }, rowGap: '30px', marginTop: '50px', color: '#002856' }}>
                        <Box sx={{ display: 'flex' }}>
                            <Box>
                                <Box component={'img'} src='https://fundway.com/images/icons/newIcons/award.svg' sx={{ width: '30px', height: '30px' }} />
                            </Box>
                            <Typography sx={{ marginLeft: { md: '20px', xs: '10px' }, fontSize: { md: 'unset', xs: '13px' } }}>{lng[language]['highStandarts']}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Box>
                                <Box component={'img'} src='https://fundway.com/images/icons/newIcons/umbrella-beach.svg' sx={{ width: '30px', height: '30px' }} />
                            </Box>
                            <Typography sx={{ marginLeft: { md: '20px', xs: '10px' }, fontSize: { md: 'unset', xs: '13px' } }}>{lng[language]['beach']}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Box>
                                <Box component={'img'} src='https://fundway.com/images/icons/newIcons/travel.svg' sx={{ width: '35px', height: '35px' }} />
                            </Box>
                            <Typography sx={{ marginLeft: { md: '20px', xs: '10px' }, fontSize: { md: 'unset', xs: '13px' } }}>{lng[language]['viewIcons']}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Box>
                                <Box component={'img'} src='https://fundway.com/images/icons/newIcons/Path 2010.svg' sx={{ width: '35px', height: '35px' }} />
                            </Box>
                            <Typography sx={{ marginLeft: { md: '20px', xs: '10px' }, fontSize: { md: 'unset', xs: '13px' } }}>{lng[language]['game']}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Box>
                                <Box component={'img'} src='https://fundway.com/images/icons/newIcons/Trees.svg' sx={{ width: '30px', height: '30px' }} />
                            </Box>
                            <Typography sx={{ marginLeft: { md: '20px', xs: '10px' }, fontSize: { md: 'unset', xs: '13px' } }}>{lng[language]['parkIcon']}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Box>
                                <Box component={'img'} src='https://fundway.com/images/icons/newIcons/gym.svg' sx={{ width: '30px', height: '30px' }} />
                            </Box>
                            <Typography sx={{ marginLeft: { md: '20px', xs: '10px' }, fontSize: { md: 'unset', xs: '13px' } }}>{lng[language]['fitnessIcon']}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Box>
                                <Box component={'img'} src='https://fundway.com/images/icons/newIcons/parking-circle.svg' sx={{ width: '30px', height: '30px' }} />
                            </Box>
                            <Typography sx={{ marginLeft: { md: '20px', xs: '10px' }, fontSize: { md: 'unset', xs: '13px' } }}>{lng[language]['pargkingIcon']}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Box>
                                <Box component={'img'} src='https://fundway.com/images/icons/newIcons/shield.svg' sx={{ width: '30px', height: '30px' }} />
                            </Box>
                            <Typography sx={{ marginLeft: { md: '20px', xs: '10px' }, fontSize: { md: 'unset', xs: '13px' } }}>{lng[language]['safetyIcons']}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Box>
                                <Box component={'img'} src='https://fundway.com/images/icons/newIcons/road.svg' sx={{ width: '30px', height: '30px' }} />
                            </Box>
                            <Typography sx={{ marginLeft: { md: '20px', xs: '10px' }, fontSize: { md: 'unset', xs: '13px' } }}>{lng[language]['streetIcon']}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Box>
                                <Box component={'img'} src='https://fundway.com/images/icons/newIcons/helicopter-side.svg' sx={{ width: '30px', height: '30px' }} />
                            </Box>
                            <Typography sx={{ marginLeft: { md: '20px', xs: '10px' }, fontSize: { md: 'unset', xs: '13px' } }}>{lng[language]['heliIcon']}</Typography>
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ color: '#002856', display: 'grid', gridTemplateColumns: { md: '2fr 1fr', xs: 'unset' }, gridTemplateRows: { md: 'unset', xs: 'auto auto' }, marginTop: { md: '100px', xs: '50px' }, columnGap: { md: '50px', xs: 'unset' }, width: {md: '85%', xs: 'unset'}, marginRight: {md: 'auto', xs: 'unset'}  }}>
                    <Box sx={{ width: '100%', height: '500px' }}>
                        <Box component={'img'} src='https://fundway.com/images/2024/paragraph3.webp' alt='' sx={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: { md: 'unset', xs: '50px' }, width: { md: '100%', xs: '90%' }, marginX: { md: 'unset', xs: 'auto' } }}>
                        <Typography variant="body1" sx={{ whiteSpace: 'preserve-breaks' }}>{lng[language]['homePageThirdText']}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ width: { md: '1200px', sm: '80%', xs: '90%' }, marginX: 'auto', marginTop: { md: '100px', xs: '50px' } }}>
                <HomePageGridTemp />
            </Box>

            <Box sx={{ marginTop: { md: '100px', xs: '50px' } }}>
                <Typography variant="h6" fontSize={'29px'} color={'#002856'} marginBottom={'50px'} textAlign={'center'}>{lng[language]['descoverTxt']}</Typography>
                <Box sx={{ width: { md: '1024px', sm: '80%', xs: '100%' }, marginX: 'auto', height: { md: '576px', xs: '300px' }, position: 'relative' }}>

                    <ShowVideoModule />
                </Box>
            </Box>
        </Box>
    )
}