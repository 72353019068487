import React from 'react';
import Box from '@mui/material/Box';
// import CloseIcon from '@mui/icons-material/Close';

export default function ModalComp({children, setModal, modal}) {
    return (
        <Box sx={{position: 'fixed', top: 0, left: 0, background: 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) )', width: '100%', height: '100%', zIndex: 9999, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {children}
        </Box>
    )
}