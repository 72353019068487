import Box from '@mui/material/Box';
import AllRoutes from './routes/index';
import Header from './layout/header/Header';
import Footer from './layout/footer/Footer';
import { useLocation } from 'react-router-dom';
import env from "react-dotenv";
import ReactPixel from './utils/reactPixel';
import { useEffect, useState } from 'react';
import ConsentScreen from './components/consent/ConsentScreen';
import { areCookiesEnabled, checkCookies, cookiePaths } from './utils/cookies';
import { useDispatch, useSelector } from 'react-redux';
import { addPath, changeConsent, checkSession } from './app/consent/ConsentRedux';
import Loader from './components/loader/Loader';
import Cookies from 'js-cookie';
import { isLocalStorageAccessible } from './utils/utils';
import Typography from '@mui/material/Typography'
import ReactGA from "react-ga4";
function App() {
    const location = useLocation();
    const conesntGiven = useSelector((state) => state.consent.isConsentGiven);
    const pathTracking = useSelector((state) => state.consent.pathTracking);
    const loading = useSelector((state) => state.consent.loading);
    const disatch = useDispatch();
    const [consent, setConsent] = useState(null);
    let meta = Cookies.get('meta_pixel');
    useEffect(() => {
        if (Cookies.get('meta_pixel') === "true") {
            ReactPixel.init(env.REACT_APP_PIXEL_KEY);
        }
        
    }, [meta]);

    useEffect(() => {
        ReactGA.initialize('G-KNX7RW931Q');
    }, []);

    useEffect(() => {
        
        if (areCookiesEnabled()) {
            if (pathTracking === "true") {
                cookiePaths(location.pathname)
            }
            if (Cookies.get('meta_pixel') === "true") {
                ReactPixel.pageView();
            }
            if (Cookies.get('csrf_token')) {
                disatch(
                    addPath({
                        token: Cookies.get('csrf_token'),
                        user: '',
                        exp: Cookies.get('csrf_expire'),
                        path: location.pathname
                    })
                    
                );
                disatch(checkSession({
                    token: Cookies.get('csrf_token')
                }));
            
            }
        }
        window.scrollTo(0, 0);
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        //eslint-disable-next-line
    }, [location]);

    useEffect(() => {
        if (areCookiesEnabled()) {
            if (!checkCookies().valid && !conesntGiven) {
                disatch(changeConsent({conesntGiven: false, loading: false, pathTracking: false}));
                setConsent(<ConsentScreen />)
            }else{
                disatch(changeConsent({conesntGiven: true,loading: false,pathTracking: checkCookies().pathTracking, token: checkCookies().userId}))
                setConsent(null);
            }    
        }
        //eslint-disable-next-line
    }, [conesntGiven]);

    return (
        <Box sx={{backgroundColor:'#FFF'}}>
            {
                (areCookiesEnabled() && isLocalStorageAccessible()) ?
                <>
                    {consent}
                    <Header />
                        <AllRoutes />
                    <Footer />
                    {
                        loading &&
                        <Loader state={loading} />
                    }
                </>
                :
                <Box sx={{height: '100vh', width: '100%', color: '#002856', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Typography variant="h3">
                        To access the page you need to allow cookies
                    </Typography>
                </Box>
            }
           
        </Box>
    );
}

export default App;