import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import { Button } from '@mui/material';
import { lng } from '../../utils/language';
import { useSelector } from 'react-redux';
// import { insertDownPayment } from '../../utils/utils';

const inputStyle = {
    fieldset: { borderRadius: '0', borderWidth: '2px', },
    input: { textAlign: 'center' }
}

const inputContained = {
    borderRadius: 0,
    '&.MuiButton-contained': {
        backgroundColor: '#002856',
    },
    '&.MuiButton-outlined': {
        color: '#002856 !important',
        border: 'solid 1px #002856 !important'
    }
}

export default function SimpleMortgageCalculator({ price }) {
    const language = useSelector((state) => state.language.language);
    // const
    const [mortgage, setMortgage] = useState({
        amount: price,
        downPayment: '',
        interestRate: 3.7,
        loanTerm: 10,
        totalInteres: ''
    })

    const [calcData, setCalcData] = useState({
        downPayment: 20,
        monthlyPayment: '',
        totalPayment: '',
        totalInteres: ''
    })

    const inputs = {
        mortgatePayment: (e) => {
            const value = e.target.value;
            setMortgage({ ...mortgage, amount: value }); calculator(null, mortgage.loanTerm, (calcData.downPayment / 100) * value , value);
        },
        loanPayment: (e) => {
            const value = e.target.value; setMortgage({ ...mortgage, downPayment: value }); calculator();
        },
        loanTerm: (value) => {
            setMortgage({ ...mortgage, loanTerm: value }); calculator(null, value, calcData.downPayment, mortgage.amount);
        },
        downPaymentInput: (e) => {
            const value = e.target.value;
            // insertDownPayment(value);
            calculator(value, null, (value / 100) * price);
            setMortgage({ ...mortgage, downPayment: (value / 100) * price});
        }
    }

    console.log(mortgage.amount);

    const calculator = (innerValue, loanTearm, downPayment, price2) => {
        let loanAmount = null;
        let price = null;

        if (price) {price = price2;}else{
            price = mortgage.amount
        }

        if (downPayment) {
            loanAmount = (price - downPayment);
        }else{
            loanAmount = (price - mortgage.downPayment);
        }
        let r = mortgage.interestRate / 100 / 12;
        let n = null;
        if (loanTearm) {
            n = parseFloat(loanTearm) * 12;
        }else{
            n = parseFloat(mortgage.loanTerm) * 12;
        }
        let numerator = loanAmount * r * Math.pow(1 + r, n);
        let denumerator = Math.pow(1 + r, n) - 1;
        let monthlyPaymentData = (numerator / denumerator).toFixed(2);
        let totalPayableData = (monthlyPaymentData * n);
        let totalInterests = (totalPayableData - loanAmount).toFixed(2);
        if (innerValue) {
            return setCalcData({
                ...calcData,
                downPayment: innerValue,
                monthlyPayment: monthlyPaymentData,
                totalPayment: totalPayableData,
                totalInteres: parseInt(mortgage.amount) + (totalInterests)
            });
        } else {
            return setCalcData({
                ...calcData,
                monthlyPayment: monthlyPaymentData,
                totalPayment: totalPayableData,
                totalInteres: parseInt(mortgage.amount) + parseInt(totalInterests)
            });
        }
    }

    useEffect(() => {
            setMortgage({
                ...mortgage,
                downPayment: (calcData.downPayment / 100) * price
            });
        calculator(null, mortgage.loanTerm, (calcData.downPayment / 100) * price, );
        // eslint-disable-next-line
    }, [])

    return (
        <Box sx={{ color: '#11294D' }}>
            <Box sx={{ width: '100%', marginX: 'auto', display: 'grid', gridGap: '20px', gridTemplateColumns: '1fr' }}>
                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', gridGap: '20px' }}>
                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '20px', alignContent: 'center' }}>
                        <Box>
                            <InputLabel sx={{ marginBottom: '5px', fontWeight: 500, fontSize: '15px' }}>
                                {lng[language]['priceTxt']}
                            </InputLabel>
                            <TextField fullWidth sx={inputStyle} value={mortgage.amount} onChange={(e) => {inputs.mortgatePayment(e)}} />
                        </Box>
                        <Box>
                            <InputLabel sx={{ marginBottom: '5px', fontWeight: 500, fontSize: '15px' }}>
                                {lng[language]['interest']}
                            </InputLabel>
                            <Box sx={{border: 'solid 2px #c4c4c4', padding: '16.5px', textAlign: 'center', height: '56px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Typography variant="subtitle1">{`${mortgage.interestRate}%`}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        {/* <InputLabel sx={{ marginBottom: '5px', fontWeight: 500, fontSize: '15px' }}>
                            {lng[language]['priPayment']}
                        </InputLabel> */}
                        <Box>
                            <Typography sx={{ fontSize: '14px' }}>{lng[language]['prepaymentValue']}: {`${calcData.downPayment}%`} / {`${new Intl.NumberFormat('us-US', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format(mortgage.downPayment)}`} </Typography>
                            <Slider value={calcData.downPayment} sx={{'.MuiSlider-track': {color: '#11294D'}, '.MuiSlider-thumb': {color: '#11294D'}}} valueLabelDisplay="auto" size='small' shiftStep={20} step={10} marks min={20} max={80} onChange={(e) => inputs.downPaymentInput(e)} />
                        </Box>
                    </Box>
                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <InputLabel sx={{ marginBottom: '5px', fontWeight: 500, fontSize: '15px' }}>
                                {lng[language]['period']}: {`${mortgage.loanTerm}`} 
                            </InputLabel>
                            {/* <InputLabel sx={{ marginBottom: '5px', fontWeight: 500, fontSize: '15px' }}>
                                {lng[language]['inYears']}
                            </InputLabel> */}
                        </Box>
                        <Box>
                            <Box
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: {md: 'auto auto auto auto auto', xs: 'auto auto auto'},
                                    columnGap: {md: '20px', xs: '10px'},
                                    marginTop: '10px',
                                    rowGap: {md: 'none', xs: '10px'}
                                }}
                            >
                                <Button variant={
                                    // eslint-disable-next-line
                                    mortgage.loanTerm == '10' ? 'contained' : 'outlined'
                                } sx={inputContained} onClick={() => inputs.loanTerm(10)}>
                                    10
                                </Button>
                                <Button variant={
                                    // eslint-disable-next-line
                                    mortgage.loanTerm == '15' ? 'contained' : 'outlined'
                                } sx={inputContained} onClick={() => inputs.loanTerm(15)}>
                                    15
                                </Button>
                                <Button variant={
                                    // eslint-disable-next-line
                                    mortgage.loanTerm == '20' ? 'contained' : 'outlined'
                                } sx={inputContained} onClick={() => inputs.loanTerm(20)}>
                                    20
                                </Button>
                                <Button  variant={
                                    // eslint-disable-next-line
                                    mortgage.loanTerm == '25' ? 'contained' : 'outlined'
                                } sx={inputContained} onClick={() => inputs.loanTerm(25)}>
                                    25
                                </Button>
                                <Button variant={
                                    // eslint-disable-next-line
                                    mortgage.loanTerm == '30' ? 'contained' : 'outlined'
                                    } sx={inputContained} onClick={() => inputs.loanTerm(30)}>
                                    30
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    gridGap: '20px'
                }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: 'solid 1px #c4c4c4', paddingBottom: 1 }}>
                        <Typography variant="body1">{lng[language]['monthlyInters']}</Typography>
                        <Typography variant="body1">{calcData.monthlyPayment ? `${new Intl.NumberFormat('us-US', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format(calcData.monthlyPayment)}` : 'NaN'}</Typography>
                    </Box>
                    {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: 'solid 1px #c4c4c4', paddingBottom: 1 }}>
                        <Typography variant="body1">{lng[language]['totalPayment']}</Typography>
                        <Typography variant="body1">{calcData.totalInteres ? `${new Intl.NumberFormat('us-US', { style: 'currency', currency: 'EUR' }).format(calcData.totalInteres)}` : 'NaN'}</Typography>
                    </Box> */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="body1">{lng[language]['totalInteres']}</Typography>
                        <Typography variant="body1">{new Intl.NumberFormat('us-US', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format((mortgage.amount - mortgage.downPayment))}</Typography>
                    </Box>
                </Box>
                <Box>
                    <Typography variant="caption" sx={{fontSize: '10px'}}>{lng[language]['liability']}</Typography>
                </Box>
            </Box>

        </Box>
    )
}