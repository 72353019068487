import React from 'react';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { checkLanguage } from "./app/language/Language";
store.dispatch(checkLanguage());

if (process.env.NODE_ENV !== "development")
    console.log = () => {};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <BrowserRouter>
            {/* <React.StrictMode> */}
                <App />
            {/* </React.StrictMode> */}
        </BrowserRouter>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();