import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
// import FetchData from '../../api/FetchData';
// import InputLabel from '@mui/material/InputLabel';
// import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography'
import { motion } from "framer-motion";
import Checkbox from '@mui/material/Checkbox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import style from './Filter.module.css';
// import { useSearchParams } from 'react-router-dom';
import ClickAwayListener from 'react-click-away-listener';
// import NativeSelect from '@mui/material/NativeSelect';
import AddIcon from '@mui/icons-material/Add';
// import CloseIcon from '@mui/icons-material/Close';
// import Button from '@mui/material/Button';
import { lng } from '../../utils/language';
import { useSelector } from 'react-redux';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const itemVariants = {
    open: {
        opacity: 1,
        scale: 1,
        filter: "blur(0px)",
        transition: { type: "spring", stiffness: 300, damping: 24 }
    },
    closed: {
        opacity: 0,
        scale: 0.3,
        filter: "blur(20px)",
        transition: { duration: 0.2 }
    }
};

const checkBox = {
    width: { md: 'max-content', xs: 'max-content' },
    '.MuiCheckbox-root': {
        padding: { md: '2px', xs: '5px' },
        border: 'solid',
        borderRadius: 0,
        borderWidth: '1px',
    },
    '.MuiFormControlLabel-label': {
        marginLeft: '5px'
    },
    '.MuiSvgIcon-root': {
        path: {
            fill: 'transparent'
        }
    },
    '.Mui-checked': {
        fill: '#002856 !important',
        color: '#002856 !important',
        path: {
            fill: 'currentColor'
        }
    },
}

export default function AllFilter({ state, updateState, stateTypology, stateOrientation }) {
    const language = useSelector((state) => state.language.language);
    // DEFAULT DATA
    // const maxFloors = Array.from({ length: 14 }, (_, index) => index + 1);
    // const price = Array.from({ length: 25 }, (_, index) => (index + 1) * 100000);
    // const [surface, setSurface] = useState([])

    const [typology, setTypology] = useState([
        {
            label: lng[language]['bedRoom1'],
            value: '1+1',
            checked: false
        },
        {
            label: lng[language]['bedRoom2'],
            value: '2+1',
            checked: false
        },
        {
            label: lng[language]['bedRoom3'],
            value: '3+1',
            checked: false
        },
        // {
        //     label: lng[language]['bedRoom4'],
        //     value: '4+1',
        //     checked: false
        // },
        {
            label: 'DUPLEX',
            value: 'duplex',
            checked: false
        },
    ])

   

    const [orientation, setOrientation] = useState([
        {
            label: 'Det',
            value: 'Det',
            checked: false
        },
        {
            label: 'Promenade',
            value: 'Promenade',
            checked: false
        },
        {
            label: 'Marine',
            value: 'Marine',
            checked: false
        },
    ])

    const handleOrientationUpdate = (values, otherVal) => {
        if (values) {
            setTypology(prevTypology =>
                prevTypology.map(option => ({
                    ...option,
                    checked: option.value === values
                }))
            );
        }
        if (otherVal) {
            setOrientation(prevTypology =>
                prevTypology.map(option => ({
                    ...option,
                    checked: option.value === otherVal
                }))
            );
        }
    }

    useEffect(() => {
        handleOrientationUpdate(stateTypology, stateOrientation)
    }, [stateTypology, stateOrientation])

    // const handleUpdateFilters = (e) => {
    //     let name = e.target.name;
    //     let value = e.target.value;
    //     updateState({
    //         ...state,
    //         [name]: value
    //     })
    // }

    const handleSelect = (e, typologyIndex) => {
        var value = e.target.value;
        var checked = e.target.checked;

        if (typology[typologyIndex].value === value && checked === true) {
            setTypology(prevTypology =>
                prevTypology.map(option => ({
                    ...option,
                    checked: option.value === value
                }))
            );
            var newItem = {
                ...state,
                typology: value
            };
            updateState(newItem);
        } else {
            setTypology(prevTypology =>
                prevTypology.map(option => ({
                    ...option,
                    checked: false
                }))
            );
            updateState({
                ...state,
                typology: ''
            });
        }
    }

    const handleSelectOrientation = (e, orientationIndex) => {
        var value = e.target.value;
        var checked = e.target.checked;
        if (orientation[orientationIndex].value === value && checked === true) {
            setOrientation(prevTypology =>
                prevTypology.map(option => ({
                    ...option,
                    checked: option.value === value
                }))
            );
            var newItem = {
                ...state,
                orientation: value
            };
            updateState(newItem);
        } else {
            setOrientation(prevTypology =>
                prevTypology.map(option => ({
                    ...option,
                    checked: false
                }))
            );
            updateState({
                ...state,
                orientation: ''
            });
        }
    }

    // const [openSurface, setOpenSurface] = useState(false);
    // const handleSurfaceClickAway = (ignore) => {
    //     if (openSurface === true && ignore === true) { setOpenSurface(false); }
    //     else return
    // };
    // const [openPrice, setOpenPrice] = useState(false);
    // const handlePriceClickAway = () => {
    //     if (openPrice) setOpenPrice(false)
    //     else return
    // };
    // const [openFloor, setOpenFloor] = useState(false);
    // const handleFloorClickAway = () => {
    //     if (openFloor) setOpenFloor(false)
    //     else return
    // };
    const [openTypology, setOpenTypology] = useState(false);
    const handleTypologyClickAway = () => {
        if (openTypology) setOpenTypology(false)
        else return
    };

    const [openOrientation, setOpenOrientation] = useState(false);
    const handleOrientationClickAway = () => {
        if (openOrientation) setOpenOrientation(false)
        else return
    };

    const [openMobile, setOpenMobile] = useState(false);
    const [openMobileTypology, setOpenMobileTypology] = useState(false);
    const [openMobileOrientation, setOpenMobileOrientation] = useState(false);
    // const [openMobilePrice, setOpenMobilePrice] = useState(false);
    // const [openMobileFloor, setOpenMobileFloor] = useState(false);
    // const [openMobileSurface, setOpenMobileSurface] = useState(false);
    const desktopFilters = (
        <Box sx={{
            display: { md: 'grid', xs: 'none' },
            gridTemplateColumns: 'auto auto',
            columnGap: '20px',
            width: '100%',
            marginX: 'auto',
            alignItems: 'center',
            justifyItems: 'center',
        }}>


            {/* PRICE */}
            {/* <ClickAwayListener onClickAway={handlePriceClickAway}>
                <Box component={motion.nav} sx={{ position: 'relative' }} initial={false} animate={openPrice ? "open" : "closed"} className={["menu", style.filterContainer]}>
                    <Box component={motion.button} whileTap={{ scale: 0.97 }} className={style.buttonContainer} onClick={() => setOpenPrice(!openPrice)}>
                        <Typography variant="h6" sx={{ fontSize: '14px' }}>{lng[language]['price']}</Typography>
                        <Box component={motion.div} variants={{ open: { rotate: 180 }, closed: { rotate: 0 } }} transition={{ duration: 0.2 }} className={style.iconContainer}>
                            <KeyboardArrowDownIcon />
                        </Box>
                    </Box>
                    <Box component={motion.ul}
                        sx={{ pointerEvents: openPrice ? "auto" : "none", display: 'grid', gridTemplateColumns: '150px 50px 150px', position: 'absolute', backgroundColor: '#FFF', zIndex: 2, padding: '20px 20px 20px 20px', marginTop: 0, top: '100%' }}
                        variants={{
                            open: { clipPath: "inset(0% 0% 0% 0% round 10px)", transition: { type: "spring", bounce: 0, duration: 0.5, delayChildren: 0.3, staggerChildren: 0.1 } },
                            closed: { clipPath: "inset(10% 50% 90% 50% round 10px)", transition: { type: "spring", bounce: 0, duration: 0.3, when: "afterChildren", staggerDirection: -1, staggerChildren: 0.06 } }
                        }}
                    >
                        <Box component={motion.li} variants={itemVariants}>
                            <InputLabel>MIN</InputLabel>
                            <FormControl fullWidth>
                                <NativeSelect value={state.priceFrom} name="priceFrom" onChange={(e) => handleUpdateFilters(e)}>
                                    <Box component={'option'} value={''}>{lng[language]['selectPrice']}</Box>
                                    {price.map((data) => (<Box component={'option'} key={data} value={data}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data)}</Box>))}
                                </NativeSelect>
                            </FormControl>
                        </Box>
                        <Box component={motion.li} variants={itemVariants}>
                            <Typography variant="subtitle1" textAlign={'center'} sx={{ marginTop: '20px' }}>{lng[language]['upTo']}</Typography>
                        </Box>
                        <Box component={motion.li} variants={itemVariants}>
                            <Box>
                                <InputLabel>MAX</InputLabel>
                                <FormControl fullWidth>
                                    <NativeSelect defaultValue={''} value={state.priceTo} name="priceTo" onChange={(e) => handleUpdateFilters(e)}>
                                        <Box component={'option'} value={''}>{lng[language]['selectPrice']}</Box>
                                        {price.map((data) => (<Box component={'option'} key={data} value={data}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data)}</Box>))}
                                    </NativeSelect>
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </ClickAwayListener> */}

            {/* FLOOR */}
            {/* <ClickAwayListener onClickAway={handleFloorClickAway}>
                <Box
                    component={motion.nav}
                    sx={{ position: 'relative' }}
                    initial={false}
                    animate={openFloor ? "open" : "closed"}
                    className={["menu", style.filterContainer]}
                >
                    <Box component={motion.button} whileTap={{ scale: 0.97 }} className={style.buttonContainer} onClick={() => setOpenFloor(!openFloor)}>
                        <Typography variant="h6" sx={{ fontSize: '14px' }}>{lng[language]['floor']}</Typography>
                        <Box component={motion.div} variants={{ open: { rotate: 180 }, closed: { rotate: 0 } }} transition={{ duration: 0.2 }} className={style.iconContainer}>
                            <KeyboardArrowDownIcon />
                        </Box>
                    </Box>
                    <Box
                        component={motion.ul}
                        variants={{
                            open: { clipPath: "inset(0% 0% 0% 0% round 10px)", transition: { type: "spring", bounce: 0, duration: 0.5, delayChildren: 0.3, staggerChildren: 0.1 } },
                            closed: { clipPath: "inset(10% 50% 90% 50% round 10px)", transition: { type: "spring", bounce: 0, duration: 0.3, when: "afterChildren", staggerDirection: -1, staggerChildren: 0.06 } }
                        }}
                        sx={{ pointerEvents: openFloor ? "auto" : "none", display: 'grid', gridTemplateColumns: '140px', position: 'absolute', backgroundColor: '#FFF', zIndex: 2, padding: '20px 20px 20px 20px', marginTop: 0, top: '100%' }}
                    >
                        <Box component={motion.li} variants={itemVariants}>
                            <FormControl fullWidth>
                                <NativeSelect defaultValue={''} value={state.floorNo} name="floorNo" onChange={(e) => handleUpdateFilters(e)}>
                                    <Box component={'option'} value={''}>{lng[language]['selectFloor']}</Box>
                                    {maxFloors.map((data) => (<Box component={'option'} key={data} value={data}>{data}</Box>))}
                                </NativeSelect>
                            </FormControl>
                        </Box>
                    </Box>
                </Box>
            </ClickAwayListener> */}

            {/* TYPOLOGY */}
            <ClickAwayListener onClickAway={handleTypologyClickAway}>
                <Box component={motion.nav} initial={false} animate={openTypology ? "open" : "closed"} className={["menu", style.filterContainer]}>
                    <Box component={motion.button} whileTap={{ scale: 0.97 }} className={style.buttonContainer} onClick={() => setOpenTypology(!openTypology)} sx={openTypology ? { border: 'solid 1px #002856', borderRadius: '0', backgroundColor: '#002856', color: '#FFF' } : { border: 'solid 1px #000', borderRadius: '0' }}>
                        <Typography variant="h6" sx={{ fontSize: '14px' }}>{lng[language]['bedsFilters']}</Typography>
                    </Box>
                    <Box component={motion.ul} variants={{open: { clipPath: "inset(0% 0% 0% 0% round 0)", transition: { type: "spring", bounce: 0, duration: 0.3, delayChildren: 0.3, staggerChildren: 0.1 } }, closed: { clipPath: "inset(10% 50% 90% 50% round 10px)", transition: { type: "spring", bounce: 0, duration: 0.3, when: "afterChildren", staggerDirection: -1, staggerChildren: 0.06 } }}} sx={{ pointerEvents: openTypology ? "auto" : "none", width: '160px', marginTop: '20px', border: 'solid 1px #cdcdcd', background: '#fcfcfc' }} className={style.typologyListContainer}>
                        <FormGroup sx={{display: 'grid',gridTemplateRows: '1fr',rowGap: '10px',justifyItems: 'start',alignItems: 'center',width: '70%',marginX: 'auto',paddingY: '20px',}}>
                            {
                                language &&
                                typology?.map((data, index) => (
                                    <Box component={motion.li} variants={itemVariants} key={index} >
                                        <FormControlLabel sx={checkBox} checked={data.checked} control={<Checkbox />} label={data?.value.toUpperCase()} value={data?.value} onChange={(e) => handleSelect(e, index)} />
                                    </Box>
                                ))
                            }
                        </FormGroup>
                    </Box>
                </Box>
            </ClickAwayListener>
            {/* ORIENTATION */}
            <ClickAwayListener onClickAway={handleOrientationClickAway}>
                <Box component={motion.nav} initial={false} animate={openOrientation ? "open" : "closed"} className={["menu", style.filterContainer]}>
                    <Box component={motion.button} whileTap={{ scale: 0.97 }} className={style.buttonContainer} onClick={() => setOpenOrientation(!openOrientation)} sx={openOrientation ? { border: 'solid 1px #002856', borderRadius: '0', backgroundColor: '#002856', color: '#FFF' } : { border: 'solid 1px #000', borderRadius: '0' }}>
                        <Typography variant="h6" sx={{ fontSize: '14px' }}>{lng[language]['orientation']}</Typography>
                    </Box>
                    <Box component={motion.ul} variants={{open: { clipPath: "inset(0% 0% 0% 0% round 0)", transition: { type: "spring", bounce: 0, duration: 0.3, delayChildren: 0.3, staggerChildren: 0.1 } }, closed: { clipPath: "inset(10% 50% 90% 50% round 10px)", transition: { type: "spring", bounce: 0, duration: 0.3, when: "afterChildren", staggerDirection: -1, staggerChildren: 0.06 } } }} sx={{ pointerEvents: openOrientation ? "auto" : "none", width: '160px', marginTop: '20px', border: 'solid 1px #cdcdcd', background: '#fcfcfc' }} className={style.typologyListContainer}>
                        <FormGroup sx={{display: 'grid',gridTemplateRows: '1fr',rowGap: '10px',justifyItems: 'start',alignItems: 'center',width: '70%',marginX: 'auto',paddingY: '20px',}}>
                            {
                                language &&
                                orientation?.map((data, index) => (
                                    <Box component={motion.li} variants={itemVariants} key={index}>
                                        <FormControlLabel sx={checkBox} checked={data.checked} control={<Checkbox />} label={lng[language][data?.value]} value={data?.value} onChange={(e) => handleSelectOrientation(e, index)} />
                                    </Box>
                                ))
                            }
                        </FormGroup>
                    </Box>
                </Box>
            </ClickAwayListener>
            {/* SURFACE */}
            {/* <Box>
                <FormControl fullWidth>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Age
                    </InputLabel>
                    <NativeSelect
                        defaultValue={30}
                        inputProps={{
                            name: 'age',
                            id: 'uncontrolled-native',
                        }}
                    >
                        <Box component={'option'} value={10}>Ten</Box>
                        <Box component={'option'} value={20}>Twenty</Box>
                        <Box component={'option'} value={30}>Thirty</Box>
                    </NativeSelect>
                </FormControl>
            </Box> */}
            {/* ORIENTATION */}
            {/* <Box>
                <FormControl fullWidth>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Age
                    </InputLabel>
                    <NativeSelect
                        defaultValue={30}
                        inputProps={{
                            name: 'age',
                            id: 'uncontrolled-native',
                        }}
                    >
                        <Box component={'option'} value={10}>Ten</Box>
                        <Box component={'option'} value={20}>Twenty</Box>
                        <Box component={'option'} value={30}>Thirty</Box>
                    </NativeSelect>
                </FormControl>
            </Box> */}
            {/* FLOOR */}
            {/* <Box>
                <FormControl fullWidth>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Age
                    </InputLabel>
                    <NativeSelect
                        defaultValue={30}
                        inputProps={{
                            name: 'age',
                            id: 'uncontrolled-native',
                        }}
                    >
                        <Box component={'option'} value={10}>Ten</Box>
                        <Box component={'option'} value={20}>Twenty</Box>
                        <Box component={'option'} value={30}>Thirty</Box>
                    </NativeSelect>
                </FormControl>
            </Box> */}
            {/* TYPOLOGY */}
            {/* <Box>
                <FormControl fullWidth>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Age
                    </InputLabel>
                    <NativeSelect
                        defaultValue={30}
                        inputProps={{
                            name: 'age',
                            id: 'uncontrolled-native',
                        }}
                    >
                        <Box component={'option'} value={10}>Ten</Box>
                        <Box component={'option'} value={20}>Twenty</Box>
                        <Box component={'option'} value={30}>Thirty</Box>
                    </NativeSelect>
                </FormControl>
            </Box> */}
            {/* PRICE */}
            {/* <Box>
                <FormControl fullWidth>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Age
                    </InputLabel>
                    <NativeSelect
                        defaultValue={30}
                        inputProps={{
                            name: 'age',
                            id: 'uncontrolled-native',
                        }}
                    >
                        <Box component={'option'} value={10}>Ten</Box>
                        <Box component={'option'} value={20}>Twenty</Box>
                        <Box component={'option'} value={30}>Thirty</Box>
                    </NativeSelect>
                </FormControl>
            </Box> */}

        </Box>
    );

    const mobileFilters = (
        <Box sx={{display: { md: 'none', xs: 'grid' },width: '100%', marginX: 'auto', color: '#53626F'}}>
            {/* TYPOLOGY */}
            <Box>
                <Box>
                    <Typography variant="h6" sx={{ fontSize: '14px', fontWeight: 800 }} gutterBottom>{lng[language]['bedsFilters']}</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }} onClick={() => setOpenMobileTypology(!openMobileTypology)}>
                        {
                            state.typology.length > 0
                                ?
                                <Typography color={'#53626F'}>
                                    {
                                        state.typology.toString()
                                    }
                                </Typography>
                                :
                                <Typography color={'#53626F'}>{lng[language]['any']}</Typography>
                        }
                        <KeyboardArrowDownIcon />
                    </Box>
                    {
                        openMobileTypology &&
                        <Box sx={{ display: 'grid', width: '100%', gridTemplateRows: '50px', paddingTop: '20px', height: '175px', backgroundColor: '#fff' }}>
                            <FormGroup
                                sx={{
                                    display: 'grid',
                                    gridTemplateRows: '1fr',
                                    rowGap: '10px',
                                    justifyItems: 'start',
                                    alignItems: 'center',
                                    width: '95%',
                                    marginX: 'auto',
                                }}
                            >
                                {
                                    language &&
                                    typology?.map((data, index) => (
                                        <FormControlLabel key={index} sx={checkBox} checked={data.checked} control={<Checkbox />} label={data?.value} value={data?.value} onChange={(e) => handleSelect(e, index)} />
                                    ))
                                }
                            </FormGroup>
                        </Box>
                    }
                    <Box component={'hr'} sx={{ marginTop: '30px', borderWidth: '1px' }} />
                </Box>

            </Box>

            {/* ORIENTATION */}
            <Box>
                <Box>
                    <Typography variant="h6" sx={{ fontSize: '14px', fontWeight: 800 }} gutterBottom>{lng[language]['orientation']}</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }} onClick={() => setOpenMobileOrientation(!openMobileOrientation)}>
                        {
                            state.orientation.length > 0
                                ?
                                <Typography color={'#53626F'}>
                                    {
                                        state.orientation.toString()
                                    }
                                </Typography>
                                :
                                <Typography color={'#53626F'}>{lng[language]['any']}</Typography>
                        }
                        <KeyboardArrowDownIcon />
                    </Box>
                    {
                        openMobileOrientation &&
                        <Box sx={{ display: 'grid', width: '100%', gridTemplateRows: '50px', paddingTop: '20px', height: '127px', backgroundColor: '#fff' }}>
                            <FormGroup
                                sx={{
                                    display: 'grid',
                                    gridTemplateRows: '1fr',
                                    rowGap: '10px',
                                    justifyItems: 'start',
                                    alignItems: 'center',
                                    width: '95%',
                                    marginX: 'auto',
                                }}
                            >
                                {
                                    language &&
                                    orientation?.map((data, index) => (
                                        <FormControlLabel key={index} sx={checkBox} checked={data.checked} control={<Checkbox />} label={lng[language][data?.value]} value={data?.value} onChange={(e) => handleSelectOrientation(e, index)} />
                                    ))
                                }
                            </FormGroup>
                        </Box>
                    }
                    <Box component={'hr'} sx={{ marginTop: '30px', borderWidth: '1px' }} />
                </Box>

            </Box>

            {/* PRICE */}
            {/* <Box>
                <Box>
                    <Typography variant="h6" sx={{ fontSize: '14px', fontWeight: 800 }} gutterBottom>ÇMIMI</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }} onClick={() => setOpenMobilePrice(!openMobilePrice)}>
                        {
                            state.priceTo || state.priceFrom
                                ?
                                <Typography color={'#53626F'}>{state.priceFrom}&euro; / {state.priceTo} &euro;</Typography>
                                :
                                <Typography color={'#53626F'}>{lng[language]['any']}</Typography>
                        }
                        <KeyboardArrowDownIcon />
                    </Box>

                    {
                        openMobilePrice &&
                        <Box sx={{ display: 'grid', width: '100%', gridTemplateRows: '50px', paddingTop: '20px', height: '100%', backgroundColor: '#FFF' }}>
                            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 50px 1fr', marginX: 'auto', width: '100%', gridGap: '20px' }}>
                                <Box>
                                    <InputLabel sx={{ fontSize: '14px', marginBottom: '10px', fontWeight: 'bold' }}>MIN</InputLabel>
                                    <FormControl
                                        fullWidth
                                        sx={{ '.MuiInputBase-root': { '&::before': { border: 'none' }, '&::after': { border: 'none' }, border: 'solid 1px #53626F', textAlign: 'center', paddingLeft: '5px', paddingY: '10px' } }}>
                                        <NativeSelect defaultValue={''} value={state.priceFrom} name="priceFrom" onChange={(e) => handleUpdateFilters(e)}>
                                            <Box component={'option'} value={''}>{lng[language]['selectPrice']}</Box>
                                            {price.map((data) => (<Box component={'option'} key={data} value={data}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data)}</Box>))}
                                        </NativeSelect>
                                    </FormControl>
                                </Box>
                                <Typography sx={{ marginTop: '40px', textAlign: 'center' }}>{lng[language]['upTo']}</Typography>
                                <Box>
                                    <InputLabel sx={{ fontSize: '14px', marginBottom: '10px', fontWeight: 'bold' }}>MAX</InputLabel>
                                    <FormControl
                                        fullWidth
                                        sx={{ '.MuiInputBase-root': { '&::before': { border: 'none' }, '&::after': { border: 'none' }, border: 'solid 1px #53626F', textAlign: 'center', paddingLeft: '5px', paddingY: '10px' } }}>
                                        <NativeSelect defaultValue={''} value={state.priceTo} name="priceTo" onChange={(e) => handleUpdateFilters(e)}>
                                            <Box component={'option'} value={''}>{lng[language]['selectPrice']}</Box>
                                            {price.map((data) => (<Box component={'option'} key={data} value={data}>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data)}</Box>))}
                                        </NativeSelect>
                                    </FormControl>
                                </Box>
                            </Box>
                        </Box>
                    }

                    <Box component={'hr'} sx={{ marginTop: openMobilePrice ? '70px' : '30px', borderWidth: '1px' }} />
                </Box>
            </Box> */}
            {/* FLOOR */}
            {/* <Box>
                <Box>
                    <Typography variant="h6" sx={{ fontSize: '14px', fontWeight: 800 }} gutterBottom>KATI</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }} onClick={() => setOpenMobileFloor(!openMobileFloor)}>
                        {
                            state.floorNo
                                ?
                                <Typography color={'#53626F'}>{state.floorNo}</Typography>
                                :
                                <Typography color={'#53626F'}>{lng[language]['any']}</Typography>
                        }
                        <KeyboardArrowDownIcon />
                    </Box>

                    {
                        openMobileFloor &&
                        <Box sx={{ display: 'grid', width: '100%', gridTemplateRows: '50px', paddingTop: '20px', height: '100%', backgroundColor: '#FFF' }}>
                            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', marginX: 'auto', width: '100%', gridGap: '20px' }}>
                                <Box>
                                    <FormControl fullWidth sx={{ '.MuiInputBase-root': { '&::before': { border: 'none' }, '&::after': { border: 'none' }, border: 'solid 1px #53626F', textAlign: 'center', paddingLeft: '5px', paddingY: '10px' }, color: '#53626f' }}>
                                        <NativeSelect defaultValue={''} value={state.floorNo} name="floorNo" onChange={(e) => handleUpdateFilters(e)} sx={{ color: '#53626f' }}>
                                            <Box component={'option'} value={''} sx={{ color: '#53626f' }}>{lng[language]['selectFloor']}</Box>
                                            {maxFloors.map((data) => (<Box component={'option'} key={data} value={data} sx={{ color: '#53626f' }}>{data}</Box>))}
                                        </NativeSelect>
                                    </FormControl>
                                </Box>
                            </Box>
                        </Box>
                    }

                    <Box component={'hr'} sx={{ marginTop: openMobileFloor ? '15px' : '30px', borderWidth: '1px' }} />
                </Box>

            </Box> */}
            {/* SURFACE AREA */}
            {/* <Box>
                <Box>
                    <Typography variant="h6" sx={{ fontSize: '14px', fontWeight: 800 }} gutterBottom>HAPËSIRA</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }} onClick={() => setOpenMobileSurface(!openMobileSurface)}>
                        {
                            state.surfaceFrom || state.surfaceTo
                                ?
                                <Typography color={'#53626F'}>{state.surfaceFrom} M² / {state.surfaceTo} M²</Typography>
                                :
                                <Typography color={'#53626F'}>{lng[language]['any']}</Typography>
                        }
                        <KeyboardArrowDownIcon />
                    </Box>
                    {
                        openMobileSurface &&
                        <Box sx={{ display: 'grid', width: '100%', gridTemplateRows: '50px', paddingTop: '20px', height: '100%', backgroundColor: '#FFF' }}>
                            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 50px 1fr', marginX: 'auto', width: '100%', gridGap: '20px' }}>
                                <Box>
                                    <InputLabel sx={{ fontSize: '14px', marginBottom: '10px', fontWeight: 'bold' }}>MIN</InputLabel>
                                    <FormControl
                                        fullWidth
                                        sx={{ '.MuiInputBase-root': { '&::before': { border: 'none' }, '&::after': { border: 'none' }, border: 'solid 1px #53626F', textAlign: 'center', paddingLeft: '5px', paddingY: '10px' } }}>
                                        <NativeSelect defaultValue={''} value={state.surfaceFrom} name="surfaceFrom" onChange={(e) => handleUpdateFilters(e)}>
                                            <Box component={'option'} value={''}>{lng[language]['selectArea']}</Box>
                                            {surface?.map((data) => (<Box component={'option'} key={data} value={data}>{data} M²</Box>))}
                                        </NativeSelect>
                                    </FormControl>
                                </Box>
                                <Typography sx={{ marginTop: '40px', textAlign: 'center' }}>{lng[language]['upTo']}</Typography>
                                <Box>
                                    <InputLabel sx={{ fontSize: '14px', marginBottom: '10px', fontWeight: 'bold' }}>MAX</InputLabel>
                                    <FormControl
                                        fullWidth
                                        sx={{ '.MuiInputBase-root': { '&::before': { border: 'none' }, '&::after': { border: 'none' }, border: 'solid 1px #53626F', textAlign: 'center', paddingLeft: '5px', paddingY: '10px' } }}>
                                        <NativeSelect defaultValue={''} value={state.surfaceTo} name="surfaceTo" onChange={(e) => handleUpdateFilters(e)}>
                                            <Box component={'option'} value={''}>{lng[language]['selectArea']}</Box>
                                            {surface?.map((data) => (<Box component={'option'} key={data} value={data}>{data} M²</Box>))}
                                        </NativeSelect>
                                    </FormControl>
                                </Box>
                            </Box>
                        </Box>
                    }

                    <Box component={'hr'} sx={{ marginTop: openMobileSurface ? '45px' : '30px', borderWidth: '1px' }} />
                </Box>

            </Box> */}
        </Box>
    );

    return (
        <Box>
            {desktopFilters}

            <Box
                sx={{
                    display: { md: 'none', xs: 'flex' },
                    border: 'solid 1.6px #53626f',
                    width: '90%',
                    padding: '15px',
                    justifyContent: 'space-between',
                    color: '#53626f',
                    fill: '#53626f',
                    marginX: 'auto',
                    cursor: 'pointer'
                }}
                onClick={() => setOpenMobile(!openMobile)}
            >
                <Typography variant="body1">{lng[language]['filters']}</Typography>
                <AddIcon />
            </Box>

            {
                openMobile === true &&
                <Box
                    sx={{
                        display: 'grid',
                        width: '90%',
                        marginX: 'auto',
                        gridTemplateRows: '1fr',
                        paddingY: '40px',
                        height: '100%',
                        backgroundColor: '#fff',
                    }}
                >
                    {mobileFilters}
                </Box>
            }
        </Box>
    )
}
