import React from 'react'
import { Route, Routes } from "react-router-dom";
// import Contact from '../pages/public/Contact';
import Error404 from "../pages/public/Error404";
import Homepage from '../pages/public/Homepage';
import Residencies from '../pages/public/Residencies';
import Availability from '../pages/public/Availability';
import Animities from '../pages/public/Animities';
import AvailabilityDetails from '../pages/public/AvailabilityDetails';
import AboutUs from '../pages/public/AboutUs';
import RezidenciesDetails from '../pages/public/RezidenciesDetails';
import RezidenciesOffert from '../pages/public/RezidenciesOffert';
import Compare from '../pages/public/Compare';
import TermsAndConditions from '../pages/public/TermsAndConditions';
import ConfirmEmail from '../pages/public/ConfirmEmail';
import ConfirmMobile from '../pages/public/ConfirmMobile';
import SentEmail from '../pages/public/SentEmail';
import TestSite from '../pages/private/TestSite';
import PrivacyPolicy from '../components/consent/PrivacyPolicy';
// import HomepageS from '../pages/public/HomePageS';
// import FetchData from '../api/FetchData';

export default function AllRoutes() {

    // const [pages, setPages] = React.useState([]);
    // const [loading, setLoading] = React.useState(true);
    // const getPages = () => {
    //     FetchData.getAll('/page/obtainPages')
    //         .then(res => {
    //             setPages(res.data.data);
    //             setLoading(false);
    //         })
    // }

    // React.useEffect(() => {
    //     getPages();
    // }, [])


    return (
        <Routes>
            <Route path="/" element={<Homepage />} />
            {/* Rezidencat */}
            <Route path="/properties" element={<Residencies />} />
            <Route path="/residencieDetails/:id" element={<RezidenciesDetails />} />
            <Route path="/reserve/:id" element={<RezidenciesOffert />} />
            {/* COMPARE */}
            <Route path="/compare/:id" element={<Compare />} />
            {/* Smenities */}
            <Route path="/amenities" element={<Animities />} />
            <Route path="/availability" element={<Availability />} />
            <Route path="/availability/:id" element={<AvailabilityDetails />} />
            <Route path='/aboutus' element={<AboutUs />} />
            <Route path="/termsAndConditions" element={<TermsAndConditions />} />
            <Route path="/userData" element={<SentEmail />} />
            {/* CONFIRM EMAIL */}
            <Route path="/confirmEmail/:id" element={<ConfirmEmail />} />
            <Route path="/confirmMobile/:id" element={<ConfirmMobile />} />
            <Route path="/apiTestSite" element={<TestSite />} />

            {/* PRIVACY POLICY */}
            <Route path="/pricaypolicy" element={<PrivacyPolicy />} />

            {/* 404 ERROR */}
            <Route path="*" element={<Error404 />} />

        </Routes>
    )
}