import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Box from '@mui/material/Box';
import queryString from 'query-string'
import FetchData from '../../api/FetchData';
import CircularProgress from '@mui/material/CircularProgress';
import CardRezidencies from '../../components/card/CardRezidencies';

// import Loader from '../../components/loader/Loader';
import AllFilter from '../../components/filters/AllFilter';
import { Button, Typography } from '@mui/material';
import SkeletonComp from '../../components/SkeletonComp';
import RezidenciesSkeleton from './RezidenciesSkteleon.json';
import { useSelector } from 'react-redux';
import { lng } from '../../utils/language';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseIcon from '@mui/icons-material/Close';
import { checkIfExist, getSelectedItem, insertItems, resetComp } from '../../utils/utils';
import Alerts from '../../components/functions/Alerts';
import ReactPixel from '../../utils/reactPixel';

export default function Residencies() {

    const navigate = useNavigate();
    const location = useLocation();
    const [filters, setFilters] = useState({
        typology: '',
        orientation: '',
        // priceFrom: '',
        // priceTo: '',
        // floorNo: '',
        // surfaceFrom: '',
        // surfaceTo: '',

        status: ["free", "notForSale"],
        // limit: 5,
        // page: 0
    });
    const language = useSelector((state) => state.language.language);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [otherLoader, setOtherLoader] = useState(false);
    const [count, setCount] = useState(null);
    const [otherSelect, setOtherSelect] = useState(true);
    const [specialSelect, setSpecialSelect] = useState([])

    const [page, setPage] = useState(0);
    const [visibleAps, setVisibleAps] = useState();

    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();
    const initialObtain = () => {
        setPage(0);
        ReactPixel.track(
            "Search Properties", {
            Search_Propertios: {
                typology: queryString.parse(location.search).typology ? queryString.parse(location.search).typology : '' ,
                orientation: queryString.parse(location.search).orientation ? queryString.parse(location.search).orientation : '' ,
            }, 
            Date: new Date()
        })
        setFilters({
            ...filters,
            typology: queryString.parse(location.search).typology ? queryString.parse(location.search).typology : '' ,
            orientation: queryString.parse(location.search).orientation ? queryString.parse(location.search).orientation : '' ,
            // priceFrom: queryString.parse(location.search).priceFrom ? queryString.parse(location.search).priceFrom : '',
            // priceTo: queryString.parse(location.search).priceTo ? queryString.parse(location.search).priceTo : '',
            // floorNo: queryString.parse(location.search).floorNo ? queryString.parse(location.search).floorNo : '',
            // surfaceFrom: queryString.parse(location.search).surfaceFrom ? queryString.parse(location.search).surfaceFrom : '',
            // surfaceTo: queryString.parse(location.search).surfaceTo ? queryString.parse(location.search).surfaceTo : '',
            status: ["free", "reserved"],
            // limit: 5,
            // page: 0,
            excludedFrom: getSelectedItem()
        })
        FetchData.create('/apartments/filterRes', {
            typology: queryString.parse(location.search).typology ? queryString.parse(location.search).typology : '' ,
            orientation: queryString.parse(location.search).orientation ? queryString.parse(location.search).orientation : '' ,
            // priceFrom: queryString.parse(location.search).priceFrom ? queryString.parse(location.search).priceFrom : '',
            // priceTo: queryString.parse(location.search).priceTo ? queryString.parse(location.search).priceTo : '',
            // floorNo: queryString.parse(location.search).floorNo ? queryString.parse(location.search).floorNo : '',
            // surfaceFrom: queryString.parse(location.search).surfaceFrom ? queryString.parse(location.search).surfaceFrom : '',
            // surfaceTo: queryString.parse(location.search).surfaceTo ? queryString.parse(location.search).surfaceTo : '',
            status: ["free", "reserved"],
            // limit: 5,
            // page: 0,
            excludedFrom: getSelectedItem()
        })
            .then(res => {
                if (res?.data?.success) {
                    setData(res?.data?.data);
                    setVisibleAps(res?.data?.data.splice(0, 5));
                    setCount(res?.data?.count[0]);
                }
                setLoading(false);
            })
    }

    const obtainSpecialOne = () => {
        setOtherSelect(true)
        if (getSelectedItem()) {
            FetchData.create('/apartments/getSpecificApartemnt', { apartmentId: getSelectedItem() })
            .then(res => {
                console.log(res.data);
                setSpecialSelect(res.data.data);
                setOtherSelect(false)
            })
        }else{
            setOtherSelect(false)
        }
        setOtherSelect(false)
    }


    useEffect(() => {
        obtainSpecialOne();
        setLoading(true)
        initialObtain();
        getSelectedItem()
        // eslint-disable-next-line
    }, [location.search])
    // WHEN REDIRECT HAPPENDS USUALLY FROM THE HOMEPAGE
    useEffect(() => {
        initialObtain();
        getSelectedItem()
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, [])

    const loadMoreData = () => {
        // setOtherLoader(true);
        // FetchData.create('/apartments/filterRes', {
        //     typology: queryString.parse(location.search).typology ? queryString.parse(location.search).typology : '' ,
        //     orientation: queryString.parse(location.search).orientation ? queryString.parse(location.search).orientation : '' ,
        //     priceFrom: queryString.parse(location.search).priceFrom ? queryString.parse(location.search).priceFrom : '',
        //     priceTo: queryString.parse(location.search).priceTo ? queryString.parse(location.search).priceTo : '',
        //     floorNo: queryString.parse(location.search).floorNo ? queryString.parse(location.search).floorNo : '',
        //     surfaceFrom: queryString.parse(location.search).surfaceFrom ? queryString.parse(location.search).surfaceFrom : '',
        //     surfaceTo: queryString.parse(location.search).surfaceTo ? queryString.parse(location.search).surfaceTo : '',
        //     status: ["free", "reserved"],
        //     // limit: 5,
        //     // page: page + 5,
        //     excludedFrom: getSelectedItem()
        // })
        //     .then(res => {
        //         setFilters({ ...filters, page: filters.page + 5 })

        //         setData((prevPosts) => [...prevPosts, ...res.data.data]);

        //         setOtherLoader(false)
        //     })

        setOtherLoader(true);
        const newVisibleCount = parseInt(page) + parseInt(5);
        setPage(newVisibleCount);
        setVisibleAps(prevApartments => [
            ...prevApartments,
            ...data.slice(page, newVisibleCount),
          ]);
        setOtherLoader(false)
    }

    const handleChange = (e) => {
        setFilters(e)
        setSearchParams(e);
    }

    // HOLDS THE IDS
    const [comapareIds, setCompareIds] = useState([]);
    // HOLDS THE DATA FROM SET IDS
    const [compareAptData, setCompareAptData] = useState([]);
    const [compareAptLoader, setCompareAptLoader] = useState(true);
    const [compareOpen, setCompareOpen] = useState(false);
    const [mobileCompareOpen, setMobileCompareOpen] = useState(false);

    const handleCompareData = (data) => {
        setCompareAptLoader(true)
        FetchData.create('/apartments/getSpecificApartemnt', { apartmentId: data })
            .then(res => {
                if (res?.data?.success) {
                    setCompareAptData(res?.data?.data);
                    setCompareAptLoader(false);
                }
            })
    }
    useEffect(() => {
        document.body.style.overflow = 'unset';
        if (checkIfExist().success) {
            setCompareIds(checkIfExist().data)
            handleCompareData(checkIfExist().data.toString());
        } return;
    }, [])
    useEffect(() => {
        setCompareOpen(true);
        // eslint-disable-next-line
    }, [comapareIds.length > 0])

    const handleCompare = (checked, id) => {
        if (comapareIds.length === 3) {
            setOpenAlert(true)
        }else{
            if (checked) {
                if (comapareIds !== 3) {
                    addCompare(id);
                } else return;
            } else {
                removeCompareItem(id);
            }
        }
    }

    const addCompare = (data) => {
        const newData = [...comapareIds, data];
        insertItems(newData, false);
        setCompareIds(newData);
        handleCompareData(newData.toString());
    }

    const removeCompareItem = (data) => {
        insertItems(data, true);
        const newData = comapareIds.filter((emp) => emp !== data);
        setCompareIds(newData);
        handleCompareData(newData.toString())
    }

    const resetCompare = () => {
        setCompareOpen(false);
        resetComp();
        setCompareIds([]);
        setCompareAptData([]);
    }

    const handeRidirect = () => {
        navigate(`/compare/${checkIfExist().data.toString()}`);
    }

    const [openAlert, setOpenAlert] = useState(false)
    // eslint-disable-next-line
    const [msg, setMsg] = useState({
        severity: 'error'
    });

    return (
        <Box sx={{ paddingTop: '40px', paddingBottom: '40px' }}>

            {/* DATA */}
           <Box sx={{display: 'flex', width: {md: '1024px', xs: '100%'}, flexDirection: {md: 'row', xs: 'column'}, justifyContent: 'space-between', marginX: 'auto'}}>

           <AllFilter
                state={filters}
                updateState={handleChange}
                stateTypology={filters.typology}
                stateOrientation={filters.orientation}
            />

            {/* {   
                count &&
                <Box sx={{ color: '#002856', width: {md: '100%', xs: '90%'}, marginX: 'auto',marginTop: {md: '0', xs: '20px'}, display: 'flex', justifyContent: {md: 'flex-end', xs: 'flex-start'}, alignItems: 'center'}}>
                    {
                        loading || otherLoader ?
                        <SkeletonComp
                            width={'70px'}
                            height={'50px'}
                            variant={'text'}
                        />
                        :
                        <Typography sx={{fontWeight: 'normal', fontSize: '20px'}}>{count} {lng[language]['units']} </Typography>
                    }
                </Box>
            } */}
           </Box>
           
            {
                loading === false && otherSelect === false
                    ?
                    <>
                        <Box sx={{ marginTop: { md: '50px', xs: '20px' }, marginX: 'auto', width: { md: '1024px', xs: '90%' }, height: '100%' }}>
                            {
                                specialSelect?.length > 0 ?
                                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', rowGap: { md: '100px', xs: '50px' }, paddingBottom: {md: '50px', xs: '20px'} }}>
                                        {specialSelect?.map((data, index) => (
                                            <CardRezidencies
                                                key={index}
                                                selected={getSelectedItem()}
                                                objectName={data.objectName}
                                                imageName={data.imageName}
                                                buildingName={data.buildingName}
                                                apartmentName={data.apartmentName}

                                                // COMPARE PART
                                                checkForMatchingId={comapareIds}
                                                handleCompare={handleCompare}
                                                orientation={data.orientationName}

                                                // DATA
                                                id={data.apartmentId}
                                                floorNo={data.floorNo}
                                                surfaceGrossWithTerrace={data.surfaceGrossWithTerrace}
                                                typology={data.typology}
                                                surfaceTerrace={data.surfaceTerrace}
                                                priceTotal={parseFloat(data.priceTotal)}
                                                status={data.status}
                                                priceParking={parseInt(data.priceParking)}
                                            />
                                        ))}
                                    </Box>
                                    :
                                    <></>
                            }
                            {
                                visibleAps?.length > 0 ?
                                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', rowGap: { md: '100px', xs: '50px' } }}>
                                        {visibleAps?.map((data, index) => (
                                            <CardRezidencies
                                                key={index}
                                                objectName={data.objectName}
                                                imageName={data.imageName}
                                                buildingName={data.buildingName}
                                                apartmentName={data.apartmentName}

                                                // COMPARE PART
                                                checkForMatchingId={comapareIds}
                                                handleCompare={handleCompare}
                                                orientation={data.orientationName}

                                                // DATA
                                                id={data.apartmentId}
                                                floorNo={data.floorNo}
                                                surfaceGrossWithTerrace={data.surfaceGrossWithTerrace}
                                                typology={data.typology}
                                                surfaceTerrace={data.surfaceTerrace}
                                                priceTotal={parseFloat(data.priceTotal)}
                                                status={data.status}
                                                priceParking={parseInt(data.priceParking)}
                                            />
                                        ))}
                                    </Box>
                                    :
                                    <Typography textAlign={'center'} marginTop={3} sx={{ fontSize: '20px', fontWeight: 600, color: '#002856' }}>{lng[language]['noData']}</Typography>
                            }
                            {
                                otherLoader === true &&
                                <Box sx={{ display: 'grid', gridGap: '40px', gridTemplateColumns: { md: 'repeat(4, 1fr)', xs: '1fr' }, gridTemplateRows: { md: '1fr 1fr 1fr 50px', xs: 'auto auto auto auto' }, paddingTop: '50px', width: { md: '1024px', xs: "90%" }, marginX: 'auto' }}>
                                    {
                                        RezidenciesSkeleton.map((data, index) => (
                                            <SkeletonComp
                                                key={index}
                                                variant={data.variant}
                                                style={data.style}
                                                height={data.height}
                                                width={data.width}
                                            />
                                        ))
                                    }
                                </Box>
                            }
                            {
                                (count > parseInt(visibleAps.length) || count === parseInt(visibleAps.length))  &&
                                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '40px'}}>
                                    {
                        loading || otherLoader ?
                        <CircularProgress sx={{width: '15px', height: '15px', color: '#002856'}} />
                        :
                                    <Button variant='contained' onClick={() => loadMoreData()} sx={{borderRadius: 0, background: '#002856', border: 'solid 1px #002856', '&:hover': {background: '#FFF', color: '#002856'}}}>
                                        {lng[language]['loadMore']}
                                    </Button>
}
                                </Box>
                            }
                        </Box>

                        {
                            (comapareIds && comapareIds?.length > 0) ?
                                compareOpen &&
                                <>
                                    {/* DESKTOP */}
                                    <Box sx={{ display: { md: 'block', xs: 'none' }, position: 'fixed', bottom: 0, left: 0, width: '100%', height: 200, backgroundColor: '#FFF', borderTop: 'solid 1px', color: '#002856', zIndex: 2 }}>
                                        <Box sx={{ width: '80%', marginX: 'auto', display: 'grid', gridTemplateColumns: '1fr 200px', height: '100%', columnGap: '40px' }}>

                                            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', columnGap: '40px', justifyItems: 'stretch', alignItems: 'center' }}>
                                                {
                                                    compareAptLoader === false ?
                                                        compareAptData.map((data, index) => (
                                                            <Box key={index} sx={{ height: '130px', padding: '20px', width: '100%', border: 'solid 1px', display: 'grid', gridTemplateColumns: '1fr 30px', columnGap: '10px' }}>
                                                                <Box sx={{ display: 'grid' }}>
                                                                    <Box>
                                                                        <Typography variant="subtitle1" sx={{ fontWeight: 'normal', fontSize: '18px' }}>{language === 'eng' ? data.apartmentName.replace('Apartamenti', 'Apartment') : data.apartmentName}/{language === 'eng' ? data.buildingName.replace('Godina', 'Building') : data.buildingName}</Typography>
                                                                    </Box>
                                                                    <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                                                        <Box sx={{ marginRight: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                            <Box><Box component={'img'} src='https://fundway.com/images/2024/double-bed.svg' sx={{ width: '35px', height: '35px' }} /></Box>
                                                                            <Typography sx={{ marginLeft: '10px', fontWeight: 'normal' }}>{data.typology}</Typography>
                                                                        </Box>
                                                                        {/* {
                                                                            data.status === 'free'
                                                                            ?
                                                                                <Box sx={{ marginLeft: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                    <Box><Box component={'img'} src='https://fundway.com/images/2024/money.svg' sx={{ width: '35px', height: '35px' }} /></Box>
                                                                                    <Typography sx={{ marginLeft: '10px', fontWeight: 'normal' }}>{new Intl.NumberFormat('us-Us', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format(Math.ceil((parseFloat(data.priceTotal) + parseInt(data.priceParking)) / 100) * 100)}</Typography>
                                                                                </Box>
                                                                                : */}
                                                                                <Box sx={{ marginLeft: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                    <Box><Box component={'img'} src='https://fundway.com/images/icons/eye.svg' sx={{ width: '35px', height: '35px' }} /></Box>
                                                                                    <Typography sx={{ marginLeft: '10px', fontWeight: 'normal' }}>{data.orientationName}</Typography>
                                                                                </Box>
                                                                        {/* } */}
                                                                        
                                                                    </Box>
                                                                </Box>
                                                                <Box onClick={() => removeCompareItem(data.apartmentId)}>
                                                                    <CloseIcon sx={{ marginTop: '10px', cursor: 'pointer' }} />
                                                                </Box>
                                                            </Box>
                                                        ))
                                                        :
                                                        <>
                                                            <SkeletonComp
                                                                width={'100%'}
                                                                height={'200px'}
                                                            />
                                                            <SkeletonComp
                                                                width={'100%'}
                                                                height={'200px'}
                                                            />
                                                            <SkeletonComp
                                                                width={'100%'}
                                                                height={'200px'}
                                                            />
                                                        </>
                                                }
                                            </Box>

                                            <Box sx={{ height: '70%', margin: 'auto', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'space-around' }}>
                                                <Button sx={{ width: '150px', color: '#FFF', borderRadius: 0, backgroundColor: '#002856', padding: '10px 15px', border: 'solid 1px #002856', '&:hover': { color: '#002856', backgroundColor: '#FFF' }, '&.Mui-disabled': { background: '#53626f', borderColor: '#53626f', color: '#FFF' } }} disabled={comapareIds?.length > 1 ? false : true} onClick={() => handeRidirect()}>
                                                    {lng[language]['comapreResidencies']}
                                                </Button>
                                                <Button onClick={() => resetCompare()} sx={{ color: '#C8CFD6' }}>
                                                    {lng[language]['resetFilters']}
                                                </Button>
                                            </Box>

                                        </Box>
                                    </Box>

                                    {/* MOBILE */}
                                    <Box sx={{ display: { md: 'none', xs: 'grid' }, position: 'fixed', bottom: 0, left: 0, width: '100%', height: 'auto', gridTemplateRows: '60px 1fr', rowGap: mobileCompareOpen ? '20px' : 0, backgroundColor: '#FFF', borderTop: 'solid 1px', color: '#002856', transition: '.3s all', paddingBottom: mobileCompareOpen ? '10px' : 0, }}>
                                        <Box sx={{ width: '90%', marginX: 'auto', padding: '10px', display: 'flex', alignItems: 'center' }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }} onClick={() => setMobileCompareOpen(!mobileCompareOpen)}>
                                                <Typography variant="h6">{lng[language]['compare']} {`(${comapareIds.length})`}</Typography>
                                                <KeyboardArrowUpIcon sx={{ rotate: mobileCompareOpen ? '180deg' : '0', transition: '.3s all' }} />
                                            </Box>
                                        </Box>
                                        {
                                            mobileCompareOpen &&
                                            <Box
                                                sx={{
                                                    width: '90%',
                                                    rowGap: '20px',
                                                    display: 'grid',
                                                    marginX: 'auto',
                                                    gridTemplateRows: '1fr 100px',
                                                }}
                                            >

                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        rowGap: '20px',
                                                        display: 'grid',
                                                        marginX: 'auto',
                                                        gridTemplateRows: '1fr 1fr',
                                                    }}
                                                >
                                                    {
                                                        compareAptLoader === false ?
                                                            compareAptData.map((data, index) => (
                                                                <Box key={index} sx={{ height: '150px', padding: '20px', width: '100%', border: 'solid 1px', display: 'grid', gridTemplateColumns: '1fr 20px', columnGap: '10px' }}>
                                                                    <Box sx={{ display: 'grid' }}>
                                                                        <Box>
                                                                            <Typography variant="subtitle1" sx={{ fontWeight: 700, fontSize: '19px' }}>{language === 'eng' ? data.apartmentName.replace('Apartamenti', 'Apartment') : data.apartmentName}/{language === 'eng' ? data.buildingName.replace('Godina', 'Building') : data.buildingName}</Typography>
                                                                        </Box>
                                                                        <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                                                            <Box sx={{ marginRight: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                <Box><Box component={'img'} src='https://fundway.com/images/2024/double-bed.svg' sx={{ width: '35px', height: '35px' }} /></Box>
                                                                                <Typography sx={{ marginLeft: '10px', fontWeight: 'bold' }}>{data.typology}</Typography>
                                                                            </Box>
                                                                            {/* {
                                                                                data.status === 'free'
                                                                                ?
                                                                                <Box sx={{ marginLeft: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                <Box><Box component={'img'} src='https://fundway.com/images/2024/money.svg' sx={{ width: '35px', height: '35px' }} /></Box>
                                                                                <Typography sx={{ marginLeft: '10px', fontWeight: 'bold' }}>{new Intl.NumberFormat('us-Us', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format(Math.ceil((parseFloat(data.priceTotal) + parseInt(data.priceParking)) / 100) * 100)}</Typography>
                                                                            </Box>
                                                                            : */}
                                                                            <Box sx={{ marginLeft: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                <Box><Box component={'img'} src='https://fundway.com/images/icons/eye.svg' sx={{ width: '35px', height: '35px' }} /></Box>
                                                                                <Typography sx={{ marginLeft: '10px', fontWeight: 'bold' }}>{data.orientationName}</Typography>
                                                                            </Box>
                                                                            {/* } */}
                                                                        </Box>
                                                                    </Box>
                                                                    <Box onClick={() => removeCompareItem(data.apartmentId)}>
                                                                        <CloseIcon sx={{ marginTop: '5px', cursor: 'pointer' }} />
                                                                    </Box>
                                                                </Box>
                                                            ))
                                                            :
                                                            <>
                                                                <SkeletonComp
                                                                    width={'100%'}
                                                                    height={'200px'}
                                                                />
                                                                <SkeletonComp
                                                                    width={'100%'}
                                                                    height={'200px'}
                                                                />
                                                            </>
                                                    }
                                                </Box>

                                                <Box sx={{ height: '100%', margin: 'auto', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'space-around' }}>
                                                    <Button sx={{ width: 'max-content', color: '#FFF', borderRadius: 0, backgroundColor: '#002856', border: 'solid 1px #002856', '&:hover': { color: '#002856', backgroundColor: '#FFF' }, '&.Mui-disabled': { background: '#53626f', borderColor: '#53626f', color: '#FFF' } }} disabled={comapareIds?.length > 1 ? false : true} onClick={() => handeRidirect()}>
                                                        {lng[language]['comapreResidencies']}
                                                    </Button>
                                                    <Button onClick={() => resetCompare()} sx={{ color: '#C8CFD6' }}>
                                                        {lng[language]['resetFilters']}
                                                    </Button>
                                                </Box>

                                            </Box>
                                        }
                                    </Box>
                                </>
                                :
                                ''
                        }

                    </>
                    :
                    <Box sx={{ display: 'grid', gridGap: '40px', gridTemplateColumns: { md: 'repeat(4, 1fr)', xs: '1fr' }, gridTemplateRows: { md: '1fr 1fr 1fr 50px', xs: 'auto auto auto auto' }, paddingTop: '50px', width: { md: '1024px', xs: "90%" }, marginX: 'auto' }}>
                        {
                            RezidenciesSkeleton.map((data, index) => (
                                <SkeletonComp
                                    key={index}
                                    variant={data.variant}
                                    style={data.style}
                                    height={data.height}
                                    width={data.width}
                                />
                            ))
                        }
                    </Box>
                // <Loader state={loading} />
            }

            <Alerts 
                setOpen={setOpenAlert}
                open={openAlert}
                msg={msg.msg}
                severity={msg.severity}
            />

        </Box>
    )
}
