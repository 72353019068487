import React from 'react'
import Containers from '../../layout/Containers'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux';
import { lng } from '../../utils/language';

export default function TermsAndConditions() {
    const language = useSelector((state) => state.language.language);
    return (
        <Box sx={{color: '#11294D'}}>
            <Containers>
                <Typography variant="h4">
                    {lng[language]['tearmsHeader']}
                </Typography>

                <Typography variant="body1">
                    {lng[language]['tearmsDesc']}
                </Typography>
            </Containers>
        </Box>
    )
}