import React from 'react';
import Box from '@mui/material/Box';
import YouTube from 'react-youtube';

export default function ShowVideoModule({data, style, extraProperties}) {
    return (
        <Box sx={style ? style : {}}> 
        <Box  sx={{position: 'absolute', width: 1, height: 1, top: 0, left: 0, zIndex: 2}}>

        </Box>
            <Box sx={{position: 'absolute', width: 1, height: 1, top: 0, left: 0, zIndex: 1}}>
            <Box
                    component={YouTube}
                    opts={{
                        height: '100%', 
                        width: '100%', 
                        playerVars: {
                            playlist: data ? data : 'njmThrg5obM', 
                            loop: 1, 
                            autoplay: 1, 
                            controls: 0, 
                            mute: 1, 
                            enablejsapi: 1, 
                            disablekb: 1, 
                            origin: 'https://fundway.com/'
                        }
                    }}
                    sx={{
                        width: 1, 
                        height: 1, 
                        borderRadius: '20px', 
                        overflow: 'hidden'
                    }}
                    videoId={data ? data : 'njmThrg5obM'}
                    title={'Youtube Video Player'}
                />
            </Box>
        </Box>
    )
}