import React from 'react';
import Alert from '@mui/material/Alert';
import { lng } from '../../utils/language';
import { useSelector } from 'react-redux';

export default function Alerts({severity, open, setOpen}) {
    const language = useSelector((state) => state.language.language);
    setTimeout(() => {
        setOpen(false)
    }, 5000);
  return (
    open &&
    <Alert 
        severity={severity} 
        sx={{
            '.MuiAlert-action': {
                display: 'none'
            }, 
            svg: {
                fill: '#FFF', 
                color: '#000'
            }, 
            position: 'fixed', 
            zIndex: 10, 
            bottom: 0, 
            left: 0, 
            display: 'flex', 
            justifyContent: 'center', 
            backgroundColor: '#002856', 
            width: '100%', 
            borderRadius: 0, 
            color: '#FFF'
        }}>
            {lng[language]['errorAps']}
        </Alert>
  )
}