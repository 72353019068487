// src/ImageSlider.js
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ImageSlider.css"; // Create this file for custom styles
import Box from "@mui/material/Box";

const ImageSlider = ({ images, settings }) => {

  return (
    <Box className="image-slider" 
        sx={{
            '.slick-slide': {
                padding: {md: '0 5px', xs: 0},
                img: {
                    height: {md: '500px', xs: '400px'},
                    objectFit: 'cover',
                    width: '100%',
                }
            },
            '.slick-arrow': {
                display: 'none'
            },
            '.slick-list': {
                gridRowStart: 1,
                gridRowEnd: 2,
                gridColumnStart: 1,
                gridColumnEnd: 4,
            },
            '.slick-slider': {
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gridTemplateRows: {md: '1fr 30px', xs: '1fr 22px'},
            },
            '.slick-dots': {
                width: {md: 'max-content', xs: '100%'},
                bottom: 0,
                backgroundColor: '#FFF',
                gridRowStart: 2,
                gridRowEnd: 3,
                gridColumnStart: 1,
                gridColumnEnd: {md: 3, xs: 4},
                margin: 0,
                padding: 0
            }
        }}
    >
      <Slider {...settings}>
        {images.map((image, index) => (
          <Box key={index} sx={{backgroundImage: `url(${image.imageName})`, height: {md: '500px', xs: '400px'}, width: 1, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}>
            <img src={image.imageName} alt={`Slide ${index}`} />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default ImageSlider;