import React from 'react';
import Box from '@mui/material/Box';
import { privacyLng } from '../../utils/priacy';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography'


export default function PrivacyPolicy() {
    const language = useSelector((state) => state.language.language);
    const privacyAlb = (
        <Box sx={{ color: '#002856' }}>
            <Box>
                <Typography variant="h2">{privacyLng[language]['policy']}</Typography>
                <Box component={'hr'} sx={{ background: '#707070' }} />
            </Box>
            <Box sx={{ display: 'grid', gridTemplateRows: '1fr', rowGap: '25px' }}>
                {/* 1. */}
                <Box marginTop={'50px'}>
                    <Typography variant="h4" gutterBottom>{privacyLng[language]['policy1']}</Typography>
                    <Typography variant="body1">
                        Objekti i kësaj politike privatësie (<Box component={'span'} sx={{ fontWeight: 'bold' }}>“Politika e Privatësisë”</Box>, <Box component={'span'} sx={{ fontWeight: 'bold' }}>“Politika”</Box>) është përcaktimi i procedurave organizative e teknike, masave për mbrojtjen e të dhënave personale, si edhe sigurisë, ruajtjes dhe administrimit të të dhënave personale nga strukturat e degës në Shqipëri të Fundway LLC.
                    </Typography>
                </Box>

                {/* 1.1. */}
                <Box>
                    <Typography variant="h5" marginBottom={'20px'}>{privacyLng[language]['policy11']}</Typography>
                    <Typography variant="body1">
                        Dega në Shqipëri e Fundway LLC., regjistruar në Regjistrin Tregtar me NUIS: M22316001A, me adresë të ushtrimit të aktivitetit tregtar në Shqipëri në Rrugën “Brigada VIII“, Pallati 8/1, Kati 4 Apartamenti 14, Tiranë, Shqipëri, ka si objekt aktivitieti tregtar kryerjen e veprimtarive të ndryshme me pasuri të paluajtshme, duke përfshirë po pa u kufizuar në veprimtari në fushën e ndërtimit, veprimtari ekonomike në fushën e pasurive të paluajtshme, projektim dhe ndërtim i objekteve civile apo industriale dhe menaxhimi i zbatimit të projekteve të kësaj natyre për vete dhe për të tretë, investimin në projekte zhvillimi zonash urbane, turistike apo rezidenciale apo investime të cilësdo natyre, qoftë në zhvillimin e pasurive të paluajtshme në përgjithësi, si dhe veprimtari të tjera që lidhen me to.
                    </Typography>
                </Box>

                {/* 1.2. */}
                <Box>
                    <Typography variant="h5" marginBottom={'20px'}>{privacyLng[language]['policy12']}</Typography>
                    <Typography variant="body1">
                        Kjo Politikë Privatësie zbatohet vetëm për të dhënat personale që përpunohen si rezultat i përdorimit të faqes së internetit (“faqe web” ose “website”) www.fundway.com nga individët që e vizitojnë atë. 
                        <br /><br />
                        Kjo Politikë nuk zbatohet për informacionin e mbledhur: 
                        <br /><br />
                        • nëpërmjet çdo forme tjetër, apo nëpërmjet faqeve të internetit që operohen nga palë të treta; 
                        <br />
                        • nga çdo palë e tretë, përfshirë nëpërmjet faqeve apo aplikacioneve që përmbajnë lidhje që të drejtojnë në këtë faqe web.
                    </Typography>
                </Box>

                {/* 1.3. */}
                <Box>
                    <Typography variant="h5" marginBottom={'20px'}>{privacyLng[language]['policy13']}</Typography>
                    <Typography variant="body1">
                        Në Shqipëri ekziston një legjislacion I gjerë për mbrojtjen e të dhënave personale, zbatimit i të cilit qëndron në themel të ushtrimit të veprimtarisë tregtare të Fundway. 
                        <br/><br/>
                        Kjo Politikë bazohet në kuadrin ligjor vendas si vijon: 
                        <br/><br/>
                        • Kushtetuta e Republikës së Shqipërisë, nenet 15-58 të saj. 
                        <br />
                        • Ligji Nr. 9887, datë 10.03.2008, “Për mbrojtjen e të dhënave personale”, i ndryshuar (<Box component={'span'} sx={{ fontWeight: 'bold' }}>“Ligji për Mbrojtjen e të Dhënave Personale”</Box>). 
                        <br />
                        • Urdhrat, Udhëzimet dhe Vendimet e Komisionerit për të Drejtën e Informimit dhe Mbrojtjen e të Dhënave Personale (<Box component={'span'} sx={{ fontWeight: 'bold' }}>“Komisioneri për Mbrojtjen e të Dhënave”</Box> ose <Box component={'span'} sx={{ fontWeight: 'bold' }}>“Komisioneri”</Box>), të aksesueshme në faqen zyrtare të këtij institucioni www.idp.al. 
                        <br />
                        • Akte të tjera ligjore e nënligjore të zbatueshme për objektin e veprimtarisë së Fundway. në vijim referuar si <Box component={'span'} sx={{ fontWeight: 'bold' }}>“Ligji i Zbatueshëm”</Box>. 
                        <br/><br/>
                        Politika e Privatësisë ka për qëllim të përcaktojë parimet e përgjithshme dhe masat organizative dhe teknike për mbrojtjen, ruajtjen, sigurinë dhe administrimin e të dhënave personale që mblidhen dhe përpunohen nëpërmjet faqes web si vijon www.fundway.com 
                        <br/><br/>
                        Ajo zbatohet për të gjitha të dhënat e përpunuara nga Fundway nëpërmjet kësaj faqeje web www.fundway.com në përputhje me Ligjin për Mbrojtjen e të Dhënave Personale. Çdo përpunimi i të dhënave tuaja që mblidhen nëpërmjet kësaj faqeje web bëhet në përputhje me Ligjin e Zbatueshëm. 
                        <br/><br/>
                        Në rast se keni pyetje, paqartësi, apo nëse kërkoni informacion lidhur me të dhënat tuaja personale të mbledhura nëpërmjet kësaj faqeje web, lutemi të na kontaktoni në një nga format e kontaktit të vendosura në dispozicion në seksionin “Na kontaktoni”, të kësaj Politike Privatësie. 
                    </Typography>
                </Box>

                {/* 2. */}
                <Box>
                    <Typography variant="h4" gutterBottom>{privacyLng[language]['policy2']}</Typography>
                    <Typography variant="body1">
                        Seksioni I mëposhtëm detajon llojet specifike të informacionit personal që mbledhim përmes kësaj faqeje web, arsyet për mbledhjen e këtyre të dhënave dhe mënyrën se si ne i përdorim ato për të përmirësuar përvojën tuaj dhe për të ofruar shërbimet tona. 
                    </Typography>
                </Box>

                {/* 2.1 */}
                <Box>
                    <Typography variant="h5" marginBottom={'20px'}>{privacyLng[language]['policy21']}</Typography>
                    <Typography variant="body1">
                        Ne përdorim të dhënat tuaja personale për të na ndihmuar t’u përgjigjemi pyetjeve ose shprehjes së interesit tuaj dhe për t’u siguruar që ne t’ju ndihmojmë të gjeni pronën e duhur që i përshtatet nevojave tuaja.
                        <br/><br/>
                        Në përputhje me Ligjin e Zbatueshëm, të dhënat personale përpunohen vetëm për një nga qëllimet e mëposhtme: 
                        <br/><br/>
                        <Box component={'span'} sx={{ fontWeight: 'bold' }}>Për të përmbushur detyrimet tona kontraktuale në përputhje me termat dhe kushtet e faqes web</Box>
                        <br/><br/>
                        Këtu përfshihen rastet në të cilat na duhet të adresojmë kërkesat, pyetjet apo shprehjet tuaja të interesit për pasuritë e paluajtshme të treguara në faqen tonë web. Me qëllim që t’i përgjigjemi kërkesave tuaja, ne do të përdorim të dhënat e kontaktit të vendosura në dispozicion nga ju nëpërmjet formularit të kontaktit të treguar në faqen web në seksionin “Na Kontaktoni”—, siç listohen në seksionin 2 të kësaj Politike. 
                        <br/><br/>
                        <Box component={'span'} sx={{ fontWeight: 'bold' }}>Për përmbushjen e një detyrimi ligjor të Fundway </Box>
                        <br/><br/>
                        Me qëllim adresimin e ankesave apo kërkesave tuaja për informacion në lidhje me të dhënat personale që përpunojmë, në përputhje me Ligjin për Mbrojtjen e të Dhënave Personale. 
                        <br/><br/>
                        <Box component={'span'} sx={{ fontWeight: 'bold' }}>Për ndjekjen e interesave legjitimë të Fundway si rezultat i ushtrimit të aktivitetit tregtar të shoqërisë</Box>
                        <br/><br/>
                        Me qëllim përmirësimin e eksperiencës suaj gjatë përdorimit të faqes sonë web, Fundway mbledh dhe përdor informacionin tuaj personal për: 
                        <br/><br/>
                        • të kuptuar interesat tuaja dhe për t’ju dërguar përmbajtje të përshtatur, të tilla si njoftime për ngjarje, zbritje dhe oferta, në rast se kemi marrë pëlqimin tuaj të shprehur për këtë qëllim në përputhje me Ligjin e Zbatueshëm. 
                        <br/><br/>
                        • të vlerësuar interesin tuaj dhe për të përshtatur marketingun tonë në përputhje me rrethanat, në rast se kemi marrë pëlqimin tuaj të shprehur për këtë qëllim në përputhje me Ligjin e Zbatueshëm. 
                        <br/><br/>
                        • për t’ju shfaqur reklama apo dërguar promocione nëpërmjet e-mail, dhe mund të bashkëpunojmë me palë të treta për të lehtësuar këtë proces, në rast se kemi marrë pëlqimin tuaj të shprehur për këtë qëllim në përputhje me Ligjin e Zbatueshëm. 
                        <br/><br/>
                        Ju mund të hiqni dorë nga pëlqimi për marrjen e komunikimeve të mësipërme në çdo kohë, duke ndjekur udhëzimet e dhëna në faqen web dhe duke shtypur butonin Unsubscribe ne emailn qe ju eshte derguar. 
                    </Typography>
                </Box>

                {/* 2.2 */}
                <Box>
                    <Typography variant="h5" marginBottom={'20px'}>{privacyLng[language]['policy22']}</Typography>
                </Box>

                {/* 2.2.1 */}
                <Box>
                    <Typography variant="h5" marginBottom={'20px'}>{privacyLng[language]['policy221']}</Typography>
                    <Typography variant="body1">
                        Ne mund t’i mbledhim të dhënat tuaja personale kur na dërgoni një kërkesë, pyetje, apo shprehje interesi nëpërmjet faqes sonë të internetit në lidhje me një nga pronat tona. Në këtë kuadër, ne mbledhim të dhënat personale të mëposhtme: 
                        <br/><br/>
                        • Emrin dhe mbiemrin, 
                        <br/>
                        • Numri Personal; 
                        <br/>• Vendi i Rezidences; 
                        <br/>• Adresën email 
                        <br/>• Adresën tuaj, 
                        <br/>• Postal Code 
                        <br/>• Numrin e telefonit, 
                        <br/>• Detajet e kërkesës suaj në përputhje me interesat tona të ligjshme të biznesit. 
                        <br/><br/>
                        Në raste të caktuara, pyetja juaj mund të lidhet me një kërkesë specifike shërbimi, si për shembull: ngritja e një pyetjeje në lidhje me një pronë specifike. 
                        <br/><br/>
                        Për kërkesa të tilla, ne duhet të përdorim të dhënat tuaja personale për të na ndihmuar t’i përgjigjemi kërkesës suaj dhe të përmbushim detyrimet tona kontraktuale, në përputhje me termat dhe kushtet, sipas shërbimit të kërkuar. 
                    </Typography>
                </Box>


                {/* 2.2.2 */}
                <Box>
                    <Typography variant="h5" marginBottom={'20px'}>{privacyLng[language]['policy222']}</Typography>
                    <Typography variant="body1">
                        Kur telefononi qendrën tonë të shërbimit ndaj klientit, ne do të mbajmë një regjistër të komunikimeve, në përputhje me interesat tona të ligjshme të ushtrimit të veprimtarisë tregtare, si edhe për të monitoruar dhe përmirësuar cilësinë e mbështetjes ndaj klientit. – nuk aplikohet momentalisht 
                        <br/><br/>
                        Fundway ndërmerr në mënyrë të vazhdueshme hapat e nevojshëm për t’u siguruar që ju të jeni të kënaqur nga përdorimi i faqes sonë web. 
                        <br/><br/>
                        Për të na ndihmuar t’u përgjigjemi komenteve ose ankesave tuaja, ne mbledhim informacionin personal të mëposhtëm: 
                        <br/><br/>
                        • Emrin dhe mbiemrin, 
                        <br/>
                        • Vendi i Rezidences: 
                        <br/>
                        • Adresën email 
                        <br/>
                        • Adresën tuaj, 
                        <br/>
                        • Numrin e telefonit, 
                        <br/>
                        • Detajet e kërkesës suaj në përputhje me interesat tona të ligjshme të biznesit. 
                    </Typography>
                </Box>


                {/* 3 */}
                <Box>
                    <Typography variant="h4" gutterBottom>{privacyLng[language]['policy3']}</Typography>
                    <Typography variant="body1">
                        Personat që marrin të dhënat tuaja të mbledhura nga faqja web janë vetëm personat e ngarkuar në Fundway, të cilët janë trajnuar që t’i përpunojnë ato në përputhje me sa kërkohet bazuar në detyrimet ligjore dhe rregullatore. 
                        <br/><br/>
                        Përveç tyre, edhe kontraktorëve (kryesisht ofruesit e shërbimeve të Teknologjisë së Informacionit dhe Komunikimit. Si dhe back office) ju vihen në dispozicion të dhënat tuaja për aq sa është e nevojshme për të kryer shërbimin respektiv. 
                        <br/><br/>
                        Të gjithë përpunuesit, në kuadër të dhënies së shërbimit respektiv, janë të detyruar që t’i përpunojnë të dhënat, bazuar te marrëveshja që kanë lidhur me Fundway për përpunimin e të dhënave dhe për ruajtjen e konfidencialitetit të të dhënave tuaja. 
                        <br/><br/>
                        Nëse ka një detyrim ligjor apo rregullator nga autoritetet publike, edhe këto të fundit mund të jenë marrës të të dhënave tuaja. 
                    </Typography>
                </Box>

                {/* 4 */}
                <Box>
                    <Typography variant="h4" gutterBottom>{privacyLng[language]['policy4']}</Typography>
                    <Typography variant="body1">
                        Transferimi i të dhënave në vende të treta (jashtë zonës Ekonomike Europiane-EEA) mund të bëhet vetëm kur është nevojshme, nëse kërkohet nga ligji ose nëse keni dhënë pëlqimin tuaj të shprehur. Transferimi i të dhënave tuaja në një vend të tretë mund të bëhet vetëm pas marrjes së autorizimit nga Komisioneri, në përputhje me Ligjin e Zbatueshëm. 
                    </Typography>
                </Box>

                {/* 5 */}
                <Box>
                    <Typography variant="h4" gutterBottom>{privacyLng[language]['policy5']}</Typography>
                    <Typography variant="body1">
                        Ne përpunojmë të dhënat tuaja personale për aq kohë sa është e nevojshme dhe e arsyeshme për t’iu përgjigjur kërkesave, pyetjeve, ankesave apo shprehjeve tuaja të interesit të dërguara nëpërmjet kësaj faqeje web. Në rast se shprehja juaj e interesit për një pasuri të paluajtshme nuk pasohet nga nënshkrimi i marrëveshjes së rezervimit të së drejtës së blerjes së pasurisë së paluajtshme në fjalë, atëherë ne do t’i fshijmë të dhënat tuaja menjëherë, përveç rasteve kur ju keni dhënë pëlqimin që këto të ruhen në databazën tonë. Arkivimi i të dhënave i nënshtrohet kufizimeve kohore. 
                        <br/><br/>
                        Për më shumë informacion rreth afateve kohore të ruajtjes së të dhënave tuaja personale, na kontaktoni në të dhënat e kontaktit të vendosura në dispozicion në seksionin ”Na Kontaktoni”. 
                    </Typography>
                </Box>

                {/* 6 */}
                <Box>
                    <Typography variant="h4" gutterBottom>{privacyLng[language]['policy6']}</Typography>
                    <Typography variant="body1">
                        Fundway ka ndërmarrë masa organizative dhe teknike të përshtatshme për të mbrojtur të dhënat tuaja personale nga shkatërrime të paligjshme, aksidentale, humbje aksidentale, për të mbrojtur aksesin ose përhapjen nga persona të paautorizuar, veçanërisht kur përpunimi i të dhënave bëhet në rrjet, si dhe nga çdo formë tjetër e paligjshme përpunimi. 
                        <br/><br/>
                        Fundway ndërmerr këto masa të veçanta sigurie: 
                        <br/><br/>
                        • Përcaktimi I funksioneve dhe personave përgjegjës në shoqëri për përdorimin e të dhënave; 
                        <br/>
                        • Përdorimi I të dhënave bëhet me urdhër të personave përgjegjës; 
                        <br/>
                        • Udhëzon dhe trajnon në mënyrë periodike stafin, pa përjashtim, për detyrimet që kanë, në përputhje me Ligjin për Mbrojtjen e të Dhënave Personale dhe rregulloret e brendshme për mbrojtjen e të dhënave, përfshirë edhe rregulloret për sigurinë e të dhënave; 
                        <br/>
                        • Ndalon hyrjen e personave të paautorizuar në mjediset e saj; 
                        <br/>
                        • Aksesi në të dhënat dhe programet, bëhet vetëm nga personat e autorizuar; 
                        <br/>
                        • Vënia në punë e pajisjeve të përpunimit të të dhënave bëhet vetëm me autorizim të Personi Pergjegjes dhe çdo mjet sigurohet me masa parandaluese ndaj vënies së autorizuar në punë; 
                        <br/>
                        • Regjistrimi dhe dokumentimi I modifikimeve, korrigjimeve, fshirjeve, transmetimeve, përditësimeve, etj. 
                        <br/>
                        • Masa të tjera sigurie në përputhje me Ligjin e Zbatueshëm. 
                        <br/><br/>
                        Për më shumë informacion lutemi të na kontaktoni në të dhënat e kontaktit të vendosura në seksionin Na Kontaktoni të kësaj Politike Privatësie. 
                    </Typography>
                </Box>

                {/* 7 */}
                <Box>
                    <Typography variant="h4" gutterBottom>{privacyLng[language]['policy7']}</Typography>
                    <Typography variant="body1">
                        Përhapja ose komunikimi i të dhënave personale kryhet në përputhje me qëllimin për të cilin janë grumbulluar këto të dhëna. Në përputhje me Ligjin e Zbatueshëm, çdo person ka të drejtë që të njihet me të dhënat personale të përpunuara nëpërmjet një kërkese me shkrim. 
                        <br/><br/>
                        Ju mund t’i drejtoheni në çdo kohë Fundway për të ushtruar të drejtat tuaja si subjekt të dhënash, si më poshtë: 
                        <br/><br/>
                        a) të drejtën për akses në të dhënat tuaja personale të mbledhura nëpërmjet kësaj faqeje web; 
                        <br/>
                        b) të drejtën për të kërkuar korrigjimin ose fshirjen e të dhënave tuaja personale; 
                        <br/>
                        c) të drejtën për të kundërshtuar vendimmarrjen automatike mbi të dhënat tuaja personale; 
                        <br/>
                        d) të drejtën për të kundërshtuar përpunimin e të dhënave tuaja personale; 
                        <br/>
                        e) të drejtën për tu ankuar në lidhje me përpunimin e të dhënave tuaja personale; 
                        <br/>
                        f) të drejtën për kompensimin e dëmit, në përputhje me Ligjin e Zbatueshëm. 
                        <br/><br/>
                        Kërkesa duhet të përmbajë të dhëna të mjaftueshme për të vërtetuar identitetin tuaj si kërkues. Fundway, brenda 30 ditëve nga data e marrjes së kërkesës, informon subjektin e të dhënave ose i shpjegon atij arsyet e mosdhënies së informacionit. 
                        <br/><br/>
                        Çdo kërkesë për ushtrimin e të drejtave tuaja si subjekt të dhënash duhet të dërgohet në adresën e personit të kontaktit të ngarkuar nga Fundway si vijon: 
                        <br/><br/>
                        Email: <a href="mailto:info@fundway.com">info@fundway.com</a> 
                    </Typography>
                </Box>

                {/* 8 */}
                <Box>
                    <Typography variant="h4" gutterBottom>{privacyLng[language]['policy8']}</Typography>
                    <Typography variant="body1">
                        Fundway mund të miratojë ndryshime të herëpashershme në këtë Politikë Privatësie. Përdorimi juaj i vazhdueshëm i faqes sonë web, konsiderohet si pranim i këtyre ndryshimeve, prandaj ju lutemi të kontrolloni rregullisht Politikën për përditësime. 
                    </Typography>
                </Box>

                {/* 9 */}
                <Box>
                    <Typography variant="h4" gutterBottom>{privacyLng[language]['policy9']}</Typography>
                    <Typography variant="body1">
                        Për çdo pyetje, paqartësi, ankesë apo kërkesë për të ushtruar të drejtat tuaja si subjekt të dhënash në përputhje me Ligjin e Zbatueshëm, lutemi të kontaktoni personin e kontaktit si vijon: 
                        <br/>
                        Email. <a href="mailto:info@fundway.com">info@fundway.com</a> 
                    </Typography>
                </Box>
            </Box>
        </Box>
    )

    const privacyEng = (
        <Box sx={{ color: '#002856' }}>
            <Box>
                <Typography variant="h2">{privacyLng[language]['policy']}</Typography>
                <Box component={'hr'} sx={{ background: '#707070' }} />
            </Box>
            <Box sx={{ display: 'grid', gridTemplateRows: '1fr', rowGap: '25px' }}>
                {/* 1. */}
                <Box marginTop={'50px'}>
                    <Typography variant="h4" gutterBottom>{privacyLng[language]['policy1']}</Typography>
                    <Typography variant="body1">
                        The purpose of this privacy policy (<Box component={'span'} sx={{ fontWeight: 'bold' }}>“Privacy Policy”</Box> or <Box component={'span'} sx={{ fontWeight: 'bold' }}>“Policy”</Box>) is to outline the organisational and technical procedures, as well as measures for the protection of personal data, including the security, storage, and management of personal data by the structures of the Albanian Branch of Fundway LLC. 
                    </Typography>
                </Box>

                {/* 1.1. */}
                <Box>
                    <Typography variant="h5" marginBottom={'20px'}>{privacyLng[language]['policy11']}</Typography>
                    <Typography variant="body1">
                        The Albanian Branch of Fundway LLC, registered in the Commercial Register with NUIS: M22316001A, with its business address at “Brigada VIII“, Pallati 8/1, Kati 4 Apartamenti 14, Tirana, Albania”, is engaged in various real estate activities, including but not limited to construction activities, economic activities in the real estate sector, design and construction of civil or industrial buildings, and management of project implementation of this nature for its own account and for third parties, involved in investing in urban, tourism, or residential area development projects, or investments of any nature, whether in the development of real estate in general, as well as other related activities. 
                    </Typography>
                </Box>

                {/* 1.2. */}
                <Box>
                    <Typography variant="h5" marginBottom={'20px'}>{privacyLng[language]['policy12']}</Typography>
                    <Typography variant="body1">
                        This Privacy Policy applies solely to personal data processed as a result of the use of the website (“webpage” or “website”) www.fundway.com by individuals who visit it. 
                        <br /><br />
                        This Policy does not apply to information collected: 
                        <br /><br />
                        • through any other form, or websites operated by third parties; 
                        <br />
                        • by any third party, including through sites or applications containing links that direct to this website. 
                    </Typography>
                </Box>

                {/* 1.3. */}
                <Box>
                    <Typography variant="h5" marginBottom={'20px'}>{privacyLng[language]['policy13']}</Typography>
                    <Typography variant="body1">
                        In Albania, there is extensive legislation for the protection of personal data, the implementation of which is fundamental to the conduct of Fundway’s commercial activities. 
                        <br /><br />
                        This Policy is based on the following national legal framework:
                        <br /><br />
                        • The Constitution of the Republic of Albania, Articles 15-58. 
                        <br />
                        • Law No. 9887, dated 10.03.2008, <Box component={'span'} sx={{ fontWeight: 'bold' }}>“On the Protection of Personal Data,”</Box> as amended (<Box component={'span'} sx={{ fontWeight: 'bold' }}>“Personal Data Protection Law”</Box>). 
                        <br />
                        • Orders, Guidelines, and Decisions issued by the Commissioner for the Right to Information and Protection of Personal Data (<Box component={'span'} sx={{ fontWeight: 'bold' }}>“Data Protection Commissioner”</Box> or <Box component={'span'} sx={{ fontWeight: 'bold' }}>“Commissioner”</Box>), accessible on the official website of this institution www.idp.al. 
                        <br />
                        • Other applicable legal and sub-legal acts relevant to Fundway’s business activities, hereinafter referred to as the “Applicable Law.” 
                        <br /><br />
                        The purpose of the Privacy Policy is to set forth the general principles and organizational and technical measures for the protection, storage, security, and management of personal data that are collected and processed through the website as follows www.fundway.com. 
                        <br /><br />
                        It applies to all data processed by Fundway through this website www.fundway.com in accordance with the Personal Data Protection Law. Any processing of your data collected through this website is conducted in compliance with the <Box component={'span'} sx={{ fontWeight: 'bold' }}>Applicable Law. </Box>
                        <br /><br />
                        If you have any questions, concerns, or seek information regarding your personal data collected through this website, please contact us through one of the contact methods provided in the “Contact Us” section of this Privacy Policy. 
                    </Typography>
                </Box>

                {/* 2. */}
                <Box>
                    <Typography variant="h4" gutterBottom>{privacyLng[language]['policy2']}</Typography>
                    <Typography variant="body1">
                        The following section details the specific types of personal information we collect through this website, the reasons for collecting this data, and how we use it to enhance your experience and provide our services. 
                    </Typography>
                </Box>

                {/* 2.1 */}
                <Box>
                    <Typography variant="h5" marginBottom={'20px'}>{privacyLng[language]['policy21']}</Typography>
                    <Typography variant="body1">
                        We use your personal data to help us respond to your inquiries or expressions of interest and to ensure that we assist you in finding the right property that fits your needs. 
                        <br /><br />
                        In compliance with the Applicable Law, personal data is processed only for one of the following purposes: 
                        <br /><br />
                        <Box component={'span'} sx={{ fontWeight: 'bold' }}>To fulfill our contractual obligations in accordance with the terms and conditions of the website</Box>
                        <br /><br />
                        This includes instances where we need to address your requests, questions, or expressions of interest regarding the real estate properties displayed on our website. In order to respond to your inquiries, we will use the contact information you provided through the contact form available on the website in section Contact Us as listed in section 2 of this Policy. 
                        <br /><br />
                        <Box component={'span'} sx={{ fontWeight: 'bold' }}>For the fulfilment of a legal obligation of Fundway. </Box>
                        <br /><br />
                        In order to address your complaints or requests for information regarding the personal data we process, in compliance with the Personal Data Protection Law. 
                        <br /><br />
                        <Box component={'span'} sx={{ fontWeight: 'bold' }}>For pursuing the legitimate interests of Fundway as a result of the company's business operations. </Box>
                        <br /><br />
                        To enhance your experience while using our website, Fundway collects and uses your personal information to: 
                        <br /><br />
                        • understand your interests and send you tailored content, such as notifications about events, discounts, and offers, in the event that we have obtained your express consent for this purpose in accordance with Applicable Law. 
                        <br />
                        • assess your interests and adjust our marketing accordingly, in the event that we have obtained your express consent for this purpose in accordance with Applicable Law. 
                        <br />
                        • display advertisements or send promotions via e-mail, and we may cooperate with third parties to facilitate this process, in the event that we have obtained your express consent for this purpose in accordance with Applicable Law. 
                        <br /> <br />
                        You may withdraw your consent to receive the above communications at any time by simply clicking the "Unsubscribe" button located in any email that has been sent to you. 
                    </Typography>
                </Box>

                {/* 2.2 */}
                <Box>
                    <Typography variant="h5" marginBottom={'20px'}>{privacyLng[language]['policy22']}</Typography>
                </Box>

                {/* 2.2.1 */}
                <Box>
                    <Typography variant="h5" marginBottom={'20px'}>{privacyLng[language]['policy221']}</Typography>
                    <Typography variant="body1">
                        We may collect your personal data when you send us a request, inquiry, or expression of interest via our website regarding one of our properties. In this context, we collect the following personal data: 
                        <br /> <br />
                        • Name and surname, 
                        <br />
                        • Personal number; 
                        <br />
                        • Place of residence; 
                        <br />
                        • Email address, 
                        <br />
                        • Your address, 
                        <br />
                        • Postal Code, 
                        <br />
                        • Phone number, 
                        <br />
                        • Details of your request, in line with our legitimate business interests.. 
                        <br /><br />
                        In certain cases, your inquiry may relate to a specific service request, such as raising a question about a particular property. 
                        <br /><br />
                        For such requests, we need to use your personal data to assist us in responding to your inquiry and fulfilling our contractual obligations, following the terms and conditions of the requested service. 
                    </Typography>
                </Box>


                {/* 2.2.2 */}
                <Box>
                    <Typography variant="h5" marginBottom={'20px'}>{privacyLng[language]['policy222']}</Typography>
                    <Typography variant="body1">
                        Fundway continuously takes the necessary steps to ensure your satisfaction with the use of our website. 
                        <br /><br />
                        To help us respond to your comments or complaints, we collect the following personal information: 
                        <br /><br />
                        • Name and Surname, 
                        <br />
                        • Place of residence, 
                        <br />
                        • Email address, 
                        <br />
                        • Your address, 
                        <br />
                        • Phone number, 
                        <br />
                        • Details of your request, in line with our legitimate business interests. 
                    </Typography>
                </Box>


                {/* 3 */}
                <Box>
                    <Typography variant="h4" gutterBottom>{privacyLng[language]['policy3']}</Typography>
                    <Typography variant="body1">
                        The recipients of your data collected through the website are solely those individuals at Fundway who are authorized and trained to process such data in accordance with legal and regulatory requirements. 
                        <br /><br />
                        In addition to these individuals, contractors (primarily Information Technology and Communication service providers, as well as back-office services) may also have access to your data to the extent necessary to perform the respective service. 
                        <br /><br />
                        All processors, within the scope of providing their respective services, are obligated to process the data based on the agreement they have entered into with Fundway for data processing and to maintain the confidentiality of your data. 
                        <br /><br />
                        If there is a legal or regulatory obligation from public authorities, these authorities may also be recipients of your data. 
                    </Typography>

                </Box>

                {/* 4 */}
                <Box>
                    <Typography variant="h4" gutterBottom>{privacyLng[language]['policy4']}</Typography>
                    <Typography variant="body1">
                        The transfer of data to third countries (outside the European Economic Area - EEA) can only occur when necessary, if required by law, or if you have provided your explicit consent. The transfer of your data to a third country can only take place after obtaining authorization from the Commissioner, in accordance with the Applicable Law. 
                    </Typography>

                </Box>

                {/* 5 */}
                <Box>
                    <Typography variant="h4" gutterBottom>{privacyLng[language]['policy5']}</Typography>
                    <Typography variant="body1">
                        We process your personal data for as long as necessary and reasonable to respond to your requests, inquiries, complaints, or expressions of interest submitted through this website. If your expression of interest in a real estate property is not followed by the signing of a reservation agreement for the right to purchase the said property, we will delete your data immediately, unless you have given consent for them to be stored in our database. Data archiving is subject to time limitations. 
                        <br /><br />
                        For more information regarding the storage time of your personal data, please contact us using the contact details provided in section Contact Us. 
                    </Typography>
                </Box>

                {/* 6 */}
                <Box>
                    <Typography variant="h4" gutterBottom>{privacyLng[language]['policy6']}</Typography>
                    <Typography variant="body1">
                        Fundway has implemented appropriate organizational and technical measures to protect your personal data from unlawful or accidental destruction, accidental loss, unauthorized access or dissemination, particularly when data processing is conducted over a network, and from any other form of unlawful processing. 
                        <br /><br />
                        Fundway takes the following specific security measures: 
                        <br /><br />
                        • Defining roles and responsible individuals within the company for the use of data; 
                        <br />
                        • Data usage is conducted under the orders of responsible individuals; 
                        <br />
                        • Regularly instructing and training staff, without exception, on their obligations in accordance with the Personal Data Protection Law and internal data protection regulations, including data security regulations; 
                        <br />
                        • Prohibiting unauthorized individuals from entering its premises; 
                        <br />
                        • Access to data and programs is granted only to authorized personnel; 
                        <br />
                        • Activation of data processing equipment is only done with authorization from the Responsible Person, and each device is secured with preventive measures against unauthorized activation; 
                        <br />
                        • Recording and documenting modifications, corrections, deletions, transmissions, updates, etc.; 
                        <br />
                        • Other security measures in compliance with the Applicable Law. 
                        <br /><br />
                        For more information, please contact us using the contact details provided in section Contact Us of this Privacy Policy. 
                    </Typography>
                </Box>

                {/* 7 */}
                <Box>
                    <Typography variant="h4" gutterBottom>{privacyLng[language]['policy7']}</Typography>
                    <Typography variant="body1">
                        The dissemination or communication of personal data is carried out in accordance with the purpose for which these data were collected. In accordance with the Applicable Law, every individual has the right to access their personal data processed through a written request. 
                        <br /><br />
                        You may contact Fundway at any time to exercise your rights as a data subject, as follows:
                        <br /><br />
                         a) the right to access your personal data collected through this website;
                         <br />
                          b) the right to request the correction or deletion of your personal data; 
                          <br />
                          c) the right to object to automatic decision-making on your personal data;
                          <br />
                          d) the right to object to the processing of your personal data; 
                          <br />
                          e) the right to complain about the processing of your personal data; 
                          <br />
                          f) the right to compensation for damage, in accordance with the Applicable Law 
                          <br /><br />
                          The request must contain sufficient data to prove your identity as a requester. Fundway, within 30 days from the date of receipt of the request, informs the data subject or explains to him the reasons for not providing the information. 
                          <br /><br />
                          Any request to exercise your rights as a data subject should be sent to the contact person assigned by Fundway as follows: Email: <a href="mailto:info@fundway.com ">info@fundway.com </a>
                    </Typography>
                </Box>

                {/* 8 */}
                <Box>
                    <Typography variant="h4" gutterBottom>{privacyLng[language]['policy8']}</Typography>
                    <Typography variant="body1">
                        Fundway may adopt changes to this Privacy Policy from time to time. Your continued use of our website constitutes acceptance of these changes, so please check the Policy regularly for updates. 
                    </Typography>
                </Box>

                {/* 9 */}
                <Box>
                    <Typography variant="h4" gutterBottom>{privacyLng[language]['policy9']}</Typography>
                    <Typography variant="body1">
                        For any questions, concerns, complaints, or requests to exercise your rights as a data subject in accordance with the Applicable Law, please contact the contact person as follows: <br />Email: <a href="mailto:info@fundway.com ">info@fundway.com </a>
                    </Typography>
                </Box>
            </Box>
        </Box>
    )

    return (
        <Box sx={{ paddingY: '50px', width: { md: '900px' }, marginX: 'auto' }}>

            {language === 'alb' ? privacyAlb : null}

            {language === 'eng' ? privacyEng : null}

        </Box>
    )
}