import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
// import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
// import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button } from '@mui/material';
import { lng } from '../utils/language';
import { useSelector } from 'react-redux';

const inputStyle = {
    fieldset: { borderRadius: '0', borderWidth: '2px', },
    input: { textAlign: 'center' }
}

const initialState = {
    amount: '',
    downPayment: '',
    interestRate: 3.7,
    loanTerm: '',
    totalInteres: ''
};

// const slider = {
//     color: '#11294D',
//     width: '90%', 
//     position: 'absolute', 
//     padding: 0, 
//     bottom: '-22px',
//     '.MuiSlider-mark':  {
//         backgroundColor: '#FFF'
//     }
// }

const inputContained = {
    borderRadius: 0,
    '&.MuiButton-contained': {
        backgroundColor: '#002856',
    },
    '&.MuiButton-outlined': {
        color: '#002856 !important',
        border: 'solid 1px #002856 !important'
    }
}

export default function MorgageCalculator() {
    const language = useSelector((state) => state.language.language);
    const [mortgage, setMortgage] = useState(initialState)

    const [calcData, setCalcData] = useState({
        downPayment: 20,
        monthlyPayment: '',
        totalPayment: '',
        totalInteres: ''
    })

    const inputs = {
        loanPayment: (e) => {
            const value = e.target.value; setMortgage({ ...mortgage, downPayment: value}); calculator();
        },
        loanTerm: (value) => {
             setMortgage({...mortgage, loanTerm: value }); calculator();
        },
        downPaymentInput: (e) => {
            const value = e.target.value; 
            setMortgage({...mortgage, downPayment: calculateValue(mortgage.amount, value)}); 
            // handleSubChange(value);
            calculator(value);
        },
        propertyValue: (e) => {
            const value = e.target.value; 
            setMortgage({...mortgage, amount: value }); calculator();
        },
        interesChange: (add) => {
            if (add) {
                if (mortgage.interestRate === 100 ||  mortgage.interestRate === '100') {
                   return 
                }else{
                    const value =parseFloat(mortgage.interestRate)+0.1;
                    setMortgage({...mortgage,interestRate: value.toFixed(1)}); calculator();
                }
            }else{
                if (mortgage.interestRate === 3.7 ||  mortgage.interestRate === '3.7') {
                    return
                }else{
                    const value =parseFloat(mortgage.interestRate)-0.1;
                    setMortgage({...mortgage, interestRate: value.toFixed(1)}); calculator();
                }
            }
        }
    }

    const calculator = (innerValue) => {
        const loanAmount = parseFloat(mortgage.amount) - (mortgage.downPayment ? parseFloat(mortgage.downPayment) : 0);
        const r = parseFloat(mortgage.interestRate) / 100 / 12;
        const n = parseFloat(mortgage.loanTerm) * 12;

        const numerator = loanAmount * r * Math.pow(1 + r, n);
        const denumerator = Math.pow(1 + r, n) - 1;
        const monthlyPaymentData = (numerator / denumerator).toFixed(2);
        const totalPayableData = (monthlyPaymentData * n).toFixed(2);
        const totalInterests = (totalPayableData - loanAmount).toFixed(2);
        if (innerValue) {
            return setCalcData({
                ...calcData, 
                downPayment: innerValue,
                monthlyPayment: monthlyPaymentData, 
                totalPayment: totalPayableData,
                totalInteres: parseInt(mortgage.amount)+parseInt(totalInterests)
            });            
        }else{
            return setCalcData({
                ...calcData, 
                monthlyPayment: monthlyPaymentData, 
                totalPayment: totalPayableData,
                totalInteres: parseInt(mortgage.amount)+parseInt(totalInterests)
            });   
        }
    }

    useEffect(() => {
        if (mortgage) {
            setMortgage({
                ...mortgage,
                downPayment: (mortgage.amount/100) * calcData.downPayment
            });
        }
        // eslint-disable-next-line
    }, [])

    const calculateValue = (value, percentage) => {
        if (value) {
            const newAmount = (value/100) * percentage ;
            return newAmount;
        }else return
    }

    const handleLowerInteres = () => {
        inputs.interesChange(false);
    }

    const handleHigherInteres = () =>{
        inputs.interesChange(true);
    }

    return (
        <Box sx={{color: '#11294D'}}>

            <Box sx={{ width: { sm: '500px', }, marginX: 'auto', display: 'grid', gridGap: '20px', paddingX: '40px', gridTemplateColumns: '1fr'}}>
               

                <Box>
                    <Typography variant="body1" sx={{ fontWeight: 700, color: '#707070', fontSize: '18px' }}>
                        {lng[language]['calculateOffer']}
                    </Typography>
                </Box>

                <Box sx={{display: 'grid', gridTemplateColumns: '1fr', gridGap: '20px' }}>

                    <Box sx={{display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '20px', alignContent: 'center'}}>
                        <Box>
                            <InputLabel sx={{marginBottom: '5px', fontWeight: 500, fontSize: '15px'}}>
                                {lng[language]['priceTxt']}
                            </InputLabel>
                            <TextField 
                                fullWidth
                                sx={inputStyle}
                                value={`${mortgage.amount}`}
                                onChange={(e) => inputs.propertyValue(e)}
                            />
                        </Box>

                        <Box>
                            <InputLabel sx={{marginBottom: '5px', fontWeight: 500, fontSize: '15px'}}>
                                {lng[language]['interest']}
                            </InputLabel>
                            <Box
                                sx={{
                                    border: 'solid 2px #c4c4c4',
                                    textAlign: 'center',
                                    height: '56px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <Box sx={{display: 'flex', justifyContent: 'space-between', width: '80%'}}> 
                                    <RemoveIcon onClick={() => handleLowerInteres()} sx={{color: mortgage.interestRate === 3.7 ||  mortgage.interestRate === '3.7' ? 'gray' : '#11294D', cursor: 'pointer'}} />  
                                        <Typography>{`${mortgage.interestRate}%`}</Typography> 
                                    <AddIcon onClick={() => handleHigherInteres()} sx={{color: mortgage.interestRate === 100 ||  mortgage.interestRate === '100' ? 'gray' : '#11294D', cursor: 'pointer'}}/> 
                                </Box>
                            </Box>
                            
                        </Box>
                    </Box>

                    <Box>
                        <InputLabel sx={{ marginBottom: '5px', fontWeight: 500, fontSize: '15px' }}>
                            {lng[language]['priPayment']}
                        </InputLabel>
                        <Box>
                            <Typography sx={{ fontSize: '14px' }}>{lng[language]['prepaymentValue']}: {`${Math.round(mortgage.downPayment)}€`} / {`${calcData.downPayment}%`}</Typography>
                            <Slider value={calcData.downPayment} sx={{'.MuiSlider-track': {color: '#11294D'}, '.MuiSlider-thumb': {color: '#11294D'}}} valueLabelDisplay="auto" size='small' shiftStep={20} step={10} marks min={20} max={80} onChange={(e) => inputs.downPaymentInput(e)} />
                        </Box>
                    </Box>

                    <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <InputLabel sx={{ marginBottom: '5px', fontWeight: 500, fontSize: '15px' }}>
                                {lng[language]['period']}: {`${mortgage.loanTerm}`} 
                            </InputLabel>
                            <InputLabel sx={{ marginBottom: '5px', fontWeight: 500, fontSize: '15px' }}>
                                {lng[language]['inYears']}
                            </InputLabel>
                        </Box>
                        <Box>
                            <Box>
                            <Box
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: {md: 'auto auto auto auto auto', xs: 'auto auto auto'},
                                    columnGap: {md: '20px', xs: '10px'},
                                    marginTop: '10px',
                                    rowGap: {md: 'none', xs: '10px'}
                                }}
                            >
                                <Button 
                                // eslint-disable-next-line
                                variant={mortgage.loanTerm == '10' ? 'contained' : 'outlined'} sx={inputContained} onClick={(e) => inputs.loanTerm(10)} disabled={mortgage.amount ? false : true}>
                                    10
                                </Button>
                                <Button 
                                // eslint-disable-next-line
                                variant={mortgage.loanTerm == '15' ? 'contained' : 'outlined'} sx={inputContained} onClick={(e) => inputs.loanTerm(15)} disabled={mortgage.amount ? false : true}>
                                    15
                                </Button>
                                <Button 
                                // eslint-disable-next-line
                                variant={mortgage.loanTerm == '20' ? 'contained' : 'outlined'} sx={inputContained} onClick={(e) => inputs.loanTerm(20)} disabled={mortgage.amount ? false : true}>
                                    20
                                </Button>
                                <Button 
                                // eslint-disable-next-line 
                                variant={mortgage.loanTerm == '25' ? 'contained' : 'outlined'} sx={inputContained} onClick={(e) => inputs.loanTerm(25)} disabled={mortgage.amount ? false : true}>
                                    25
                                </Button>
                                
                                <Button
                                // eslint-disable-next-line 
                                variant={mortgage.loanTerm == '30' ? 'contained' : 'outlined'} sx={inputContained} onClick={(e) => inputs.loanTerm(30)} disabled={mortgage.amount ? false : true}>
                                    30
                                </Button>
                            </Box>
                        </Box>
                        </Box>
                    </Box>

                   

                </Box>

                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    gridGap: '20px'
                }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: 'solid 1px #c4c4c4', paddingBottom: 1 }}>
                        <Typography variant="body1">{lng[language]['monthlyInters']}</Typography>
                        <Typography variant="body1">{calcData.monthlyPayment ? `${calcData.monthlyPayment}€` : 'NaN'}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: 'solid 1px #c4c4c4', paddingBottom: 1 }}>
                        <Typography variant="body1">{lng[language]['totalPayment']}</Typography>
                        <Typography variant="body1">{calcData.totalInteres ? `${calcData.totalInteres}€` : 'NaN'}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="body1">{lng[language]['totalInteres']}</Typography>
                        <Typography variant="body1">{calcData.totalPayment ? `${calcData.totalPayment}€` : 'NaN'}</Typography>
                    </Box>
                </Box>

                {
                    !mortgage.amount &&
                    <Typography sx={{
                        border: 'solid 1px #11294D',
                        padding: '20px',
                        color: 'red'
                    }}>
                        {lng[language]['calculatorWarning']}
                    </Typography> 
                   }

            </Box>

            

        </Box>
    )
}