import { api } from './config';

//GET 1 SPECIFIC PIECE OF DATA
    const get = (url, id) => {
        return api.get(`${url}/${id}`)
    }
//GET ALL DATA
    const getAll = (url) => {
        return api.get(url);
    }
//CREATE
    const create = (url, data) => {
        return api.post(url, data);
    }
//UPDATE
    const update = (url, id, data) => {
        return api.put(`${url}/${id}`, data);
    }
//DELETE
    const deleteOne = (url, id) => {
        return api.post(`${url}/${id}`)
    }
//DELETE ALL
    const deleteAll = (url) => {
        return api.delete(url);
    }

    const FetchData = {
        get,
        getAll,
        create,
        update,
        deleteOne,
        deleteAll
    }

export default FetchData;