import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FetchData from '../../api/FetchData';
import { useNavigate, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Loader from '../../components/loader/Loader';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import { api } from '../../api/config';
import { triggerBase64Download } from 'common-base64-downloader-react';
import { lng } from '../../utils/language';
import { useSelector } from 'react-redux';
import SimpleMortgageCalculator from '../../components/card/SimpleMortgageCalculator';
import { AnimatePresence, motion } from 'framer-motion';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Countries from './Countries.json';
import { financingType } from '../../utils/utils';

const info = {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '10px',
    borderBottom: 'solid 1px #000',
    justifyContent: 'space-between',
}

const inputStyle = {
    '.MuiInputBase-root': {
        borderColor: '#7f8a94',
    },
    fieldset: {
        borderRadius: 0,
        borderWidth: '2px'
    }
}

const defaultAlert = {
    open: false,
    severity: '',
    msg: ''
}

export default function AvailabilityDetails() {
    const navigate = useNavigate();
    const id = useParams();
    const language = useSelector((state) => state.language.language);
    const [apartment, setApartment] = useState();
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = React.useState(defaultAlert);
    useEffect(() => {
        FetchData.create('/apartments/getSpecificApartemnt', { apartmentId: id.id })
            .then(res => {
                setApartment(res?.data?.data[0]);
                setLoading(false)
            })
            .catch(err => {
                console.log(err);
            })
        window.scrollTo(0, 0);
    }, [id.id])

    const handleDownload = (url, filename) => {
        setLoading(true)
        api.post('/decoder/decodePdf', { filename })
            .then(res => {
                triggerBase64Download(`data:application/pdf;base64,${res.data}`, filename.replace('.pdf', ''));
                setOpenModal(false);
                setLoading(false);
            })
    }

    const [openModal, setOpenModal] = useState(false)

    const [userData, setUserData] = useState({ fullName: '', lastName: '', country: '', prefix: '', mob: '', email: '' })

    const [userDataError, setUserDataError] = useState({ fullNameError: false, lastNameError: false, countryError: false, prefixError: false, mobError: false, emailError: false })

    const inputs = {
        nameInput: (e) => {
            var value = e.target.value;
            if (value !== "" && !value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g)) {
                setUserDataError({ ...userDataError, fullnameError: true })
            } else if (value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g) || value !== "") {
                setUserDataError({ ...userDataError, fullnameError: false })
            }
            setUserData({ ...userData, fullName: value })
        },
        lastNameInput: (e) => {
            var value = e.target.value;
            if (value !== "" && !value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g)) {
                setUserDataError({ ...userDataError, lastNameError: true })
            } else if (value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g) || value !== "") {
                setUserDataError({ ...userDataError, lastNameError: false })
            }
            setUserData({ ...userData, lastName: value })
        },
        countryInput: (e) => {
            var value = e.target.value;
            if (value !== "" && !value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g)) {
                setUserDataError({ ...userDataError, countryError: true })
            } else if (value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g) || value !== "") {
                setUserDataError({ ...userDataError, countryError: false })
            }
            setUserData({ ...userData, country: value })
        },
        prefixInput: (e) => {
            var value = e.target.value;
            if (value === '') {
                setUserDataError({ ...userDataError, prefixError: true })
            }
            setUserData({ ...userData, prefix: value })
        },
        mobInput: (e) => {
            var value = e.target.value;
            const regex = /^[0-9\s]*$/;
            if (value !== "" && !value.match(regex)) {
                setUserDataError({ ...userDataError, mobError: true })
            } else if (value.match(regex) || value !== "") {
                setUserDataError({ ...userDataError, mobError: false })
            }
            setUserData({ ...userData, mob: value })
        },
        emailInput: (e) => {
            var value = e.target.value;
            if (value !== "" && !value.match(/^[\w\-.]+@([\w-]+\.)+[\w-]{2,}/)) {
                setUserDataError({ ...userDataError, emailError: true })
            } else if (value.match(/^[\w\-.]+@([\w-]+\.)+[\w-]{2,}/) || value === "") {
                setUserDataError({ ...userDataError, emailError: false })
            }
            setUserData({ ...userData, email: value })
        }
    }

    const handleSubmit = (e, path, filename) => {
        e.preventDefault();
        // ERRORS
        const validation = Object.values(userDataError);
        const confirmation = validation.includes(true);
        if (!confirmation) {
            FetchData.create('/email/getDocument', {
                userData,
            })
                .then(res => {
                    if (res.data.success) {
                        handleDownload(path, filename);
                        setUserData({
                            fullName: '',
                            email: ''
                        });
                        setUserDataError({
                            fullnameError: false,
                            emailError: false
                        });
                        setAlert({
                            open: true,
                            msg: res.data.msg,
                            severity: res.data.severity,
                        });
                    } else {
                        setAlert({
                            open: true,
                            msg: res.data.msg,
                            severity: res.data.severity,
                        });
                    }

                })
        } else {

        }
    }

    const handleClose = (event, reason) => {
        setTimeout(() => {
            setAlert({ ...alert, open: false });
        }, 5000)
    };

    const [planDetailsModal, setPlanDetailsModal] = useState(false);

    const [openSelfPayingPlan, setOpenSelfPayingPlan] = useState(false);
    const [openCalculator, setOpenCalculator] = useState(false);

    return (
        <Box color={'#002856'}>
            {
                (loading === false && apartment)
                    ?
                    <Box>
                        <Box>
                            {
                                apartment.typology === 'DUPLEX' ?
                                    <Typography variant="h3" sx={{ fontSize: '30px', fontWeight: 'light' }} textAlign={'center'} marginTop={5} marginBottom={2}>
                                        {
                                            apartment.apartmentId === '1650'
                                                ?
                                                language === 'alb' ? 'DUPLEX 1 Building 1 B2'.replace('Building', 'Godina') : 'DUPLEX 1 Building 1 B2'
                                                :
                                                ''
                                        }
                                        {
                                            apartment.apartmentId === '1651'
                                                ?
                                                language === 'alb' ? 'DUPLEX 2 Building 2 B2'.replace('Building', 'Godina') : 'DUPLEX 2 Building 2 B2'
                                                :
                                                ''
                                        }
                                        {
                                            apartment.apartmentId === '1652'
                                                ?
                                                language === 'alb' ? 'DUPLEX 3 Building 3 B2'.replace('Building', 'Godina') : 'DUPLEX 3 Building 3 B2'
                                                :
                                                ''
                                        }
                                    </Typography>
                                    :
                                    <Typography variant="h3" sx={{ fontSize: '30px', fontWeight: 'light' }} textAlign={'center'} marginTop={5} marginBottom={2}>{language === 'eng' ? apartment.apartmentName.replace('Apartamenti', 'Apartment') : apartment.apartmentName} {language === 'eng' ? apartment.buildingName.replace('Godina', 'Building') : apartment.buildingName} {apartment.objectName}</Typography>
                            }
                            <Box sx={{ width: { md: '1024px', sm: '80%', xs: '90%' }, height: { md: 500, xs: 'auto' }, marginX: 'auto' }}>
                                <Box sx={{ height: 1, width: 1, display: 'flex', justifyContent: 'center' }}>
                                    <Box component={'img'} src={`https://www.fundway.com/images/apartments/${apartment?.imageName}`} sx={{ height: 1, objectFit: 'cover', width: { md: 'unset', xs: '100%' } }} />
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginY: 5 }}>
                            <Button onClick={() => setOpenModal(true)} sx={{ color: '#c3c3c3', border: 'solid 1px #c3c3c3', borderRadius: 0, padding: '15px 15px', marginRight: '10px' }}>
                                {lng[language]['downloadPlan']}
                            </Button>
                            {
                                apartment.status === 'free' &&
                                <Button onClick={() => setPlanDetailsModal(true)} sx={{ color: '#FFF', backgroundColor: '#53626f', border: 'solid 1px #53626f', borderRadius: 0, '&:hover': { color: '#53626f', backgroundColor: '#FFF' }, marginLeft: '10px' }}>{lng[language]['paymentPlan'].toUpperCase()}</Button>
                            }
                        </Box>

                        <Box sx={{ width: { md: '800px', sm: '600px', xs: '90%' }, rowGap: '10px', marginX: 'auto', display: 'grid', gridTemplateRows: 'auto auto auto auto auto auto' }}>
                            <Box sx={info}>
                                <Box display={'grid'} alignItems={'center'} gridTemplateColumns={'auto auto'} columnGap={'10px'} paddingLeft={'10px'} width={'max-content'}>
                                    <Box component={'img'} src='https://fundway.com/images/2024/stair.svg' sx={{ width: { sm: '35px', xs: '30px' }, height: '100%', objectFit: 'contain' }} />
                                    <Typography display={'grid'} alignItems={'center'} gridTemplateColumns={'auto auto'} columnGap={'10px'} paddingLeft={'10px'} width={'max-content'} sx={{width: {md: 'unset', xs: '100%'}}} fontWeight={500}>{lng[language]['flootTxt'].toUpperCase()}</Typography>
                                </Box>
                                <Typography fontWeight={500}>+{apartment.floorNo}</Typography>
                            </Box>
                            <Box sx={info}>
                                <Box display={'grid'} alignItems={'center'} gridTemplateColumns={'auto auto'} columnGap={'10px'} paddingLeft={'10px'} width={'max-content'}>
                                    <Box component={'img'} src='https://fundway.com/images/2024/double-bed.svg' sx={{ width: { sm: '35px', xs: '30px' }, height: '100%', objectFit: 'contain' }} />
                                    <Typography display={'grid'} alignItems={'center'} gridTemplateColumns={'auto auto'} columnGap={'10px'} paddingLeft={'10px'} width={'max-content'}  sx={{width: {md: 'unset', xs: '100%'}}}fontWeight={500}>{lng[language]['bedsTxt'].toUpperCase()}</Typography>
                                </Box>
                                <Typography fontWeight={500}>{apartment.typology}</Typography>
                            </Box>
                            <Box sx={info}>
                                <Box display={'grid'} alignItems={'center'} gridTemplateColumns={'auto auto'} columnGap={'10px'} paddingLeft={'10px'} width={'max-content'}>
                                    <Box component={'img'} src='https://fundway.com/images/2024/terrace.svg' sx={{ width: { sm: '35px', xs: '30px' }, height: '100%', objectFit: 'contain' }} />
                                    <Typography display={'grid'} alignItems={'center'} gridTemplateColumns={'auto auto'} columnGap={'10px'} paddingLeft={'10px'} width={'max-content'}  sx={{width: {md: 'unset', xs: '100%'}}}fontWeight={500}>{lng[language]['terraceTxt'].toUpperCase()}</Typography>
                                </Box>
                                <Typography fontWeight={500}>{apartment.surfaceTerrace ? apartment.surfaceTerrace : 0} M²</Typography>
                            </Box>
                            <Box sx={info}>
                                <Box display={'grid'} alignItems={'center'} gridTemplateColumns={'auto auto'} columnGap={'10px'} paddingLeft={'10px'} width={'max-content'}>
                                    <Box component={'img'} src='https://fundway.com/images/2024/scalability.svg' sx={{ width: { sm: '35px', xs: '30px' }, height: '100%', objectFit: 'contain' }} />
                                    <Typography display={'grid'} alignItems={'center'} gridTemplateColumns={'auto auto'} columnGap={'10px'} paddingLeft={'10px'} width={'max-content'}  sx={{width: {md: 'unset', xs: '100%'}}}fontWeight={500}>{lng[language]['apartmentSize'].toUpperCase()}</Typography>
                                </Box>
                                <Typography fontWeight={500}>{apartment.surfaceGrossWithoutTerrace} M²</Typography>
                            </Box>

                            <Box sx={info}>
                                <Box display={'grid'} alignItems={'center'} gridTemplateColumns={'auto auto'} columnGap={'10px'} paddingLeft={'10px'} width={'max-content'}>
                                    <Box component={'img'} src='https://fundway.com/images/2024/scalability.svg' sx={{ width: { sm: '35px', xs: '30px' }, height: '100%', objectFit: 'contain' }} />
                                    <Typography display={'grid'} alignItems={'center'} gridTemplateColumns={'auto auto'} columnGap={'10px'} paddingLeft={'10px'} width={'max-content'}  sx={{width: {md: 'unset', xs: '100%'}}}fontWeight={500}>{lng[language]['areaTxt'].toUpperCase()}</Typography>
                                </Box>
                                <Typography fontWeight={500}>{apartment.surfaceGrossWithTerrace} M²</Typography>
                            </Box>
                            {
                                apartment.status === 'free' &&
                                <>
                                    <Box sx={info}>
                                        <Box display={'grid'} alignItems={'center'} gridTemplateColumns={'auto auto'} columnGap={'10px'} paddingLeft={'10px'} width={'max-content'}>
                                            <Box component={'img'} src='https://fundway.com/images/2024/parking.svg' sx={{ width: { sm: '27px', xs: '24px' }, height: '100%', objectFit: 'contain' }} />
                                            <Typography display={'grid'} marginLeft={'10px'} alignItems={'center'} gridTemplateColumns={'auto auto'} columnGap={'10px'} paddingLeft={'10px'} width={'max-content'} sx={{width: {md: 'unset', xs: '100%'}}} fontWeight={500}>PARKING</Typography>
                                        </Box>
                                        <Typography fontWeight={500}>{new Intl.NumberFormat('us-Us', { style: 'currency', currency: 'EUR' }).format(apartment.priceParking)}</Typography>
                                    </Box>
                                    <Box sx={info}>
                                        <Box display={'grid'} alignItems={'center'} gridTemplateColumns={'auto auto'} columnGap={'10px'} paddingLeft={'10px'} width={'max-content'}>
                                            <Box component={'img'} src='https://fundway.com/images/2024/money.svg' sx={{ width: { sm: '35px', xs: '30px' }, height: '100%', objectFit: 'contain' }} />
                                            <Typography display={'grid'} alignItems={'center'} gridTemplateColumns={'auto auto'} columnGap={'10px'} paddingLeft={'10px'} width={'max-content'} sx={{width: {md: 'unset', xs: '100%'}}} fontWeight={500}>{lng[language]['totalPrice'].toUpperCase()}</Typography>
                                        </Box>
                                        <Typography fontWeight={500}>{new Intl.NumberFormat('us-Us', { style: 'currency', currency: 'EUR' }).format(Math.ceil((parseFloat(apartment.priceTotal) + parseInt(apartment.priceParking)) / 100) * 100)}</Typography>
                                    </Box>
                                </>
                            }
                        </Box>
                        {
                            openModal &&
                            <Box sx={{ position: 'fixed', top: 0, left: 0, background: 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) )', width: '100%', height: '100%', zIndex: 9990, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Box sx={{ background: '#FFF', width: { md: 500, sm: '80%', xs: '90%' }, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
                                    <Box onClick={() => setOpenModal(false)} sx={{ marginLeft: 'auto', width: 'min-content', marginRight: '10px', marginTop: '10px' }}>
                                        <CloseIcon />
                                    </Box>
                                    <Box sx={{ display: 'grid', gridTemplateRows: '1fr 1fr auto', gridGap: '20px' }}>
                                        <Box>
                                            <InputLabel sx={{ fontWeight: '600', marginBottom: '10px' }}>{lng[language]['rezerveDetailFilterName']}</InputLabel>
                                            <TextField
                                                fullWidth
                                                type='text'
                                                required
                                                value={userData.fullName}
                                                error={userDataError.fullnameError}
                                                placeholder={lng[language]['placeholderFullName']}
                                                onChange={(e) => inputs.nameInput(e)}
                                                sx={inputStyle}
                                            />
                                        </Box>

                                        <Box>
                                            <InputLabel sx={{ fontWeight: '600', marginBottom: '10px' }}>{lng[language]['rezerveDetailFilterLastname']}</InputLabel>
                                            <TextField
                                                fullWidth
                                                type='text'
                                                required
                                                value={userData.fullName}
                                                error={userDataError.fullnameError}
                                                placeholder={lng[language]['placeholderFullName']}
                                                onChange={(e) => inputs.nameInput(e)}
                                                sx={inputStyle}
                                            />
                                        </Box>

                                        <Box>
                                            <InputLabel sx={{ fontWeight: '600', marginBottom: '10px' }}>{lng[language]['rezerveDetailFilterCountry']}</InputLabel>
                                            <TextField
                                                fullWidth
                                                type='text'
                                                required
                                                value={userData.fullName}
                                                error={userDataError.fullnameError}
                                                placeholder={lng[language]['placeholderFullName']}
                                                onChange={(e) => inputs.nameInput(e)}
                                                sx={inputStyle}
                                            />
                                        </Box>

                                        <Box>
                                            <InputLabel sx={{ fontWeight: '600', marginBottom: '10px' }}>NUMRI I TELEFONIT</InputLabel>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <FormControl sx={{ '.MuiInputBase-root': { borderRadius: 0 }, width: '120px', '.MuiSelect-select': { paddingY: '16.5px !important', paddingX: '5px' } }}>
                                                    <Select>
                                                        {
                                                            Countries.map((data, index) => (
                                                                <MenuItem key={index} value={data.phone_code}>{data.phone_code} {data.flag}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                                <TextField
                                                    type='tel'
                                                    fullWidth
                                                    sx={[{ marginLeft: '10px' }, inputStyle]}
                                                    placeholder='Shkruani numrin e telefonit'
                                                    value={userData.mob}
                                                    error={userDataError.mobError}
                                                    onChange={(e) => inputs.mobInput(e)}
                                                />
                                            </Box>
                                        </Box>

                                        <Box>
                                            <InputLabel sx={{ fontWeight: '600', marginBottom: '10px' }}>{lng[language]['email']}</InputLabel>
                                            <TextField
                                                type='email'
                                                required
                                                fullWidth
                                                value={userData.email}
                                                error={userDataError.emailError}
                                                placeholder={lng[language]['placeholderMail']}
                                                onChange={(e) => inputs.emailInput(e)}
                                                sx={inputStyle}
                                            />
                                        </Box>
                                        <Button
                                            onClick={(e) => handleSubmit(e, `https://fundway.com/documents/${apartment.pdfFileName}`, apartment.pdfFileName)}
                                            sx={{
                                                width: 'max-content',
                                                backgroundColor: '#002856',
                                                padding: 1.6,
                                                borderRadius: 0,
                                                color: '#FFF',
                                                justifySelf: 'center',
                                                border: 'solid 1px #002856',
                                                marginY: '20px',
                                                '&:hover': {
                                                    color: '#002856',
                                                    background: '#FFF'
                                                }
                                            }}
                                        >
                                            {lng[language]['downloadPlan']}
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        }

                        {
                            planDetailsModal &&
                            <>
                                {/* MOBILE */}
                                <Box
                                    sx={{
                                        display: { md: 'none', xs: 'block' },
                                        backgroundColor: '#FFF',
                                        position: 'fixed',
                                        top: 0,
                                        left: 0,
                                        height: '100%',
                                        width: '100%',
                                        zIndex: 999,
                                        overflow: 'scroll'
                                    }}
                                >
                                    <Box sx={{ gridTemplateRows: 'auto 1fr', display: 'grid' }}>
                                        <Box sx={{ width: '80%', marginX: 'auto', display: 'flex', justifyContent: 'flex-end', marginTop: '30px' }}>
                                            <CloseIcon onClick={() => setPlanDetailsModal(false)} sx={{ cursor: 'pointer' }} />
                                        </Box>
                                        <Box sx={{ width: '80%', marginX: 'auto' }}>
                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateRows: '80px 1fr'
                                                }}
                                            >
                                                <Box sx={{ marginTop: '20px', display: 'flex', borderBottom: 'solid 1px', paddingY: '20px', cursor: 'pointer', justifyContent: 'space-between', backgroundColor: openSelfPayingPlan ? '#002856' : 'unset', color: openSelfPayingPlan ? '#FFF' : '#002856', }}
                                                    onClick={() => setOpenSelfPayingPlan(!openSelfPayingPlan)}
                                                >
                                                    <Typography variant="h6">{lng[language]['selfPayingPlan']}</Typography>
                                                    <Box>
                                                        <KeyboardArrowRightIcon />
                                                    </Box>
                                                </Box>
                                                <AnimatePresence >
                                                    {
                                                        openSelfPayingPlan === true ?
                                                            <Box
                                                                component={motion.section}
                                                                key="content"
                                                                initial="collapsed"
                                                                animate="open"
                                                                exit="collapsed"
                                                                variants={{
                                                                    open: { opacity: 1, height: "auto" },
                                                                    collapsed: { opacity: 0, height: 0 },
                                                                }}
                                                                transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                                                                sx={{ marginTop: '20px' }}
                                                            >

                                                                <Box sx={{ display: 'grid', gridTemplateRows: '1fr 1fr 1fr 1fr 1fr 1fr', rowGap: '20px' }}>
                                                                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px 120px', columnGap: '10px' }}>
                                                                        <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['installments']}</Typography>
                                                                        <Typography>%</Typography>
                                                                        <Typography>&euro;</Typography>
                                                                    </Box>

                                                                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px 120px', columnGap: '10px', textAlign: 'left' }}>
                                                                        <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader1']} {lng[language]['selfPayingPlanList1']}</Typography>
                                                                        <Typography>30%</Typography>
                                                                        <Typography>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format((30 / 100) * (Math.ceil((parseFloat(apartment.priceTotal) + parseInt(apartment.priceParking)) / 100) * 100))}</Typography>
                                                                    </Box>

                                                                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px 120px', columnGap: '10px' }}>
                                                                        <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader2']} {lng[language]['selfPayingPlanList2']}</Typography>
                                                                        <Typography>30%</Typography>
                                                                        <Typography>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format((30 / 100) * (Math.ceil((parseFloat(apartment.priceTotal) + parseInt(apartment.priceParking)) / 100) * 100))}</Typography>
                                                                    </Box>

                                                                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px 120px', columnGap: '10px' }}>
                                                                        <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader3']} {lng[language]['selfPayingPlanList3']}</Typography>
                                                                        <Typography>30%</Typography>
                                                                        <Typography>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format((30 / 100) * (Math.ceil((parseFloat(apartment.priceTotal) + parseInt(apartment.priceParking)) / 100) * 100))}</Typography>
                                                                    </Box>

                                                                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px 120px', columnGap: '10px' }}>
                                                                        <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader4']} {lng[language]['selfPayingPlanList4']}</Typography>
                                                                        <Typography>10%-5000&euro;</Typography>
                                                                        <Typography>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format((10 / 100) * (Math.ceil((parseFloat(apartment.priceTotal) + parseInt(apartment.priceParking)) / 100) * 100) - 5000)}</Typography>
                                                                    </Box>
                                                                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px 120px', columnGap: '10px' }}>
                                                                        <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader5']} {lng[language]['selfPayingPlanList5']}</Typography>
                                                                        {/* <Typography></Typography> */}
                                                                        <Typography>5000&euro;</Typography>
                                                                    </Box>

                                                                </Box>

                                                            </Box>
                                                            :
                                                            ''
                                                    }
                                                </AnimatePresence>
                                            </Box>
                                            <Box sx={{ display: 'grid', gridTemplateRows: 'auto 1fr' }}>
                                                <Box sx={{ marginTop: '20px', display: 'flex', borderBottom: 'solid 1px', paddingY: '20px', cursor: 'pointer', justifyContent: 'space-between', backgroundColor: openCalculator ? '#002856' : 'unset', color: openCalculator ? '#FFF' : '#002856', }} onClick={() => setOpenCalculator(!openCalculator)}>
                                                    <Typography variant="h6">{lng[language]['selfPayWithBank']}</Typography>
                                                    <Box><KeyboardArrowRightIcon /></Box>
                                                </Box>
                                                <AnimatePresence initial={false}>
                                                    {
                                                        openCalculator === true ?
                                                            <Box
                                                                component={motion.section}
                                                                key="content"
                                                                initial="collapsed"
                                                                animate="open"
                                                                exit="collapsed"
                                                                variants={{
                                                                    open: { opacity: 1, height: "auto" },
                                                                    collapsed: { opacity: 0, height: 0 },
                                                                }}
                                                                transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                                                                sx={{ marginTop: '20px', paddingBottom: '70px' }}
                                                            >
                                                                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', marginX: 'auto' }}>
                                                                    <SimpleMortgageCalculator price={Math.ceil((parseFloat(apartment.priceTotal) + parseInt(apartment.priceParking)) / 100) * 100} />
                                                                </Box>
                                                            </Box>
                                                            :
                                                            ''
                                                    }
                                                </AnimatePresence>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                {/* DESKTOP */}
                                <Box
                                    sx={{
                                        display: { md: 'flex', xs: 'none' },
                                        position: 'fixed',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) )',
                                        zIndex: 997,
                                        justifyContent: 'center', alignItems: 'center'
                                    }}
                                >

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            background: '#FFF',
                                            height: 'auto',
                                            paddingY: '20px',
                                            width: '1200px',
                                            gridTemplateColumns: '1fr 1fr',
                                            position: 'relative'
                                        }}
                                    >
                                        <Box sx={{ position: 'absolute', top: '20px', right: '20px', cursor: 'pointer' }} onClick={() => setPlanDetailsModal(false)}>
                                            <CloseIcon />
                                        </Box>
                                        <Box sx={{ width: '100%', borderRight: '0.5px solid #c4c4c4' }}>
                                            <Box sx={{ width: '90%', marginX: 'auto', gridTemplateRows: '100px 1fr', display: 'grid' }}>
                                                <Typography variant="h5" fontSize={'28px'} fontWeight={'normal'} sx={{ width: '80%' }}>{lng[language]['selfPayingPlan']}</Typography>
                                                <Box>
                                                    <Box sx={{ display: 'grid', gridTemplateRows: '1fr 1fr 1fr 1fr 1fr 1fr', rowGap: '20px' }}>
                                                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px 120px', columnGap: '10px' }}>
                                                            <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['installments']}</Typography>
                                                            <Typography>%</Typography>
                                                            <Typography>&euro;</Typography>
                                                        </Box>

                                                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px 120px', columnGap: '10px', textAlign: 'left' }}>
                                                            <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader1']} {lng[language]['selfPayingPlanList1']}</Typography>
                                                            <Typography>30%</Typography>
                                                            <Typography>
                                                                {
                                                                    new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format((30 / 100) * (Math.ceil((parseFloat(apartment.priceTotal) + parseInt(apartment.priceParking)) / 100) * 100))
                                                                }
                                                            </Typography>
                                                        </Box>

                                                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px 120px', columnGap: '10px' }}>
                                                            <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader2']} {lng[language]['selfPayingPlanList2']}</Typography>
                                                            <Typography>30%</Typography>
                                                            <Typography>
                                                                {
                                                                    new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format((30 / 100) * (Math.ceil((parseFloat(apartment.priceTotal) + parseInt(apartment.priceParking)) / 100) * 100))
                                                                }
                                                            </Typography>
                                                        </Box>

                                                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px 120px', columnGap: '10px' }}>
                                                            <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader3']} {lng[language]['selfPayingPlanList3']}</Typography>
                                                            <Typography>30%</Typography>
                                                            <Typography>
                                                                {
                                                                    new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format((30 / 100) * (Math.ceil((parseFloat(apartment.priceTotal) + parseInt(apartment.priceParking)) / 100) * 100))
                                                                }

                                                            </Typography>
                                                        </Box>

                                                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px 120px', columnGap: '10px' }}>
                                                            <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader4']} {lng[language]['selfPayingPlanList4']}</Typography>
                                                            <Typography>10%-5000&euro;</Typography>
                                                            <Typography>
                                                                {
                                                                    new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format((10 / 100) * (Math.ceil((parseFloat(apartment.priceTotal) + parseInt(apartment.priceParking)) / 100) * 100) - 5000)
                                                                }
                                                            </Typography>
                                                        </Box>
                                                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px 120px', columnGap: '10px' }}>
                                                            <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader5']} {lng[language]['selfPayingPlanList5']}</Typography>
                                                            {/* <Typography></Typography> */}
                                                            <Typography>5000&euro;</Typography>
                                                        </Box>

                                                    </Box>
                                                    <Button onClick={() => {financingType('getOfferWithSelfFinancing'); navigate(`../reserve/${id}`);}} variant='contained' sx={{ borderRadius: 0, backgroundColor: '#002856', color: '#FFF', border: 'solid 1px #002856', paddingY: 1.4, marginTop: '20px', '&:hover': { color: '#002856', backgroundColor: '#FFF' } }}>
                                                        {lng[language]['takeOffer']}
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box sx={{ width: '100%', borderLeft: '0.5px solid #c4c4c4' }}>
                                            <Box sx={{ width: '90%', marginX: 'auto', gridTemplateRows: '100px 1fr', display: 'grid' }}>
                                                <Typography variant="h5" fontSize={'28px'} fontWeight={'normal'} sx={{ width: '80%' }}>{lng[language]['selfPayWithBank']}</Typography>
                                                <Box>
                                                    <Box>
                                                        <SimpleMortgageCalculator
                                                            price={Math.ceil((parseFloat(apartment.priceTotal) + parseInt(apartment.priceParking)) / 100) * 100}
                                                        />
                                                    </Box>
                                                    <Button onClick={() => {financingType('getOfferWithBank'); navigate(`../reserve/${id}`);}} variant='contained' sx={{ borderRadius: 0, backgroundColor: '#002856', color: '#FFF', border: 'solid 1px #002856', paddingY: 1.4, marginTop: '20px', '&:hover': { color: '#002856', backgroundColor: '#FFF' } }}>
                                                        {lng[language]['takeOffer']}
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </>


                            // const [openSelfPayingPlan, setOpenSelfPayingPlan] = useState(false);
                            // const [openCalculator, setOpenCalculator] = useState(false);
                        }

                        {
                            alert.open &&
                            <Box sx={{ width: '100%', bottom: '0', display: 'flex', position: 'fixed', alignItems: 'center', justifyContent: 'center' }}>
                                <Alert onClose={handleClose} severity={alert.severity} variant="filled">
                                    {lng[language][alert.msg]}
                                </Alert>
                            </Box>
                        }

                    </Box>
                    :
                    <Loader state={loading} />
            }
        </Box>
    )
}