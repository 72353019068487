import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
// import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from '@mui/material/FormControlLabel';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import FormControl from '@mui/material/FormControl';
// import NativeSelect from '@mui/material/NativeSelect';
// import FetchData from '../../api/FetchData';
import { lng } from '../../utils/language';
import { useSelector } from 'react-redux';
import ClickAwayListener from 'react-click-away-listener';
import { motion } from "framer-motion";

const itemVariants = {
    open: {
        opacity: 1,
        scale: 1,
        filter: "blur(0px)",
        transition: { type: "spring", stiffness: 300, damping: 24 },
    },
    closed: {
        opacity: 0,
        scale: 0.3,
        filter: "blur(20px)",
        transition: { duration: 0.2 },
    }
};

const checkBox = {
    width: 'max-content ',
    '.MuiCheckbox-root': {
        padding: { md: '2px', xs: '5px' },
        border: 'solid',
        borderRadius: 0,
        borderWidth: '1px',
    },
    '.MuiFormControlLabel-label': {
        marginLeft: '5px'
    },
    '.MuiSvgIcon-root': {
        path: {
            fill: 'transparent'
        }
    },
    '.Mui-checked': {
        fill: '#002856 !important',
        color: '#002856 !important',
        path: {
            fill: 'currentColor'
        }
    },
    
}

export default function MainFilter({ state, update, search, updateModal, resetFilters }) {
    const language = useSelector((state) => state.language.language)

    const [show, setShow] = useState({
        showRooms: false,
        showPrice: false,
        showFloor: false,
        showSpace: false,
        showOrientation: false
    })

    // const handleChange = (e) => {
    //     let name = e.target.name;
    //     let value = e.target.value;
    //     update({
    //         ...state,
    //         [name]: value
    //     })
    // }

    const [typology, setTypology] = useState([
        {
            label: lng[language]['bedRoom1'],
            value: '1+1',
            checked: false
        },
        {
            label: lng[language]['bedRoom2'],
            value: '2+1',
            checked: false
        },
        {
            label: lng[language]['bedRoom3'],
            value: '3+1',
            checked: false
        },
        // {
        //     label: lng[language]['bedRoom4'],
        //     value: '4+1',
        //     checked: false
        // },
        {
            label: 'DUPLEX',
            value: 'duplex',
            checked: false
        },
    ])

    const handleSelect = (e, typologyIndex) => {
        var value = e.target.value;
        var checked = e.target.checked;

        if (typology[typologyIndex].value === value && checked === true) {
            setTypology(prevTypology =>
                prevTypology.map(option => ({
                    ...option,
                    checked: option.value === value
                }))
            );
            var newItem = {
                ...state,
                typology: value
            };
            update(newItem);
        } else {
            setTypology(prevTypology =>
                prevTypology.map(option => ({
                    ...option,
                    checked: false
                }))
            );
            update({
                ...state,
                typology: ''
            });
        }
    }

    const [orientation, setOrientation] = useState([
        {
            label: lng[language]['Promenade'],
            value: 'Promenade',
            checked: false
        },
        {
            label: lng[language]['Det'],
            value: 'Det',
            checked: false
        },
        {
            label: lng[language]['Marine'],
            value: 'Marine',
            checked: false
        },
    ])

    const handleSelectOrientation = (e, orientationIndex) => {
        var value = e.target.value;
        var checked = e.target.checked;

        if (orientation[orientationIndex].value === value && checked === true) {
            setOrientation(prevTypology =>
                prevTypology.map(option => ({
                    ...option,
                    checked: option.value === value
                }))
            );
            var newItem = {
                ...state,
                orientation: value
            };
            update(newItem);
        } else {
            setOrientation(prevTypology =>
                prevTypology.map(option => ({
                    ...option,
                    checked: false
                }))
            );
            update({
                ...state,
                orientation: ''
            });
        }
    }

    const handleRememberSelect = () => {
        if (state.typology.value) {
            setTypology(prevTypology =>
                prevTypology.map(option => ({
                    ...option,
                    checked: option.value === state.typology.value
                }))
            );
        }
        if (state.orientation.value) {
            setOrientation(prevTypology =>
                prevTypology.map(option => ({
                    ...option,
                    checked: option.value === state.orientation.value
                }))
            );
        }
    }

    useEffect(() => {
        handleRememberSelect();
        // eslint-disable-next-line
    }, [])

    // const maxFloors = Array.from({ length: 14 }, (_, index) => index + 1);
    // const price = Array.from({ length: 25 }, (_, index) => (index + 1) * 100000);
    // const [surface, setSurface] = useState();
    // const [prices, setPrices] = useState();

    // const getSurfaceData = () => {
    //     FetchData.getAll('apartments/getSurfeces')
    //         .then((res) => {
    //             if (res.data.success) {
    //                 setSurface(res.data.data);
    //             } else {
    //                 return;
    //             }
    //         })
    //     FetchData.getAll('apartments/getAptPrices')
    //         .then((res) => {
    //             if (res.data.success) {
    //                 setPrices(res.data.data);
    //             } else {
    //                 return;
    //             }
    //         })

    // }

    // useEffect(() => {
    //     getSurfaceData();
    // }, [])

    const [openTypology, setOpenTypology] = useState(false);
    const handleTypologyClickAway = () => {
        if (openTypology) setOpenTypology(false)
        else return
    };

    const [openOrientation, setOpenOrientation] = useState(false);
    const handleOrientationClickAway = () => {
        if (openOrientation) setOpenOrientation(false)
        else return
    };

    // const resetAllFilters = () => {
    //     setTypology([
    //         {
    //             label: lng[language]['bedRoom1'],
    //             value: '1+1',
    //             checked: false
    //         },
    //         {
    //             label: lng[language]['bedRoom2'],
    //             value: '2+1',
    //             checked: false
    //         },
    //         {
    //             label: lng[language]['bedRoom3'],
    //             value: '3+1',
    //             checked: false
    //         },
    //         // {
    //         //     label: lng[language]['bedRoom4'],
    //         //     value: '4+1',
    //         //     checked: false
    //         // },
    //         {
    //             label: 'DUPLEX',
    //             value: 'duplex',
    //             checked: false
    //         },
    //     ])
    //     resetFilters();
    // }

    const mobileFilters = (
        <>
            {/* TYPOLOGY */}
            <Box>
                <Box sx={{ width: '95%', marginX: 'auto' }}>
                    <InputLabel sx={{ color: '#53626F', fontWeight: 800 }}>{lng[language]['bedsFilters'].toUpperCase()}</InputLabel>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 2, cursor: 'pointer' }} onClick={() => setShow({ ...show, showRooms: !show.showRooms })}>
                        {
                            state.typology.length > 0
                                ?
                                <Typography color={'#53626F'}>
                                    {
                                        state.typology.toString()
                                    }
                                </Typography>
                                :
                                <Typography color={'#53626F'}>{lng[language]['any']}</Typography>
                        }
                        {
                            show.showRooms
                                ?
                                <KeyboardArrowUpIcon sx={{ fill: '#53626F' }} />
                                :
                                <KeyboardArrowDownIcon sx={{ fill: '#53626F' }} />
                        }
                    </Box>
                </Box>
                {
                    show.showRooms &&
                    <Box sx={{ top: 0, left: 0, width: '100%', zIndex: 9999, height: 'auto', background: '#FFF' }}>
                        <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', marginX: 'auto', width: '90%', paddingBottom: '10px' }}>
                            <FormGroup sx={{
                                width: '100%',
                                display: 'grid',
                                gridTemplateColumns: '1fr',
                                gridGap: '20px',
                                justifyItems: 'start',
                                backgroundColor: '#FFF',
                            }}>
                                {
                                    language &&
                                    typology?.map((data, index) => (
                                        <FormControlLabel key={index} sx={checkBox} checked={data.checked} control={<Checkbox />} label={data.value.toUpperCase()} value={data.value} onChange={(e) => handleSelect(e, index)} />
                                    ))
                                }
                            </FormGroup>
                        </Box>
                    </Box>
                }
                <Box component={'hr'}></Box>
            </Box>

            {/* ORIENTATION */}
            <Box sx={{ marginTop: '20px' }}>
                <Box sx={{ width: '95%', marginX: 'auto' }}>
                    <InputLabel sx={{ color: '#53626F', fontWeight: 800 }}>{lng[language]['orientation'].toUpperCase()}</InputLabel>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 2, cursor: 'pointer' }} onClick={() => setShow({ ...show, showOrientation: !show.showOrientation })}>
                        {
                            state.typology.length > 0
                                ?
                                <Typography color={'#53626F'}>
                                    {
                                        state.orientation.toString()
                                    }
                                </Typography>
                                :
                                <Typography color={'#53626F'}>{lng[language]['any']}</Typography>
                        }
                        {
                            show.showOrientation
                                ?
                                <KeyboardArrowUpIcon sx={{ fill: '#53626F' }} />
                                :
                                <KeyboardArrowDownIcon sx={{ fill: '#53626F' }} />
                        }
                    </Box>
                </Box>
                {
                    show.showOrientation &&
                    <Box sx={{ top: 0, left: 0, width: '100%', zIndex: 9999, height: 'auto', background: '#FFF' }}>
                        <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', marginX: 'auto', width: '90%', paddingBottom: '10px' }}>
                            <FormGroup sx={{
                                width: '100%',
                                display: 'grid',
                                gridTemplateColumns: '1fr',
                                gridGap: '20px',
                                justifyItems: 'start',
                                backgroundColor: '#FFF',
                            }}>
                                {
                                    language &&
                                    orientation?.map((data, index) => (
                                        <FormControlLabel key={index} sx={checkBox} checked={data.checked} control={<Checkbox />} label={lng[language][data.value]} value={data.value} onChange={(e) => handleSelectOrientation(e, index)} />
                                    ))
                                }
                            </FormGroup>
                        </Box>
                    </Box>
                }
                <Box component={'hr'}></Box>
            </Box>
        </>
    );
    const desktopFilters = (
        <>
            {/* TYPOLOGY */}
                <Box component={ClickAwayListener} onClickAway={handleTypologyClickAway} sx={{display: {md: 'inherit', xs: 'none'}}}>
                    <Box component={motion.nav} initial={false} animate={openTypology ? "open" : "closed"} className={["menu"]} sx={{display: {md: 'inherit', xs: 'none'}, borderRight: 'solid 1px #b8b8b8'}}>
                        <Box sx={{ position: 'relative', display: { md: 'inherit', xs: 'none' } }}>
                            <InputLabel sx={{ color: '#707C87', fontSize: '14px', position: 'absolute', top: '0px', left: 0, fontWeight: 'bold' }}>{lng[language]['bedsFilters'].toUpperCase()}</InputLabel>
                            <Box sx={{ display: 'flex', height: '50px', justifyContent: 'center', alignItems: 'center', border: 'solid 1px #', borderColor: 'rgba(0, 0, 0, 0.3)', marginTop: '5px' }} onClick={() => setOpenTypology(!openTypology)}>
                                <Typography variant="subtitle1" sx={{ width: '100%', textAlign: 'center' }}>{
                                    state.typology.length > 0
                                        ?
                                        <Typography sx={{ width: 'fit-content', marginTop: '15px', marginLeft: '2px', color: '#707C87', fontSize: '12px', fontWeight: 'bold' }}>
                                            {
                                                state.typology.toString().toUpperCase()
                                            }
                                        </Typography>
                                        :
                                        <Typography sx={{ width: 'fit-content', marginTop: '15px', marginLeft: '2px', color: '#707C87', fontSize: '12px', fontWeight: 'bold' }}
                                        >{lng[language]['any'].toUpperCase()}</Typography>
                                }
                                </Typography>
                                <KeyboardArrowDownIcon sx={{marginRight: '10px', fill: '#707C87A6', height: '30px', width: '30px', rotate: openTypology ? '180deg' : '0deg', transition: '.3s all'}} />
                            </Box>
                           <Box>
                                <Box component={motion.ul} variants={{ open: { clipPath: "inset(0% 0% 0% 0% round 0px)",boxShadow: '100px 100px 100px 100px #000', transition: { type: "spring", bounce: 0, duration: 0.5, delayChildren: 0.3, staggerChildren: 0.1 }  }, closed: { clipPath: "inset(10% 50% 90% 50% round 0px)", transition: { type: "spring", bounce: 0, duration: 0.3, when: "afterChildren", staggerDirection: -1, staggerChildren: 0.06 } } }} sx={{ pointerEvents: openTypology ? "auto" : "none", width: '220px', position: 'absolute', padding: '20px', marginTop: 0, left: '-25px', top: '75px', borderRadius: 0,border: 'solid 1px #cdcdcd', background: '#fcfcfc',  
                                }}>
                                    <FormGroup sx={{ display: 'grid', gridTemplateColumns: { sm: '1fr', xs: '1fr' }, gridGap: '10px', justifyItems: 'left', width: '90%', marginX: 'auto' }}>
                                        {
                                            language &&
                                            typology?.map((data, index) => (
                                                <Box component={motion.li} variants={itemVariants} key={index} >
                                                    <FormControlLabel sx={checkBox} checked={data.checked} control={<Checkbox />} label={data?.value.toUpperCase()} value={data?.value} onChange={(e) => handleSelect(e, index)} />
                                                </Box>
                                            ))
                                        }
                                    </FormGroup>
                                </Box>
                           </Box>
                        </Box>
                    </Box>
                </Box>

            {/* ORIENTATION */}
            <Box component={ClickAwayListener} onClickAway={handleOrientationClickAway} sx={{display: {md: 'inherit', xs: 'none'}}}>
                <Box component={motion.nav} initial={false} animate={openOrientation ? "open" : "closed"} className={["menu"]} sx={{display: {md: 'inherit', xs: 'none'}, borderRight: 'solid 0.5px #b8b8b8'}}>
                    <Box sx={{ position: 'relative', display: { md: 'inherit', xs: 'none' } }}>
                        <InputLabel sx={{  fontSize: '14px', position: 'absolute', top: '0px', left: 0, color: '#707C87', fontWeight: 'bold' }}>{lng[language]['orientation'].toUpperCase()}</InputLabel>
                        <Box sx={{ display: 'flex', height: '50px', justifyContent: 'center', alignItems: 'center', border: 'none', borderColor: 'rgba(0, 0, 0, 0.3)', marginTop: '5px' }} onClick={() => setOpenOrientation(!openOrientation)}>
                            <Typography variant="subtitle1" sx={{ width: '100%', textAlign: 'center' }}>{
                                state.orientation.length > 0
                                    ?
                                    <Typography sx={{ width: 'fit-content', marginTop: '15px', marginLeft: '2px', color: '#707070', fontSize: '12px', fontWeight: 'bold' }}>
                                        {
                                            state.orientation.toString().toUpperCase()
                                        }
                                    </Typography>
                                    :
                                    <Typography sx={{ width: 'fit-content', marginTop: '15px', marginLeft: '2px', color: '#707070', fontSize: '12px', fontWeight: 'bold' }} >{lng[language]['any'].toUpperCase()}</Typography>
                            }
                            </Typography>
                            <KeyboardArrowDownIcon sx={{marginRight: '10px', fill: '#707C87A6', height: '30px', width: '30px', rotate: openOrientation ? '180deg' : '0deg', transition: '.3s all'}} />
                        </Box>
                        <Box component={motion.ul} variants={{ open: { clipPath: "inset(0% 0% 0% 0% round 0px)", transition: { type: "spring", bounce: 0, duration: 0.5, delayChildren: 0.3, staggerChildren: 0.1 } }, closed: { clipPath: "inset(10% 50% 90% 50% round 0px)", transition: { type: "spring", bounce: 0, duration: 0.3, when: "afterChildren", staggerDirection: -1, staggerChildren: 0.06 } } }} sx={{ pointerEvents: openOrientation ? "auto" : "none", marginTop: 0, width: '180px', position: 'absolute', padding: '20px', left: '-10px', top: '75px', border: 'solid 1px #cdcdcd', background: '#fcfcfc' }}>
                            <FormGroup sx={{ display: 'grid', gridTemplateColumns: { sm: '1fr', xs: '1fr' }, gridGap: '10px', justifyItems: 'left', }}>
                                {
                                    language &&
                                    orientation?.map((data, index) => (
                                        <Box component={motion.li} variants={itemVariants} key={index}>
                                            <FormControlLabel sx={checkBox} checked={data.checked} control={<Checkbox />} label={lng[language][data?.value]} value={data?.value} onChange={(e) => handleSelectOrientation(e, index)} />
                                        </Box>
                                    ))
                                }
                            </FormGroup>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )

    return (
        <Box sx={{ display: 'grid', gridTemplateColumns: { md: '1fr 1fr 1fr', xs: 'unset' }, gridTemplateRows: { md: 'unset', xs: '80px 1fr 1fr' }, columnGap: '20px', padding: {md: '5px 20px', xs: '20px 30px'}, paddingLeft: '30px', paddingRight: '0', background: '#FFF', position: 'relative', width: { md: '100%', xs: '100%' }, overflow: { md: 'unset', xs: 'scroll' }, alignItems: { md: 'center', xs: 'unset' }, borderRadius: { md: '0', xs: 'unset' }, height: {md: '83px', xs:'unset'} }}>
            {desktopFilters}
            <Box onClick={updateModal} sx={{ display: { md: 'none', xs: 'flex' }, justifyContent: 'flex-end', alignItems: 'flex-start' }}>
                <CloseIcon sx={{ cursor: 'pointer', marginTop: 2, marginRight: 2 }} />
            </Box>
            <Box sx={{ display: { md: 'none', xs: 'flex' }, flexDirection: 'column', width: '100%' }}>
                {mobileFilters}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', marginTop: { md: 'unset', xs: '20px' }, height: { md: '50px', xs: 'unset' } }}>
                <Button variant='contained' sx={{ background: '#002856', color: '#FFF', borderRadius: '0', boxShadow: 0, paddingY: 1.6, height: { md: '50px', xs: 'unset' }, marginBottom: {md: 'unset', xs: '20px'}, fontSize: {md: '13px', xs: '0.875rem'}, width: {md: '100%', xs: 'auto'} }} onClick={search}>
                    {lng[language]['checkResidence']}
                </Button>
            </Box>
        </Box>
    )
}